import JSZip from 'jszip';
import axios from 'axios';
import {
  error as errorNotification,
} from 'react-notification-system-redux';
import cloudinary from '../utils/cloudinaryConfig';
import defaultShaderImage from '../assets/default.jpeg';

const getExtension = (blob) => {
  if (!blob || !blob.type) return null;
  if (blob.type === 'image/jpeg') return 'jpeg';
  if (blob.type === 'image/gif') return 'gif';
  if (blob.type === 'image/png') return 'png';
  return null;
};

export const zipShader = async (shader, dispatch, zipFolder) => {
  const { title, rawFragmentSource, rawVertexSource, images, inputs = [] } = shader;

  // if a JSZip object is passed in, create a subfolder for this shader
  // otherwise, create a new JSZip object
  const zip = zipFolder ? zipFolder.folder(title) : new JSZip();

  zip.file(`${title}.fs`, rawFragmentSource);
  zip.file(`${title}.vs`, rawVertexSource);
  const downloadImages = (
    inputs
      .filter(input => (input.TYPE === 'image' && input.NAME !== ''))
      .map(input => ({
        title: input.NAME,
        image: images.find(image => image.name === input.NAME),
      }))
  );
  const blobDownloads = downloadImages.map(({ title, image }) => {
    if (!image || image.CloudinaryId || !image.blobUrl) {
      const url = (image && image.cloudinaryId) ? cloudinary.url(image.cloudinaryId) : defaultShaderImage;
      return axios.get(url, { responseType: 'blob' })
        .then(response => ({ title, blob: response.data }));
    }
    return axios.get(image.blobUrl, { responseType: 'blob' })
      .then(response => ({ title, blob: response.data }));
  });

  await Promise.all(
    blobDownloads
      .map(dlp => dlp.then(({ blob, title }) => {
        const ext = getExtension(blob);
        if (!ext) {
          dispatch(errorNotification({
            title: `Could not download image '${title}'`,
            message: `Unknown file type ${blob.type}`,
            autoDismiss: 5,
          }));
        } else {
          zip.file(`${title}.${ext}`, blob, { base64: true });
        }
        Promise.resolve();
      })),
  );
  return zip;
};
