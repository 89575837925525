// TODO: use redux-form selectors instead
import { getGPUTier } from 'detect-gpu';

const GPUTier = getGPUTier();

export const getSearchQuery = ({ form = {} }) => {
  if (form.search && form.search.values && form.search.values.q) {
    return form.search.values.q;
  }
  return '';
};

export const getSearchFlags = ({ form = {} }) => {
  if (form.search && form.search.values) {
    const { showPrivate = false } = form.search.values;
    return { showPrivate };
  }
  return '';
};

export const getCategory = ({ form = {} }) => {
  if (form.category && form.category.values && form.category.values.Categories) {
    return form.category.values.Categories;
  }
  return '';
};

export const getSort = ({ form = {} }) => {
  if (form.sort && form.sort.values && form.sort.values.sort) {
    return form.sort.values.sort;
  }
  return '';
};

export const getSearchParams = state => ({
  q: getSearchQuery(state),
  category: getCategory(state),
  sort: getSort(state),
  page: state.search.page,
  ...getSearchFlags(state),
});

export const getOptimizedScale = (state) => {
  const { shader: { scale } } = state;
  // scale of 0 is 'auto'
  if (scale && scale > 0) return scale;
  const { tier, type } = GPUTier;
  if (
    type !== 'FALLBACK' && (
      tier === 'GPU_DESKTOP_TIER_1' ||
      tier === 'GPU_MOBILE_TIER_1'
    )) {
    return 0.5;
  }
  return 1;
};

