import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import SearchForm from './SearchForm';

export default class SearchFormBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      autocompleteArray: [],
    };
  }

  handleChange = (formdata) => {
    const { onSubmit } = this.props;
    onSubmit();
    axios.get(`${process.env.REACT_APP_API_URL}/search/completion/${formdata.q}`)
      .then(this.handleCompletionSuggestions)
      .catch((err) => {
        console.error(err);
      });
  }

  handleCompletionSuggestions = (response) => {
    if (response.data.success) {
      this.setState({
        autocompleteArray: response.data.options.map(item => item.text),
      });
    }
  }

  render() {
    const { onSubmit, onSubmitSuccess } = this.props;
    return (
      <div className="search-container">
        <SearchForm
          onChange={this.handleChange}
          onSubmit={onSubmit}
          onSubmitSuccess={onSubmitSuccess}
          autocompleteArray={this.state.autocompleteArray}
        />
      </div>
    );
  }

  static propTypes = ({
    onSubmit: PropTypes.func.isRequired,
    onSubmitSuccess: PropTypes.func,
  })

  static defaultProps = ({
    onSubmitSuccess: () => ({}),
  })
}
