import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import CustomPropTypes from '../../CustomPropTypes';
import MobileShaderToolbar from '../../components/MobileShaderToolbar/MobileShaderToolbar';
import { fixShaderImage } from '../../utils/images';
import cloudinary from '../../utils/cloudinaryConfig';
import Shader from '../../components/Shader/Shader';
import TopMenu from '../../components/TopMenu/TopMenu';

const MobileShaderView = props => (
  <Fragment>
    <TopMenu />
    <SwipeableViews
      style={{ height: '100%', width: '100%' }}
      containerStyle={{ height: '100%', width: '100%' }}
    >
      <div className="shader-swipe__item">
        <Shader />
      </div>
      { props.isNew ? ''
        : props.shaders.map(related => (
          <div className="shader-swipe__item">
            <a href={`/shaders/${related.id}`}>
              <img
                alt={related.title}
                className="shader-swipe__placeholder"
                src={cloudinary.url(related.thumbnailCloudinaryId)}
                onError={fixShaderImage}
              />
            </a>
            <div className="shader-swipe__lower mobile-bar">
              <div className="shader-swipe__lower-header">
                Related:
              </div>
              <div className="shader-swipe__lower-title mobile-tools__title">
                <a href={`/shaders/${related.id}`}>
                  {related.title}
                </a>
              &nbsp;by&nbsp;
                <a href={`/u/${related.username}`}>
                  {related.username}
                </a>
              </div>
            </div>
          </div>
        ))
      }
    </SwipeableViews>
    <MobileShaderToolbar />
  </Fragment>);

MobileShaderView.propTypes = ({
  shaders: PropTypes.arrayOf(CustomPropTypes.shader).isRequired,
  isNew: PropTypes.bool.isRequired,
});

export default MobileShaderView;
