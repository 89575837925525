import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

const SearchForm = ({
  autocompleteArray,
  handleSubmit,
  onSubmit,
  submitting,
  pristine,
  change,
  autofill,
}) => {
  const autocompleteUnique = [...new Set(autocompleteArray)];
  const autocomplete = autocompleteUnique.map(row => (
    <button
      key={row}
      onClick={() => {
        autofill('q', row);
        handleSubmit(onSubmit)();
      }}
      className="autocomplete-row-wrapper"
      type="button"
    >
      <div className="autocomplete-row">
        <p>
          {row}
        </p>
      </div>
    </button>));

  return (
    <form
      className="search-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Field className="search-form__input" name="q" component="input" type="text" label="search" placeholder="Search" autoComplete="off" />
      <button className="search-form__submit" type="submit" disabled={submitting || pristine}>
        <i className="fa fa-search" />
      </button>
      <div className="autocomplete-container">
        {autocomplete}
      </div>

    </form>
  );
};

SearchForm.propTypes = ({
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  autocompleteArray: PropTypes.arrayOf(PropTypes.string).isRequired,
});

const SearchFormRegistered = reduxForm({
  form: 'search',
  // destroyOnUnmount: false,
})(SearchForm);

export default SearchFormRegistered;
