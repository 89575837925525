import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { userStarredCurrent } from '../../selectors/shader';
import {
  forkShader,
  starShader,
  unstarShader,
  downloadShader,
} from '../../actions/shader';
import CustomPropTypes from '../../CustomPropTypes';
import ShareNavButton from '../Share/ShareNavButton';

const ShaderButtons = props => (
  <Fragment>
    { props.shaderStarred
      ? (
        <button
          className="main-nav__button"
          onClick={() => props.unstarShader()}
          type="button"
          title="Undo like"
        >
          <i className="fa fa-heart fa-fw" /> Like
        </button>
      )
      : (
        <button
          className="main-nav__button"
          onClick={() => props.starShader()}
          type="button"
          title="Like shader"
        >
          <i className="fa fa-heart-o fa-fw" /> Like
        </button>
      )
    }
    <ShareNavButton
      shader={props.shader}
      shareType="tooltip"
      buttonClass="main-nav__button fa-fw"
      tooltipStyle={{ top: 'calc(100% + 12px)', right: '12px' }}
    />
    <button
      className="main-nav__expand-button"
      onClick={props.forkShader}
      type="button"
      title="Fork this shader"
    >
      <i className="fa fa-code-fork fa-fw" /> Fork
    </button>
    <button
      className="main-nav__button"
      onClick={props.downloadShader}
      type="button"
      title="Download this shader"
    >
      <i className="fa fa-download fa-fw" /> Zip
    </button>
  </Fragment>);

const mapStateToProps = state => ({
  shader: state.shader,
  user: state.user,
  shaderStarred: userStarredCurrent(state),
});

const mapDispatchToProps = dispatch => ({
  ...(bindActionCreators({
    forkShader,
    starShader,
    unstarShader,
    downloadShader,
  }, dispatch)),
});

ShaderButtons.propTypes = ({
  shader: CustomPropTypes.shader.isRequired,
  forkShader: PropTypes.func.isRequired,
  starShader: PropTypes.func.isRequired,
  downloadShader: PropTypes.func.isRequired,
  unstarShader: PropTypes.func.isRequired,
  shaderStarred: PropTypes.bool.isRequired,
});


export default connect(mapStateToProps, mapDispatchToProps)(ShaderButtons);
