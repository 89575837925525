// SHADER EDITOR WALKTHROUGH PAGES
export const MOBILE_INTRO_PAGE = 'MOBILE_INTRO_PAGE';
export const MOBILE_CODE_TAB_PAGE = 'MOBILE_CODE_TAB_PAGE';
export const MOBILE_CONTROLS_PAGE = 'MOBILE_CONTROLS_PAGE';
export const MOBILE_CONSOLE_PAGE = 'MOBILE_CONSOLE_PAGE';
export const MOBILE_RELATED_SHADERS_PAGE = 'MOBILE_RELATED_SHADERS_PAGE';
export const MOBILE_OUTRO_PAGE = 'MOBILE_OUTRO_PAGE';

export const DESKTOP_INTRO_PAGE = 'DESKTOP_INTRO_PAGE';
export const DESKTOP_CODE_TAB_PAGE = 'DESKTOP_CODE_TAB_PAGE';
export const DESKTOP_CONTROLS_PAGE = 'DESKTOP_CONTROLS_PAGE';
export const DESKTOP_CONSOLE_PAGE = 'DESKTOP_CONSOLE_PAGE';
export const DESKTOP_RELATED_SHADERS_PAGE = 'DESKTOP_RELATED_SHADERS_PAGE';
export const DESKTOP_OUTRO_PAGE = 'DESKTOP_OUTRO_PAGE';

export const DESKTOP_PAGES = [
  DESKTOP_INTRO_PAGE,
  DESKTOP_CODE_TAB_PAGE,
  DESKTOP_CONTROLS_PAGE,
  DESKTOP_CONSOLE_PAGE,
  DESKTOP_OUTRO_PAGE,
];

export const MOBILE_PAGES = [
  MOBILE_INTRO_PAGE,
  MOBILE_CODE_TAB_PAGE,
  MOBILE_CONTROLS_PAGE,
  MOBILE_CONSOLE_PAGE,
  MOBILE_RELATED_SHADERS_PAGE,
  MOBILE_OUTRO_PAGE,
];
