import React from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setShaderTitle } from '../../actions/shader';
import { isUserOwner } from '../../selectors/shader';

class ShaderTitleInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { title: props.title };
    this.input = null;
  }

  componentWillReceiveProps(newProps) {
    if (
      newProps.title
      && this.props.title !== newProps.title
    ) {
      this.setState({ title: newProps.title });
    }
  }

  handleInputChange = e => this.setState({ title: e.target.value });

  handleInputSubmit = (e) => {
    e.preventDefault();
    this.input.blur();
  }

  handleInputBlur = () => this.props.setShaderTitle(this.state.title);

  render() {
    const { formClass, inputClass, modified } = this.props;
    return (
      <form
        className={formClass}
        onSubmit={this.handleInputSubmit}
      >
        <input
          type="text"
          ref={(i) => { this.input = i; }}
          className={inputClass}
          onChange={this.handleInputChange}
          disabled={!this.props.isUserOwner}
          value={this.state.title}
          onBlur={this.handleInputBlur}
        />
        { modified && <span>*</span> }
      </form>);
  }
}

ShaderTitleInput.defaultProps = ({
  inputClass: '',
  formClass: '',
  title: '',
  modified: false,
});

ShaderTitleInput.propTypes = ({
  modified: PropTypes.bool,
  formClass: PropTypes.string,
  inputClass: PropTypes.string,
  isUserOwner: PropTypes.bool.isRequired,
  title: PropTypes.string,
  setShaderTitle: PropTypes.func.isRequired,
});

const mapStateToProps = state => ({
  isUserOwner: isUserOwner(state),
  title: state.shader.title,
  modified: (
    state.shader.dirty
    || state.shader.vertexEdited
    || state.shader.fragmentEdited
  ),
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({ setShaderTitle }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(ShaderTitleInput);
