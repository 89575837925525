import * as ActionTypes from '../constants/ActionTypes';
import * as Walkthrough from '../constants/walkthrough';

const initialState = {
  pageIndex: 0,
  enabled: true,
  mobile: false,
};

const walkthrough = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_WALKTHROUGH_MOBILE:
      return {
        ...state,
        mobile: true,
      };
    case ActionTypes.SET_WALKTHROUGH_DESKTOP:
      return {
        ...state,
        mobile: false,
      };
    case ActionTypes.DISABLE_WALKTHROUGH:
      return {
        ...state,
        enabled: false,
      };
    case ActionTypes.ENABLE_WALKTHROUGH:
      return {
        ...state,
        pageIndex: 0,
        enabled: true,
      };
    case ActionTypes.AUTHENTICATE_USER:
      return {
        ...state,
        enabled: !action.payload.walkthroughFinished,
      };
    case ActionTypes.INCREMENT_PAGE: {
      const { pageIndex, mobile } = state;
      return {
        ...state,
        pageIndex: Math.min(
          (mobile ? Walkthrough.MOBILE_PAGES : Walkthrough.DESKTOP_PAGES).length,
          pageIndex + 1,
        ),
      };
    }
    case ActionTypes.DECREMENT_PAGE: {
      const { pageIndex } = state;
      return {
        ...state,
        pageIndex: Math.max(0, pageIndex - 1),
      };
    }
    default:
      return state;
  }
};

export default walkthrough;
