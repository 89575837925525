import { mapGLErrorToISFLine, mapISFErrorToISFLine } from './isfLineMapper';

// const parserErrorRegex = /(.+) at position (.+) and line (.+)/;
const rendererErrorRegex = /ERROR: (\d+):(\d+):(.+)/gi;

/*
 * Analyze a shader error string to extract the line number/position
 * for each error for the editor
 */
export const decomposeShaderError = (error = { message: '', lineNumber: null }, rawFragmentSource, fragmentShader) => {
  const errorString = error.message;

  if (error.lineNumber) {
    const row = mapISFErrorToISFLine(error, rawFragmentSource) - 1;
    return [{
      text: errorString, row, column: 0, type: 'error',
    }];
  }

  let match = rendererErrorRegex.exec(errorString);
  const rendererErrors = [];
  while (match != null) {
    if (match.length !== 4) {
      console.error('Detected, but was unable to parse shader renderer error'); // eslint-disable-line no-console
    }

    const errorLine = match[0];
    const message = match[3];
    const row = mapGLErrorToISFLine({ message: errorLine }, fragmentShader, rawFragmentSource) - 1;
    match = rendererErrorRegex.exec(errorString);

    // If we have an invalid row the LineMapper regex failed or the calculation was wrong
    if (row > 0) {
      rendererErrors.push({
        row,
        column: 0,
        text: message,
        type: 'error',
      });
    }
  }

  return rendererErrors;
};
