import axios from 'axios';

import * as ActionTypes from '../constants/ActionTypes';

export function setFeaturedShaders(shaders) {
  return {
    type: ActionTypes.SET_FEATURED_SHADERS,
    payload: shaders,
  };
}

export function getFeaturedShaders() {
  return (dispatch) => {
    axios.get(`${process.env.REACT_APP_API_URL}/shaders`, { params: { featured: true } })
      .then((response) => {
        dispatch(setFeaturedShaders(response.data));
      });
  };
}
