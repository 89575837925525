import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CustomPropTypes from '../../CustomPropTypes';
import Share from './Share';

const ShareButton = ({
  expand, retract, shader, expanded,
}) => {
  const shareButtonClass = classNames({
    'share-button': true,
    'share-button--expanded': expanded,
  });
  return (
    <div className={shareButtonClass}>
      <div className="share-button__retracted">
        <button
          className="share-button__icon"
          onClick={expand}
          type="button"
          title="Share this shader"
        >
          <i className="fa fa-share fa-fw" /> Share
        </button>
      </div>
      <div className="share-button__expanded">
        <button
          className="share-button__icon"
          onClick={retract}
          type="button"
          title="Share this shader"
        >
          <i className="fa fa-share fa-fw" /> Share
        </button>
        <div className="share-button__mobile-container">
          <Share
            shader={shader}
            retract={retract}
          />
        </div>
      </div>
    </div>
  );
};

ShareButton.propTypes = ({
  expand: PropTypes.func.isRequired,
  retract: PropTypes.func.isRequired,
  shader: CustomPropTypes.shader.isRequired,
  expanded: PropTypes.bool.isRequired,
});

export default ShareButton;
