import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ReactRouterPropTypes from 'react-router-prop-types';

export const TAB_OPEN = '1';

const ShaderEditorTabButton = ({ tabClassAttribute, toggleExpanded, iconClass, title }) => (
  <button
    className={`controls__button controls__button--${tabClassAttribute}`}
    onClick={toggleExpanded}
    type="button"
    title={title}
  >
    <i className={iconClass} />
  </button>
);

ShaderEditorTabButton.propTpes = {
  toggleExpanded: PropTypes.func.isRequired,
  iconClass: PropTypes.string.isRequired,
  tabClassAttribute: PropTypes.string.isRequired,
};

const withTab = (
  TabComponent,
  tabClassAttribute,
  buttonIconClass,
  right = false,
  title = '',
) => {
  class ShaderEditorTab extends React.Component {
    constructor(props) {
      super(props);
      const { location } = props;
      const sp = new URLSearchParams(location.search);
      const initExpandedFromUrl = sp.get(tabClassAttribute) === TAB_OPEN;
      this.state = {
        expanded: !!props.initExpanded || initExpandedFromUrl,
      };
    }

    setExpandedState = (state) => {
      this.setState({ expanded: state });
    }

    toggleExpanded = () => {
      const { expanded } = this.state;
      this.setState({ expanded: !expanded });
    }

    handleKeyDown = (e) => {
      if (
        this.props.expandKey
        && (e.ctrlKey || e.metaKey)
        && this.props.expandKey === e.key
      ) {
        e.preventDefault();
        this.toggleExpanded();
      }
    }

    componentDidMount() {
      document.addEventListener('keydown', this.handleKeyDown);
    }

    componentWillUnmount() {
      document.removeEventListener('keydown', this.handleKeyDown);
    }

    render() {
      const {
        hidden, highlighted, codeTabIsFullscreen, ...passProps
      } = this.props;
      if (hidden) return '';
      const expanded = this.state.expanded || highlighted;
      const tabClass = classNames({
        tab: true,
        [`tab--${tabClassAttribute}`]: true,
        'tab--expanded': expanded,
        'tab--highlighted': highlighted,
        'tab--left': !right,
        'tab--right': right,
        'code-tab--fullscreen': codeTabIsFullscreen,
      });

      const button = (
        <ShaderEditorTabButton
          iconClass={buttonIconClass}
          toggleExpanded={this.toggleExpanded}
          tabClassAttribute={tabClassAttribute}
          title={title}
        />);

      return (
        <section className={tabClass}>
          { !expanded && button }
          <div className="tab__pane-container" >
            <TabComponent
              toggleExpanded={this.toggleExpanded}
              setExpandedState={this.setExpandedState}
              expanded={expanded}
              {...passProps}
            >
              {button}
            </TabComponent>
          </div>
        </section>
      );
    }
  }

  ShaderEditorTab.defaultProps = {
    hidden: false,
    highlighted: false,
    expandKey: 'not a key',
    initExpanded: false,
  };

  ShaderEditorTab.propTypes = ({
    initExpanded: PropTypes.bool,
    hidden: PropTypes.bool,
    highlighted: PropTypes.bool,
    expandKey: PropTypes.string,
    codeTabIsFullscreen: PropTypes.bool.isRequired,
    location: ReactRouterPropTypes.location.isRequired,
  });

  const mapStateToProps = state => ({
    codeTabIsFullscreen: state.codeTab.isFullscreen,
  });

  const ConnectedShaderEditorTab = connect(mapStateToProps)(withRouter(ShaderEditorTab));

  return ConnectedShaderEditorTab;
};

withTab.propTypes = {
  TabComponent: PropTypes.element.isRequired,
  buttonIconClass: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default withTab;
