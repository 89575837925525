import axios from 'axios';

import * as ActionTypes from '../constants/ActionTypes';

export function setCategories(categories) {
  return {
    type: ActionTypes.SET_CATEGORIES,
    payload: categories,
  };
}

export function getCategories(showPrivate = false) {
  return (dispatch) => {
    axios.get(
      `${process.env.REACT_APP_API_URL}/categories`,
      { params: { showPrivate } },
    )
      .then(response => dispatch(setCategories(response.data)));
  };
}

export function updateCategory(category) {
  return {
    type: ActionTypes.UPDATE_CATEGORY,
    payload: category,
  };
}
export function setCategoryPrivacy(id, isPublic) {
  const serverAction = isPublic ? 'setPublic' : 'setPrivate';
  return (dispatch) => {
    axios.post(
      `${process.env.REACT_APP_API_URL}/categories/${id}/${serverAction}`,
    ).then(response => dispatch(updateCategory(response.data)));
  };
}
