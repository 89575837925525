import React from 'react';
import PropTypes from 'prop-types';
import CustomPropTypes from '../../CustomPropTypes';
import classNames from 'classnames';

class CategoryList extends React.Component {
  toggleCategoryPrivacy = (id) => {
    this.props.setCategoryPrivacy(id, !this.props.isPublic);
  }

  render() {
    const { isPublic, title, categories } = this.props;
    const categoryClass = classNames({
      'category-list': true,
      'category-list--private': !isPublic,
      'category-list--public': isPublic,
    });

    return (
      <div className={categoryClass}>
        <h2 className="category-list__title">{title}</h2>
        <ul className="category-list__list">
          {
            categories.map(c => (
              <li className="category-list__category">
                {c.name}
                <button
                  type="button"
                  className="category-list__toggle"
                  onClick={() => this.toggleCategoryPrivacy(c.id)}
                >
                  Make {isPublic ? 'Private' : 'Public' }
                </button>
              </li>),
            )
          }
        </ul>
      </div>
    );
  }
}

CategoryList.defaultProps = {
  isPublic: false,
};

CategoryList.propTypes = {
  title: PropTypes.string.isRequired,
  isPublic: PropTypes.bool,
  categories: PropTypes.arrayOf(CustomPropTypes.category).isRequired,
  setCategoryPrivacy: PropTypes.func.isRequired,
};

export default CategoryList;
