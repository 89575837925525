import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import renderField from '../FormFields/InputField';
import SubmitButton from '../FormFields/SubmitButton';
import CustomPropTypes from '../../CustomPropTypes';
import {
  asyncValidate,
  validateUserObject,
} from '../../utils/form';

class AccountForm extends React.Component {
  componentWillMount() {
    const { user: { username, email, name } } = this.props;
    this.props.initialize({ username, email, name });
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.user.authenticated && nextProps.user.authenticated) {
      this.props.destroy();
      const { user: { username, email, name } } = nextProps;
      this.props.initialize({ username, email, name });
    }
  }

  render() {
    const {
      handleSubmit,
      onSubmit,
      submitting,
      pristine,
    } = this.props;
    return (
      <form className="account-form" onSubmit={handleSubmit(onSubmit)}>
        <Field
          name="username"
          component={renderField}
          type="text"
          label="Username"
        />
        <Field
          name="email"
          component={renderField}
          type="text"
          label="Email"
        />
        <Field
          name="name"
          component={renderField}
          type="text"
          label="Name"
        />
        <SubmitButton
          submitting={submitting}
          pristine={pristine}
          value="Save Changes"
        />
      </form>
    );
  }
}

AccountForm.propTypes = ({
  user: CustomPropTypes.user.isRequired,
  initialize: PropTypes.func.isRequired,
  destroy: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
});

export default reduxForm({
  form: 'account',
  validate: validateUserObject,
  asyncValidate,
  asyncBlurFields: ['email', 'username'],
})(AccountForm);
