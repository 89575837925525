import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import Controls from './Controls';
import withTab from '../ShaderEditorTab/ShaderEditorTab';
import CustomPropTypes from '../../CustomPropTypes';
import QualityButton from './QualityButton';
import Toggle from './Toggle';
import FrameRate from './FrameRate';
import DeleteShaderButton from './DeleteShaderButton';
import {
  setPrivacy, setScale, addImage, deleteShader, setFeatured,
} from '../../actions/shader';
import { updateControl, updateControls } from '../../actions/inputs';
import { setAdminMode } from '../../actions/user';
import {
  frameRate, isNew, userPermissions,
} from '../../selectors/shader';
import { getOptimizedScale } from '../../selectors';

class ControlsTab extends React.Component {
  render() {
    return (
      <div className="controls">
        <Controls />
        <header className="controls__header">
          {this.props.children}
          {!this.props.embed
          && (
            <div className="controls__shader-preferences">
              <div className="controls__scale-container">
                <QualityButton
                  optimizedScale={this.props.optimizedScale}
                  shaderScale={this.props.shader.scale}
                  setScale={this.props.setScale}
                />
                {this.props.userPermissions && !this.props.isNew
              && (
                <Toggle
                  title="Public"
                  name="public"
                  value={!this.props.shader.private}
                  setValue={v => this.props.setPrivacy(!v)}
                />
              )
                }
                {
                  this.props.user.admin && (
                    <Toggle
                      title="Admin mode"
                      name="admin-mode"
                      value={!!this.props.user.adminMode}
                      setValue={this.props.setAdminMode}
                    />
                  )
                }
                {
                  this.props.user.adminMode && (
                    <Toggle
                      title="Featured"
                      name="featured"
                      value={!!this.props.shader.featured}
                      setValue={this.props.setFeatured}
                    />
                  )
                }
                <FrameRate frameRate={this.props.frameRate} />
                {this.props.userPermissions && !this.props.isNew
              && (
                <DeleteShaderButton
                  shaderId={this.props.shader.id}
                  deleteShader={this.props.deleteShader}
                  onDelete={() => this.props.push('/shaders')}
                />
              )
                }
              </div>
            </div>
          )
          }
        </header>
      </div>
    );
  }
}

ControlsTab.defaultProps = ({
  embed: false,
});

ControlsTab.propTypes = ({
  frameRate: PropTypes.number.isRequired,
  embed: PropTypes.bool,
  optimizedScale: PropTypes.number.isRequired,
  isNew: PropTypes.bool.isRequired,
  userPermissions: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.bool.isRequired,
  ]),
  children: PropTypes.node.isRequired,
  shader: CustomPropTypes.shader.isRequired,
  inputs: CustomPropTypes.inputs.isRequired,
  deleteShader: PropTypes.func.isRequired,
  setScale: PropTypes.func.isRequired,
  setPrivacy: PropTypes.func.isRequired,
  setFeatured: PropTypes.func.isRequired,
  updateControl: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  updateControls: PropTypes.func.isRequired,
  addImage: PropTypes.func.isRequired,
  setAdminMode: PropTypes.func.isRequired,
});

const mapStateToProps = state => ({
  shader: state.shader,
  inputs: state.inputs,
  user: state.user,
  isNew: isNew(state),
  userPermissions: userPermissions(state),
  optimizedScale: getOptimizedScale(state),
  frameRate: frameRate(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updateControl,
  updateControls,
  setPrivacy,
  setFeatured,
  addImage,
  setScale,
  deleteShader,
  push,
  setAdminMode,
}, dispatch);

const connectedControlsTab = connect(mapStateToProps, mapDispatchToProps)(ControlsTab);

export const CONTROLS_TAB_CLASS_ATTRIBUTE = 'controls';

export default withTab(
  connectedControlsTab,
  CONTROLS_TAB_CLASS_ATTRIBUTE,
  'fa fa-sliders',
  true,
  'Shader input controls (^+k or ⌘+k)',
);
