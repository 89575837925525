import { ignoreActions } from 'redux-ignore';
import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { reducer as notifications } from 'react-notification-system-redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import * as Actions from '../actions';
import * as ActionTypes from '../constants/ActionTypes';
import shaders from './shaders';
import shader from './shader';
import user from './user';
import profile from './profile';
import categories from './categories';
import search from './search';
import relatedShaders from './relatedShaders';
import popularShaders from './popularShaders';
import featuredShaders from './featuredShaders';
import inputs from './inputs';
import walkthrough from './walkthrough';
import codeTab from './codeTab';
import { connectRouter } from 'connected-react-router'

const errorMessage = (state = {}, { type, error }) => {
  switch (type) {
    case Actions.SET_ERROR_MESSAGE:
      return error;
    case Actions.RESET_ERROR_MESSAGE:
      return {};
    default:
      return state;
  }
};

/*
 * Persist walkthrough state so we don't annoy the
 * user with it every time they access the site
 */
const persistedWalkthrough = persistReducer({
  key: 'walkthrough',
  storage,
}, walkthrough);

// These reducers don't receive certain high-volume notification streams
// E.g. framerate, control updates
const nonUpdateReducers = {
  errorMessage,
  form,
  shaders,
  shader,
  user,
  profile,
  categories,
  search,
  notifications,
  relatedShaders,
  popularShaders,
  featuredShaders,
  walkthrough: persistedWalkthrough,
  codeTab,
};

// These reducers do recieve these actions
const makeIgnoringReducer = (
  reducer => ignoreActions(reducer, [ActionTypes.UPDATE_CONTROLS, ActionTypes.SET_FRAME_RATE])
);

const ignoredReducers = Object.entries(nonUpdateReducers).reduce(
  (acc, [k, v]) => ({ ...acc, [k]: makeIgnoringReducer(v) }),
  {},
);

const frameRate = ((state = -1, action) => (action.type === ActionTypes.SET_FRAME_RATE
  ? (action.payload || -1)
  : state
));

export default history => combineReducers({
  ...ignoredReducers,
  inputs,
  frameRate,
  router: connectRouter(history),
});
