import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ShaderMessage = (props) => {
  const shaderMessageClass = classNames({
    'shader-message': true,
    'shader-message--mobile': props.mobile,
    'shader-message--error': props.error,
    'shader-message--warning': props.warning,
  });
  return (
    <div className={shaderMessageClass}>
      <p>
        {props.message || 'No output'}
      </p>
    </div>
  );
};

ShaderMessage.defaultProps = ({
  error: {},
  warning: '',
  message: '',
  mobile: false,
});

ShaderMessage.propTypes = ({
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  warning: PropTypes.string,
  message: PropTypes.string,
  mobile: PropTypes.bool,
});

export default ShaderMessage;
