// SHADERS
export const SET_SHADERS = 'SET_SHADERS';
export const NOOP = 'NOOP';

// SHADER
export const TRIGGER_SNAPSHOT = 'TRIGGER_SNAPSHOT';
export const FINISH_SNAPSHOT = 'FINISH_SNAPSHOT';
export const SHADER_ERROR = 'SHADER_ERROR';
export const SET_SHADER = 'SET_SHADER';
export const SET_FETCH_SHADER_FAILED = 'FETCH_SHADER_FAILED';
export const SET_FEATURED = 'SET_FEATURED';
export const SET_FRAGMENT_SOURCE = 'SET_FRAGMENT_SOURCE';
export const SET_FRAGMENT_EDITED = 'SET_FRAGMENT_EDITED';
export const SET_VERTEX_SOURCE = 'SET_VERTEX_SOURCE';
export const SET_VERTEX_EDITED = 'SET_VERTEX_EDITED';
export const NEW_SHADER = 'NEW_SHADER';
export const SET_OWNER = 'SET_OWNER';
export const SET_FRAME_RATE = 'SET_FRAME_RATE';
export const SET_SCALE = 'SET_SCALE';
export const STAR_SHADER = 'STAR_SHADER';
export const UNSTAR_SHADER = 'UNSTAR_SHADER';
export const SET_SHADER_SOURCE = 'SET_SHADER_SOURCE';
export const SET_SHADER_FORKING = 'SET_SHADER_FORKING';
export const SET_SHADER_TITLE = 'SET_SHADER_TITLE';
export const SET_THUMBNAIL = 'SET_THUMBNAIL';
export const ADD_IMAGE = 'ADD_IMAGE';
export const RESET_IMAGE = 'RESET_IMAGE';
export const UPDATE_CONTROLS = 'UPDATE_CONTROLS';
export const SET_PENDING_CREATE = 'SET_PENDING_CREATE';
export const SET_PENDING_FORK = 'SET_PENDING_FORK';
export const UPGRADE_SHADER = 'UPGRADE_SHADER';

// PENDING ACTIONS
export const PENDING_CREATE = 'PENDING_CREATE';
export const PENDING_FORK = 'PENDING_FORK';

// SEARCH
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';
export const INCREMENT_SEARCH_PAGE = 'INCREMENT_SEARCH_PAGE';
export const DECREMENT_SEARCH_PAGE = 'DECREMENT_SEARCH_PAGE';
export const RESET_SEARCH_PAGE = 'RESET_SEARCH_PAGE';

// CATEGORIES
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';

// USER
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const SET_USER_AUTH_PENDING = 'SET_USER_AUTH_PENDING';
export const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
export const UNAUTHENTICATE_USER = 'UNAUTHENTICATE_USER';
export const AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR';
export const RESET_PASSWORD_INITIATE = 'RESET_PASSWORD_INITIATE';
export const RESET_PASSWORD_COMPLETE = 'RESET_PASSWORD_COMPLETE';
export const SET_ADMIN_MODE = 'SET_ADMIN_MODE';

// PROFILE
export const GET_PROFILE = 'GET_PROFILE';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_PROFILE_LOADING = 'SET_PROFILE_LOADING';
export const SET_PROFILE_LOADING_FAILED = 'SET_PROFILE_LOADING_FAILED';

// RELATED, POPULAR, FEATURED SHADERS
export const SET_RELATED_SHADERS = 'SET_RELATED_SHADERS';
export const SET_POPULAR_SHADERS = 'SET_POPULAR_SHADERS';
export const SET_FEATURED_SHADERS = 'SET_FEATURED_SHADERS';

// SHADER EDITOR WALKTHROUGH
export const INCREMENT_PAGE = 'INCREMENT_PAGE';
export const DECREMENT_PAGE = 'DECREMENT_PAGE';
export const ENABLE_WALKTHROUGH = 'ENABLE_WALKTHROUGH';
export const DISABLE_WALKTHROUGH = 'DISABLE_WALKTHROUGH';
export const SET_WALKTHROUGH_MOBILE = 'SET_WALKTHROUGH_MOBILE';
export const SET_WALKTHROUGH_DESKTOP = 'SET_WALKTHROUGH_DESKTOP';

// CODE TAB
export const TOGGLE_IS_FULLSCREEN = 'TOGGLE_IS_FULLSCREEN';
export const SET_OPACITY = 'SET_OPACITY';
