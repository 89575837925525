import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CodePane from './CodePane';
import CustomPropTypes from '../../CustomPropTypes';

const CodePaneButton = (props) => {
  const codePaneButtonClass = classNames({
    'code-pane-button': true,
    'code-pane-button--expanded': props.expanded,
  });
  return (
    <div className={codePaneButtonClass}>
      <div className="code-pane-button__retracted">
        <button
          className="code-pane-button__icon"
          onClick={props.expand}
          type="button"
        >
          <i className="fa fa-code" />
        </button>
      </div>
      <div className="code-pane-button__expanded">
        <button
          className="code-pane-button__icon"
          onClick={props.retract}
          type="button"
        >
          <i className="fa fa-code" />
        </button>
        <div className="code-pane__mobile-container">
          <CodePane
            shader={props.shader}
            setFragmentSource={props.setFragmentSource}
            setVertexSource={props.setVertexSource}
            isNew={props.isNew}
            setShader={props.setShader}
            isUserOwner={props.isUserOwner}
            user={props.user}
            mobile
          />
        </div>
      </div>
    </div>
  );
};

CodePaneButton.propTypes = {
  shader: CustomPropTypes.shader.isRequired,
  user: CustomPropTypes.user.isRequired,
  expanded: PropTypes.bool.isRequired,
  isUserOwner: PropTypes.bool.isRequired,
  isNew: PropTypes.bool.isRequired,
  expand: PropTypes.func.isRequired,
  retract: PropTypes.func.isRequired,
  setFragmentSource: PropTypes.func.isRequired,
  setVertexSource: PropTypes.func.isRequired,
  setShader: PropTypes.func.isRequired,
};

export default CodePaneButton;
