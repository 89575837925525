import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ace from 'brace';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import debounce from 'lodash.debounce';

import CustomPropTypes from '../../CustomPropTypes';
import {
  setShaderSource,
  createShader,
  saveShader,
  triggerSnapshot,
  setVertexEdited,
  setFragmentEdited,
  upgradeShader,
} from '../../actions/shader';
import { isNew, userPermissions } from '../../selectors/shader';
import { decomposeShaderError } from '../../utils/shaderErrors';

require('brace/ext/searchbox');
require('brace/mode/c_cpp');
require('brace/theme/monokai');

/*
 * Detect if a two sources differ and neither are null
 * (which can occur during UI initialization and shouldn't trigger an update)
 */
const sourcesDiffer = (sourceA, sourceB) => (
  sourceA
  && sourceB
  && (sourceA.replace(/\r\n/g, '\n') !== sourceB.replace(/\r\n/g, '\n'))
);

class CodePane extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shaderEditing: 'fragment',
      rawFragmentSource: props.shader.rawFragmentSource || '',
      rawVertexSource: props.shader.rawVertexSource || '',
      fullscreen: false,
      hideConversionPrompt: false,
    };
  }

  handleEditorChange = (e) => {
    const { shaderEditing, rawFragmentSource, rawVertexSource } = this.state;
    const { shader } = this.props;
    const newSource = this.editor.getValue().replace(/\r\n/g, '\n');
    if (
      shaderEditing === 'fragment'
      && sourcesDiffer(rawFragmentSource, newSource)
    ) {
      this.setState({ rawFragmentSource: newSource || '' });
      if (!shader.fragmentEdited) this.props.setFragmentEdited();
    } else if (
      shaderEditing === 'vertex'
      && sourcesDiffer(rawVertexSource, newSource)
    ) {
      this.setState({ rawVertexSource: newSource || '' });
      if (!shader.vertexEdited) this.props.setFragmentEdited();
    }
  }

  handleEditorChanges = debounce(this.handleEditorChange, 200)

  componentDidMount() {
    this.setState({ fullscreen: false });
    this.editor = ace.edit('editor');
    const { editor } = this;
    editor.getSession().setMode('ace/mode/c_cpp');
    editor.container.classList.add('code-pane__ace');
    editor.setTheme('ace/theme/monokai');
    editor.setValue(this.state.rawFragmentSource || '', -1);
    editor.$blockScrolling = Infinity;
    editor.getSession().on('change', e => this.handleEditorChanges(e));
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount = () => {
    this.editor.getSession().removeAllListeners('change');
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  setShaderEditing = value => () => {
    this.setState({
      shaderEditing: value,
    });
  }

  componentWillUpdate(newProps) {
    const { shader } = this.props;
    const { editor } = this;
    if (!shader.initialized && newProps.shader.initialized) {
      this.setState({ rawFragmentSource: newProps.shader.rawFragmentSource });
      this.setState({ rawVertexSource: newProps.shader.rawVertexSource });
    }
    if (!editor) { return; }
    if (this.state.shaderEditing === 'fragment') {
      if (shader.rawFragmentSource !== newProps.shader.rawFragmentSource
        && newProps.shader.rawFragmentSource !== editor.getValue()) {
        this.setState({ rawFragmentSource: newProps.shader.rawFragmentSource });
      }
    } else if (shader.rawVertexSource !== newProps.shader.rawVertexSource
        && newProps.shader.rawVertexSource !== editor.getValue()) {
      this.setState({ rawVertexSource: newProps.shader.rawVertexSource });
    }
  }

  componentDidUpdate(oldProps, oldState) {
    const { shader } = this.props;
    const { shaderEditing } = this.state;
    const { editor } = this;
    if (!editor) { return; }

    if (oldProps.shader.isfVersion !== shader.isfVersion) {
      this.setState({
        rawFragmentSource: shader.rawFragmentSource,
        rawVertexSource: shader.rawVertexSource,
      });
    }

    if (shaderEditing !== oldState.shaderEditing) {
      if (shaderEditing === 'fragment') {
        editor.setValue(shader.rawFragmentSource || '', -1);
      } else {
        editor.setValue(shader.rawVertexSource || '', -1);
      }
    }

    if (shaderEditing === 'fragment') {
      if (oldProps.shader.rawFragmentSource !== shader.rawFragmentSource
        && shader.rawFragmentSource !== editor.getValue()) {
        editor.setValue(shader.rawFragmentSource || '', -1);
      }
    } else if (oldProps.shader.rawVertexSource !== shader.rawVertexSource
        && shader.rawVertexSource !== editor.getValue()) {
      editor.setValue(shader.rawVertexSource || '', -1);
    }

    if (shader.error) {
      const annotations = decomposeShaderError(
        shader.error,
        shader.rawFragmentSource,
        shader.fragmentShader,
      );
      editor.getSession().setAnnotations(annotations);
    }
  }

  createOrSaveShader = () => {
    this.updateShader();
    if (this.props.isNew) {
      this.props.createShader(
        this.state.rawFragmentSource,
        this.state.rawVertexSource,
        this.props.shader.title,
      );
    } else {
      const newShader = {
        rawFragmentSource: this.state.rawFragmentSource,
        rawVertexSource: this.state.rawVertexSource,
        title: this.props.shader.title,
      };
      this.props.saveShader(newShader);
    }
    this.props.triggerSnapshot();
  }

  updateShader = () => {
    this.props.setShaderSource(
      this.state.rawVertexSource,
      this.state.rawFragmentSource,
      this.props.shader.title,
    );
  }

  handleKeyDown = (e) => {
    if ((!e.ctrlKey && !e.metaKey) || !this.props) {
      return;
    }
    switch (e.key) {
      case 's':
      case 'S':
        e.preventDefault();
        this.createOrSaveShader();
        break;
      case 'u':
      case 'U':
        e.preventDefault();
        if (this.props.expanded) {
          this.props.toggleFullscreen();
        } else {
          this.props.setExpandedState(true);
        }
        break;
      default:
    }
  }

  hideConversion = () => {
    this.setState({ hideConversionPrompt: true });
  }

  handleConversion = () => {
    this.props.upgradeShader();
  }

  render() {
    const { shaderEditing, hideConversionPrompt } = this.state;
    const fsButtonClass = classNames({
      toolbar__button: true,
      'toolbar__button--selected': shaderEditing === 'fragment',
    });
    const fsAriaSelected = shaderEditing === 'fragment';
    const vsButtonClass = classNames({
      toolbar__button: true,
      'toolbar__button--selected': shaderEditing === 'vertex',
    });
    const vsAriaSelected = shaderEditing === 'vertex';
    const { userPermissions, mobile, opacity, shader } = this.props;
    const conversionPrompt = !hideConversionPrompt && (!shader.isfVersion || shader.isfVersion < 2);
    return (
      <div className="code-pane__editor-container" style={{ opacity }}>
        <div className="code-pane__editor-toolbar">
          <div className="toolbar__left-items">
            <div className="toolbar__button-group" role="tablist">
              <button
                className={fsButtonClass}
                onClick={this.setShaderEditing('fragment')}
                type="button"
                title="Fragment shader code"
                aria-selected={fsAriaSelected}
                role="tab"
              >
                FS
              </button>
              <button
                className={vsButtonClass}
                onClick={this.setShaderEditing('vertex')}
                type="button"
                title="Vertex shader code"
                aria-selected={vsAriaSelected}
                role="tab"
              >
                VS
              </button>
            </div>
            <div className="toolbar__button-group">
              <button
                className="toolbar__button"
                type="button"
                onClick={this.updateShader}
                title="Reload shader"
              >
                <i className="fa fa-refresh" />
              </button>
              { (userPermissions)
                && (
                  <button
                    className="toolbar__button"
                    onClick={this.createOrSaveShader}
                    type="button"
                    title="Save shader (^+s or ⌘-s)"
                  >
                    Save
                  </button>
                )
              }
              {
                (!mobile) && (
                  <Fragment>
                    <button
                      className="toolbar__button"
                      type="button"
                      onClick={this.props.toggleFullscreen}
                      title="Toggle fullscreen mode (^+u or ⌘-u)"
                    >
                      <i className="fa fa-expand" />
                    </button>
                    <div
                      className="toolbar__range-container"
                    >
                      <i className="fa fa-eye-slash" />
                      <input
                        className="toolbar__range"
                        type="range"
                        min="0.25"
                        max="1"
                        step="0.05"
                        onChange={this.props.setOpacity}
                        value={opacity}
                        title="Adjust opacity of editor"
                      />
                    </div>
                  </Fragment>
                )
              }
            </div>
          </div>
        </div>
        <div id="editor" className="code-pane__editor" />
        {
          conversionPrompt && (
            <div className="conversion-prompt">
              <div className="conversion-prompt__text">
                This looks like an ISF V1 shader. Would you like to try upgrading to V2?
              </div>
              <button
                className="conversion-prompt__button"
                onClick={this.handleConversion}
              >
                Yes
              </button>
              <button
                className="conversion-prompt__button"
                onClick={this.hideConversion}
              >
                No
              </button>
            </div>
          )

        }
      </div>
    );
  }
}

CodePane.defaultProps = ({
  setExpandedState: () => ({}),
  expanded: false,
});

CodePane.propTypes = ({
  shader: CustomPropTypes.shader.isRequired,
  user: CustomPropTypes.user.isRequired,
  isNew: PropTypes.bool.isRequired,
  expanded: PropTypes.bool,
  userPermissions: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.bool.isRequired,
  ]),
  createShader: PropTypes.func.isRequired,
  saveShader: PropTypes.func.isRequired,
  setShaderSource: PropTypes.func.isRequired,
  toggleFullscreen: PropTypes.func.isRequired,
  setOpacity: PropTypes.func.isRequired,
  setExpandedState: PropTypes.func.isRequired,
  setVertexEdited: PropTypes.func.isRequired,
  setFragmentEdited: PropTypes.func.isRequired,
  triggerSnapshot: PropTypes.func.isRequired,
  upgradeShader: PropTypes.func.isRequired,
  mobile: PropTypes.bool,
});

CodePane.defaultProps = {
  mobile: false,
};

const mapStateToProps = state => ({
  user: state.user,
  shader: state.shader,
  userPermissions: userPermissions(state),
  isNew: isNew(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setShaderSource,
  createShader,
  saveShader,
  triggerSnapshot,
  setFragmentEdited,
  setVertexEdited,
  upgradeShader,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CodePane);
