import axios from 'axios';

import * as ActionTypes from '../constants/ActionTypes';

// does relatedShaders need the shaderId?
export function setRelatedShaders(shaders) {
  return {
    type: ActionTypes.SET_RELATED_SHADERS,
    payload: shaders,
  };
}

export function getRelatedShaders(shaderId) {
  return (dispatch) => {
    axios.get(`${process.env.REACT_APP_API_URL}/shaders/${shaderId}/related`)
      .then((response) => {
        dispatch(setRelatedShaders(response.data));
      });
  };
}
