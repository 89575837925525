import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import renderField from './LoginField';
import LoginButton from './LoginButton';

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Please enter an email';
  }
  if (!values.password) {
    errors.password = 'Please enter a password';
  }
  return errors;
};

const LoginForm = ({
  handleSubmit,
  onSubmit,
  submitting,
  pristine,
}) => (
  <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
    <Field name="email" component={renderField} type="text" label="Email or Username" />
    <Field name="password" component={renderField} type="password" label="Password" />
    <LoginButton
      submitting={submitting}
      pristine={pristine}
      value="Login"
    />
  </form>
);

LoginForm.propTypes = ({
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
});

const WrappedLoginForm = reduxForm({
  form: 'login',
  validate,
})(LoginForm);

export default WrappedLoginForm;
