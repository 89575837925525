import axios from 'axios';
import * as ActionTypes from '../constants/ActionTypes';
import { DATE_CREATED_DESC, POPULARITY_DESC } from '../constants/sortOptions';

export const FEATURED_SHADERS_KEY = 'featuredShaders';
export const RECENT_SHADERS_KEY = 'recentShaders';
export const POPULAR_SHADERS_KEY = 'popularShaders';

export const SHADERS_KEYS = [
  FEATURED_SHADERS_KEY,
  RECENT_SHADERS_KEY,
  POPULAR_SHADERS_KEY,
];

// These shaders are used on the homepage

export function setShaders(shaders, key = 'defaultShaders') {
  return {
    type: ActionTypes.SET_SHADERS,
    payload: { key, shaders },
  };
}

function getShaders(options, key) {
  return (dispatch) => {
    axios.get(`${process.env.REACT_APP_API_URL}/shaders`, { params: options })
      .then(({ data }) => dispatch(setShaders(data, key)));
  };
}

function searchShaders(options, key) {
  return (dispatch) => {
    axios.get(`${process.env.REACT_APP_API_URL}/search`, { params: options })
      .then(({ data }) => dispatch(setShaders(data.shaders, key)));
  };
}

export function getFeaturedShaders() {
  return (dispatch) => {
    // get some extra in case some are invalid
    dispatch(getShaders({ featured: true, limit: 10 }, FEATURED_SHADERS_KEY));
  };
}

export function getRecentShaders() {
  return (dispatch) => {
    dispatch(searchShaders({ sort: DATE_CREATED_DESC }, RECENT_SHADERS_KEY));
  };
}

export function getPopularShaders() {
  return (dispatch) => {
    dispatch(searchShaders({ sort: POPULARITY_DESC }, POPULAR_SHADERS_KEY));
  };
}
