import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const renderField = ({
  input,
  label,
  type,
  meta: { touched, error, warning },
}) => {
  const fieldClass = classNames({
    'form-field': true,
    'form-field--error': touched && error,
    'form-field--warning': touched && warning,
  });
  return (
    <div className={fieldClass}>
      <div className="form-field__input-container">
        <input
          {...input}
          type={type}
          placeholder={label}
          className="form-field__input"
        />
        {touched
          && ((error
            && (
              <span className="form-field__error">
                {error}
              </span>
            ))
            || (warning
              && (
                <span className="form-field__warning">
                  {warning}
                </span>
              )))}
      </div>
    </div>
  );
};

renderField.propTypes = {
  input: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string,
    warning: PropTypes.string,
  }).isRequired,
};

export default renderField;
