import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ShaderMessage from './ShaderMessage';

const ShaderMessageButton = (props) => {
  const shaderMessageButtonClass = classNames({
    'shader-message-button': true,
    'shader-message-button--expanded': props.expanded,
  });

  return (
    <div className={shaderMessageButtonClass}>
      <div className="shader-message-button__retracted">
        <button
          className="shader-message-button__icon"
          onClick={props.expand}
          type="button"
        >
          <i className="fa fa-terminal" />
        </button>
      </div>
      <div className="shader-message-button__expanded">
        <button
          className="shader-message-button__icon"
          onClick={props.retract}
          type="button"
        >
          <i className="fa fa-terminal" />
        </button>
        <div className="shader-message__mobile-container">
          <ShaderMessage
            error={props.error}
            warning={props.warning}
            message={props.error && props.error.message}
            mobile
          />
        </div>
      </div>
    </div>
  );
};

ShaderMessageButton.propTypes = {
  expanded: PropTypes.bool.isRequired,
  expand: PropTypes.func.isRequired,
  retract: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  warning: PropTypes.string,
  message: PropTypes.string,

};

export default ShaderMessageButton;
