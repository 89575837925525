import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import Resizable from 're-resizable';
import CodePane from './CodePane';
import { userPermissions } from '../../selectors/shader';
import CustomPropTypes from '../../CustomPropTypes';
import ShaderTitleInput from '../ShaderTitleInput/ShaderTitleInput';
import withTab from '../ShaderEditorTab/ShaderEditorTab';
import { setOpacity, toggleIsFullscreen } from '../../actions/codeTab';

const CodeTab = ({
  opacity,
  isFullscreen,
  shader,
  expanded,
  setExpandedState,
  toggleIsFullscreen,
  setOpacity,
  userPermissions,
  children,
}) => {
  const codePaneClass = classNames({
    'code-pane': true,
    'code-pane--fullscreen': isFullscreen,
  });

  const inner = (<Fragment>
    <div className="code-pane__header-row">
      <div className="code-pane__header-row__left-item">
        {children}
      </div>
      <div className="code-pane__header-row__right-items" style={{ opacity }}>
        <ShaderTitleInput inputClass="code-pane__title" />
        {shader.username
            && (
              <h3 className="code-pane__author">
                by
                {' '}
                <Link to={`/u/${shader.username}`}>
                  {shader.username}
                </Link>
              </h3>
            )
        }
      </div>
    </div>
    <CodePane
      expanded={expanded}
      setExpandedState={setExpandedState}
      toggleFullscreen={toggleIsFullscreen}
      setOpacity={e => setOpacity(e.target.value)}
      opacity={opacity}
    />
  </Fragment>);

  return (
    <section
      className="code-pane__container"
    >
      { isFullscreen
        ? <div className={codePaneClass}>{inner}</div>
        : <Resizable
          className={codePaneClass}
          defaultSize={{
            width: userPermissions ? 575 : 500,
            height: '100%',
          }}
          minWidth={userPermissions ? 575 : 500}
          enable={{
            top: false,
            right: true,
            bottom: false,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false,
          }}
        >
          { inner }
        </Resizable>
      }
    </section>
  );
};

CodeTab.propTypes = ({
  expanded: PropTypes.bool.isRequired,
  setExpandedState: PropTypes.func.isRequired,
  setOpacity: PropTypes.func.isRequired,
  toggleIsFullscreen: PropTypes.func.isRequired,
  shader: CustomPropTypes.shader.isRequired,
  children: PropTypes.node.isRequired,
  isFullscreen: PropTypes.bool.isRequired,
  opacity: PropTypes.number.isRequired,
  userPermissions: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.bool.isRequired,
  ]),
});

const mapStateToProps = state => ({
  userPermissions: userPermissions(state),
  isFullscreen: state.codeTab.isFullscreen,
  opacity: state.codeTab.opacity,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setOpacity,
  toggleIsFullscreen,
}, dispatch);

const ConnectedCodeTab = connect(mapStateToProps, mapDispatchToProps)(CodeTab);

export const CODE_TAB_CLASS_ATTRIBUTE = 'code-tab';

export default withTab(
  ConnectedCodeTab,
  CODE_TAB_CLASS_ATTRIBUTE,
  'fa fa-code',
  false,
  'Shader source editor (^+b or ⌘+b)',
);
