import React from 'react';
import PropTypes from 'prop-types';
import ShaderList from '../ShaderList/ShaderList';
import CustomPropTypes from '../../CustomPropTypes';

const ShadersTab = ({
  noneMessage, shaders, handleOpenShader, loading,
}) => (
  (shaders && shaders.length > 0) ? (
    <ul className="related-shaders__list">
      <ShaderList
        shaders={shaders}
        handleOpenShader={handleOpenShader}
      />
    </ul>)
    : (
      <div className="related-shaders__none">
        { loading ? 'Loading...' : noneMessage }
      </div>
    )
);
ShadersTab.defaultProps = ({
  handleOpenShader: () => ({}),
  noneMessage: 'No shaders',
  loading: false,
});

ShadersTab.propTypes = ({
  shaders: PropTypes.arrayOf(CustomPropTypes.shader).isRequired,
  handleOpenShader: PropTypes.func,
  noneMessage: PropTypes.string,
  loading: PropTypes.bool,
});

export default ShadersTab;
