import * as ActionTypes from '../constants/ActionTypes';

const categories = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.SET_CATEGORIES:
      return action.payload;
    case ActionTypes.UPDATE_CATEGORY: {
      const updateIndex = state.findIndex(c => c.id === action.payload.id);
      if (updateIndex) {
        return [
          ...state.slice(0, updateIndex),
          action.payload,
          ...state.slice(updateIndex + 1),
        ];
      }
      return [...state, action.payload];
    }
    default:
      return state;
  }
};

export default categories;
