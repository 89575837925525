import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import renderField from './LoginField';
import LoginButton from './LoginButton';

import {
  asyncValidate,
  validateUserObject,
} from '../../utils/form';

const validate = (values) => {
  const errors = validateUserObject(values);

  if (!values.name) {
    errors.name = 'Please enter your name.';
  }

  if (!values.password) {
    errors.password = 'Please enter a password';
  }

  if (!values.confirmPassword) {
    errors.confirmPassword = 'Please enter a password confirmation';
  }

  if (values.password !== values.confirmPassword) {
    errors.password = 'Passwords must match';
  }

  return errors;
};

const SignUpForm = ({
  handleSubmit,
  onSubmit,
  submitting,
  pristine,
}) => (
  <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
    <Field name="email" component={renderField} type="email" label="Email" />
    <Field name="name" component={renderField} type="text" label="Name" />
    <Field name="username" component={renderField} type="text" label="Username" />
    <Field name="password" component={renderField} type="password" label="Password" />
    <Field name="confirmPassword" component={renderField} type="password" label="Confirm Password" />
    <LoginButton
      submitting={submitting}
      pristine={pristine}
      value="Sign Up"
    />
  </form>);

SignUpForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
};

const WrappedSignUpForm = reduxForm({
  form: 'signup',
  validate,
  asyncValidate,
  asyncBlurFields: ['email', 'username'],
  onSubmitFail: (errors) => {
    console.log(errors);
  },
})(SignUpForm);

export default WrappedSignUpForm;
