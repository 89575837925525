import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import renderField from './LoginField';
import LoginButton from './LoginButton';

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Please enter an email';
  }
  return errors;
};

const ResetForm = ({
  handleSubmit,
  onSubmit,
  submitting,
  pristine,
}) => (
  <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
    <Field name="email" component={renderField} type="text" label="Email or Username" />
    <LoginButton
      submitting={submitting}
      pristine={pristine}
      value="Request reset link"
    />
  </form>
);

ResetForm.propTypes = ({
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
});


const WrappedResetForm = reduxForm({
  form: 'reset',
  validate,
  onSubmitSuccess: (result, dispatch, props) => {
    props.onSubmitSuccess();
  },
})(ResetForm);

export default WrappedResetForm;
