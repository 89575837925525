import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import renderField from './LoginField';
import LoginButton from './LoginButton';

const validate = (values) => {
  const errors = {};
  if (!values.newPassword) {
    errors.newPassword = 'Please enter a password';
  }

  if (!values.confirmPassword) {
    errors.confirmPassword = 'Please enter a password confirmation';
  }

  if (values.newPassword !== values.confirmPassword) {
    errors.newPassword = 'Passwords must match';
  }


  return errors;
};

const ResetForm = (props) => {
  const {
    handleSubmit,
    onSubmit,
    submitting,
    pristine,
    change,
    token,
  } = props;

  change('token', token);

  return (token ? (
    <form
      className="login-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Field
        name="token"
        component={() => null}
        type="text"
        label="token"
      />
      <Field
        name="newPassword"
        component={renderField}
        type="password"
        label="New Password"
      />
      <Field
        name="confirmPassword"
        component={renderField}
        type="password"
        label="Confirm New Password"
      />
      <LoginButton
        submitting={submitting}
        pristine={pristine}
        value="Reset"
      />
    </form>
  ) : (
    <div>
      Error: Missing reset token. Check your password reset URL.
    </div>
  ));
};

ResetForm.propTypes = ({
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  token: PropTypes.string.isRequired,
});

const WrappedResetForm = reduxForm({
  form: 'reset',
  validate,
  onSubmitSuccess: (result, dispatch, props) => {
    props.onSubmitSuccess();
  },
})(ResetForm);

export default WrappedResetForm;
