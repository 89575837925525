import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import withDropdownTooltip from '../DropdownTooltip/DropdownTooltip';

const getScaleLabel = (scale) => {
  if (scale === 0.5) return 'Half';
  if (scale === 1.0) return 'Full';
  if (scale === 2.0) return 'HD';
  return '';
};

const QualityButton = ({ shaderScale, optimizedScale }) => {
  const valueText = shaderScale ? getScaleLabel(optimizedScale) : 'Auto';
  return (
    <span title="Resolution setting" className="quality-button__content">
      <i className="fa fa-cog" />
      <span className="quality-button__label">
        {valueText}
      </span>
    </span>
  );
};

QualityButton.propTypes = {
  shaderScale: PropTypes.number,
  optimizedScale: PropTypes.number.isRequired,
};

const QualityTooltip = (props) => {
  const { shaderScale, optimizedScale, setScale } = props;
  const autoResClass = classNames({
    'quality-option': true,
    'quality-option--selected': !shaderScale,
  });
  const halfResClass = classNames({
    'quality-option': true,
    'quality-option--selected': shaderScale === 0.5,
  });
  const fullResClass = classNames({
    'quality-option': true,
    'quality-option--selected': shaderScale === 1.0,
  });
  const hdResClass = classNames({
    'quality-option': true,
    'quality-option--selected': shaderScale === 2.0,
  });

  return (
    <div className="quality-tooltip">
      <h2 className="quality-tooltip__header">
        Quality
      </h2>
      <ul className="quality-options">
        <li className={autoResClass}>
          <button
            onClick={() => setScale(0)}
            type="button"
            title="Resolution"
          >
            <span>
              Auto{!shaderScale ? ` (${getScaleLabel(optimizedScale)})` : ''}
            </span>
            <i className="fa fa-check quality-option__selected" />
          </button>
        </li>
        <li className={halfResClass}>
          <button
            onClick={() => setScale(0.5)}
            type="button"
            title="Resolution"
          >
            <span>
              Half Res
            </span>
            <i className="fa fa-check quality-option__selected" />
          </button>
        </li>
        <li className={fullResClass}>
          <button
            onClick={() => setScale(1.0)}
            type="button"
            title="Resolution"
          >
            <span>
              Full Res
            </span>
            <i className="fa fa-check quality-option__selected" />
          </button>
        </li>
        <li className={hdResClass}>
          <button
            onClick={() => setScale(2.0)}
            type="button"
            title="Resolution"
          >
            <span>
              HD
            </span>
            <i className="fa fa-check quality-option__selected" />
          </button>
        </li>
      </ul>
    </div>
  );
};

QualityTooltip.propTypes = {
  shaderScale: PropTypes.number,
  setScale: PropTypes.func.isRequired,
};

export default withDropdownTooltip(QualityButton, QualityTooltip);
