import React from 'react';
import { connect } from 'react-redux';
import CustomPropTypes from '../../CustomPropTypes';
import ReactRouterPropTypes from 'react-router-prop-types';
import TopMenu from '../../components/TopMenu/TopMenu';

class NotFound extends React.Component {
  render() {
    const { user, match: { params: { username, shaderId } } } = this.props;
    let thingNotFound = 'page';
    if (username) {
      thingNotFound = 'user';
    } else if (shaderId) {
      thingNotFound = 'shader';
    }
    return (
      <section className="login">
        <TopMenu
          menuType="default"
        />
        <section className="not-found">

          <div className="not-found-main">
            <h1>Oops! We can’t find that {thingNotFound}.</h1>
            <p>Not a big deal, this is a small site. Also, please bear with us as we roll out this new version of the site.</p>
          </div>

          <div className="not-found-suggestions">

            <div className="not-found-suggestion">
              <h2>Not sure where to get started with ISF?</h2>
              <p>ISF can be a little daunting, you might want keep the <a href="https://docs.isf.video/" target="_blank" rel="noopener noreferrer">documentation</a> handy. If you want to jump right in, check out the <a href="https://docs.isf.video/quickstart.html" target="_blank" rel="noopener noreferrer">quickstart</a>.</p>
            </div>

            <div className="not-found-suggestion">
              <h2>Where you looking for a shader?</h2>
              <p>If this was the address of a shader you’re trying to access, head over to the <a href="/shaders">search page</a>.</p>
            </div>

            { user.authenticated
              ? (
                <div className="not-found-suggestion">
                  <h2>Where you looking for your info?</h2>
                  <p>Maybe you were looking for <a href={`/u/${user.username}`}>your profile page</a> or the <a href="/shaders">settings page</a> for you account.</p>
                </div>
              )
              : (
                <div className="not-found-suggestion">
                  <h2>Need some help?</h2>
                  <p>Please <a href="mailto:isf@vidvox.net">send us an email</a> and let us know what you’re trying to find. We’ll help you out.</p>
                </div>
              )
            }


          </div>

        </section>

      </section>

    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

NotFound.propTypes = ({
  match: ReactRouterPropTypes.match.isRequired,
  user: CustomPropTypes.user.isRequired,
});

export default (connect(mapStateToProps)(NotFound));
