import * as ActionTypes from '../constants/ActionTypes';

export const toggleIsFullscreen = () => ({
  type: ActionTypes.TOGGLE_IS_FULLSCREEN,
});

export const setOpacity = (opacity = 1) => ({
  type: ActionTypes.SET_OPACITY,
  payload: opacity,
});
