import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { completePasswordReset } from '../../actions/user';
import ResetPasswordForm from '../../components/LoginForms/ResetPasswordForm';
import TopMenu from '../../components/TopMenu/TopMenu';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = { resetSuccess: false };
  }

  handleSuccess = () => {
    this.setState({ resetSuccess: true });
  }

  render() {
    return (
      <section className="reset-password">
        <TopMenu
          menuType="default"
        />
        <div className="login__main">
          <h1 className="login__header">
            Reset Password
          </h1>
          <Link className="login__alternate-link" to="/login">
            Return to Login
          </Link>
          { (this.state.resetSuccess) ? (
            <div className="login__dialog">
              Password reset.
              You may now log in with your new password.
            </div>
          ) : (
            <ResetPasswordForm
              onSubmit={this.props.completePasswordReset}
              onSubmitSuccess={this.handleSuccess}
              token={this.props.match.params.token}
            />
          )
          }
        </div>
      </section>
    );
  }
}

function mapStateToProps() {
  return {
  };
}

function mapDispatchToProps() {
  return {
    completePasswordReset,
  };
}

Login.propTypes = ({
  completePasswordReset: PropTypes.func.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
});


export default connect(mapStateToProps, mapDispatchToProps)(Login);
