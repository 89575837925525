import axios from 'axios';
import * as ActionTypes from '../constants/ActionTypes';
import { getSearchParams } from '../selectors';
import { push } from 'connected-react-router';
import { PAGE_SIZE } from '../constants/pagination';

export function setSearchResults(results) {
  return {
    type: ActionTypes.SET_SEARCH_RESULTS,
    payload: results,
  };
}

export function incrementSearchPage() {
  return {
    type: ActionTypes.INCREMENT_SEARCH_PAGE,
  };
}

export function decrementSearchPage() {
  return {
    type: ActionTypes.DECREMENT_SEARCH_PAGE,
  };
}

export function resetSearchPage(page = 0) {
  return {
    type: ActionTypes.RESET_SEARCH_PAGE,
    payload: page,
  };
}

export function searchShaders(append) {
  return (dispatch, getState) => {
    const searchParams = getSearchParams(getState());
    dispatch(push(`/shaders?${(new URLSearchParams(searchParams)).toString()}`));
    const params = { ...searchParams, from: searchParams.page * PAGE_SIZE };
    axios.get(`${process.env.REACT_APP_API_URL}/search`, { params })
      .then(({ data }) => {
        dispatch(
          setSearchResults({
            shaders: append ? getState().search.shaders.concat(data.shaders) : data.shaders,
            numberOfResults: data.numberOfResults,
            params: searchParams,
          }),
        );
      });
  };
}
