import React from 'react';
import PropTypes from 'prop-types';
import EditImageButton from './EditImageButton';
import defaultProfile from '../../assets/profile.png';

const ImageEdit = ({ edit, src }) => (
  <div className="image-edit__image-container">
    <img
      className="image-edit__image"
      src={src || defaultProfile}
      alt="User Profile"
    />
    { edit && (
      <div
        className="image-edit__edit-container"
      >
        <EditImageButton
          tooltipClass="image-edit__tooltip"
          buttonClass="image-edit__button"
          src={src}
        />
      </div>
    )}
  </div>);

ImageEdit.propTypes = ({
  edit: PropTypes.bool.isRequired,
  src: PropTypes.string,
});

ImageEdit.defaultProps = {
  src: '',
};

export default ImageEdit;
