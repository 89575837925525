import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { persistStore } from 'redux-persist';
import rootReducer from '../reducers';

const configureStore = (history) => {
  let middleware;
  if (process.env.NODE_ENV === 'production') {
    middleware = applyMiddleware(
      routerMiddleware(history),
      thunk,
    );
  } else {
    middleware = applyMiddleware(
      routerMiddleware(history),
      thunk,
      /* createLogger({
        collapsed: true,
      }), */
    );
  }

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = (createStore(
    rootReducer(history),
    { },
    composeEnhancers(middleware),
  ));
  const persistor = persistStore(store);
  return { store, persistor };
};

export default configureStore;
