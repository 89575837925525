import axios from 'axios';

import * as ActionTypes from '../constants/ActionTypes';

export function setPopularShaders(shaders) {
  return {
    type: ActionTypes.SET_POPULAR_SHADERS,
    payload: shaders,
  };
}

export function getPopularShaders() {
  return (dispatch) => {
    axios.get(`${process.env.REACT_APP_API_URL}/shaders`, { params: { sort: 'popular' } })
      .then((response) => {
        dispatch(setPopularShaders(response.data));
      });
  };
}
