import React from 'react';
import PropTypes from 'prop-types';
import InlineSVG from 'react-inlinesvg';
import Clipboard from 'clipboard';
import classNames from 'classnames';
import CustomPropTypes from '../../CustomPropTypes';
import { fixShaderImage } from '../../utils/images';
import cloudinary from '../../utils/cloudinaryConfig';
import thinX from '../../assets/thin-x.svg';
import { TAB_OPEN } from '../ShaderEditorTab/ShaderEditorTab';
import { EMBED_CONTROLS_ATTRIBUTE, EMBED_CONTROLS_HIDDEN } from '../../pages/shader/EmbedShaderView';
import { CONTROLS_TAB_CLASS_ATTRIBUTE } from '../Controls/ControlsTab';
import { CODE_TAB_CLASS_ATTRIBUTE } from '../CodePane/CodeTab';

class Share extends React.Component {
  constructor(props) {
    super(props);
    if (props.shader.initialized) {
      this.state = {
        shareText: `Check out ${props.shader.title} by ${props.shader.user.name || `@${props.shader.user.username}`}.`,
        shaderUrlEditorOpen: false,
        shaderUrlInputsOpen: false,
        shaderUrlEmbedOption: 'closed',
      };
    } else {
      this.state = {};
    }
  }

  componentDidMount() {
    this.linkClipboard = new Clipboard(this.link, {
      target: () => this.link,
    });

    this.embedClipboard = new Clipboard(this.embed, {
      target: () => this.embed,
    });

    this.linkClipboard.on('success', () => {
      this.link.classList.add('tooltipped');
      this.link.classList.add('tooltipped-s');
    });

    this.embedClipboard.on('success', () => {
      this.embed.classList.add('tooltipped');
      this.embed.classList.add('tooltipped-s');
    });
  }

  componentDidUpdate(prevProps) {
    if (!this.state.shareText && this.props.shader.initialized) {
      this.setState({
        shareText: `Check out ${this.props.shader.title} by @${this.props.shader.username}.`,
      });
    }
    if (this.props.shader.title !== prevProps.shader.title
      || this.props.shader.username !== prevProps.shader.username) {
      this.setState({
        shareText: `Check out ${this.props.shader.title} by @${this.props.shader.username}.`,
      });
    }
  }

  componentWillUnmount() {
    this.linkClipboard.destroy();
    this.embedClipboard.destroy();
  }

  onMouseLeaveHandler = () => {
    this.link.classList.remove('tooltipped');
    this.link.classList.remove('tooltipped-n');
    this.embed.classList.remove('tooltipped');
    this.embed.classList.remove('tooltipped-n');
  }

  onChangeHandler = (e) => {
    this.setState({
      shareText: e.target.value,
    });
  }

  onEditorCheckboxHandler = (e) => {
    this.setState({
      shaderUrlEditorOpen: e.target.checked,
    });
  }

  onInputsCheckboxHandler = (e) => {
    this.setState({
      shaderUrlInputsOpen: e.target.checked,
    });
  }

  onEmbedOptionRadioHandler = (e) => {
    this.setState({
      shaderUrlEmbedOption: e.target.value,
    });
  }

  constructShareUrl = (shaderUrlEditorOpen, shaderUrlInputsOpen) => {
    const shareUrl = new URL(window.location.href);
    const params = new URLSearchParams(shareUrl.search);
    if (shaderUrlEditorOpen) {
      params.set(CODE_TAB_CLASS_ATTRIBUTE, TAB_OPEN);
    } else {
      params.delete(CODE_TAB_CLASS_ATTRIBUTE);
    }
    if (shaderUrlInputsOpen) {
      params.set(CONTROLS_TAB_CLASS_ATTRIBUTE, TAB_OPEN);
    } else {
      params.delete(CONTROLS_TAB_CLASS_ATTRIBUTE);
    }
    shareUrl.search = params.toString();
    return shareUrl;
  }

  constructEmbedURL = (shaderUrlEmbedOption) => {
    const embedUrl = new URL(`${window.location.href}/embed`);
    const params = new URLSearchParams(embedUrl.search);

    if (shaderUrlEmbedOption === 'hidden') {
      params.set(EMBED_CONTROLS_ATTRIBUTE, EMBED_CONTROLS_HIDDEN);
    } else {
      params.delete(EMBED_CONTROLS_ATTRIBUTE);
    }

    if (shaderUrlEmbedOption === 'open') {
      params.set(CONTROLS_TAB_CLASS_ATTRIBUTE, TAB_OPEN);
    } else {
      params.delete(CONTROLS_TAB_CLASS_ATTRIBUTE);
    }

    embedUrl.search = params.toString();
    const embedText = `<iframe src="${embedUrl}" border=0 width=600 height=600></iframe>`;
    return embedText;
  }

  render() {
    const { shader, retract, shareType } = this.props;
    const {
      shareText,
      shaderUrlEditorOpen,
      shaderUrlInputsOpen,
      shaderUrlEmbedOption,
    } = this.state;
    const tooltip = shareType === 'tooltip';
    const shareClass = classNames({
      share: true,
      'share--tooltip': tooltip,
    });
    return (
      <section className={shareClass}>

        <div className="share__header">
          {!tooltip
            && (
              <button
                className="share__close"
                onClick={retract}
                type="button"
              >
                <InlineSVG src={thinX} />
              </button>
            )
          }
          <h2 className="share__header-title">
            Share
          </h2>
        </div>
        <div className="share__info">
          <img
            className="share__image"
            src={cloudinary.url(shader.thumbnailCloudinaryId, { width: 360, height: 270, crop: 'fill' })}
            alt="Preview for Share"
            onError={fixShaderImage}
          />
          <textarea
            className="share__text"
            onChange={this.onChangeHandler}
            value={shareText}
          />
        </div>

        <div className="share__section share__section-post">
          <h3>Post</h3>
          <div>
            <div className="share__socials--share-button-container">
              <a
                className="share__socials-button"
                target="_blank"
                rel="noopener noreferrer"
                href={encodeURI(`https://www.facebook.com/sharer/sharer.php?sdk=joey&u=${window.location.href}&display=popup&ref=plugin&src=share_button" onclick="return !window.open(this.href, 'Facebook', 'width=640,height=580')`)}
              >
                <i className="fa fa-facebook fa-fw" /><span>Facebook</span>
              </a>
              <a
                className="share__socials-button"
                target="_blank"
                rel="noopener noreferrer"
                href={encodeURI(`https://twitter.com/intent/tweet?url=${window.location.href}&text=${shareText}`)}
              >
                <i className="fa fa-twitter fa-fw" /><span>Twitter</span>
              </a>
              <a
                className="share__socials-button"
                target="_blank"
                rel="noopener noreferrer"
                href={encodeURI(`http://tumblr.com/widgets/share/tool?canonicalUrl=${window.location.href}&posttype=link&title=${shareText}`)}
              >
                <i className="fa fa-tumblr fa-fw" /><span>Tumblr</span>
              </a>
            </div>
          </div>
        </div>

        <div className="share__section share__section-link">
          <h3>Link</h3>
          <div>
            <div className="share__socials--text-input-container">
              <input type="text" readOnly value={this.constructShareUrl(shaderUrlEditorOpen, shaderUrlInputsOpen)} />
              <button
                className="share__socials-copy-button tooltipped-no-delay"
                data-clipboard-text={this.constructShareUrl(shaderUrlEditorOpen, shaderUrlInputsOpen)}
                ref={(element) => { this.link = element; }}
                aria-label="Copied!"
                onMouseLeave={this.onMouseLeaveHandler}
                type="button"
              >
                <i className="fa fa-copy" />
              </button>
            </div>
            <div className="share__socials--checkbox-container">
              <input type="checkbox" onClick={this.onEditorCheckboxHandler} id="EditorCheckbox" name="EditorCheckbox" />
              <label htmlFor="EditorCheckbox">
                Editor open
              </label>
              <input type="checkbox" onClick={this.onInputsCheckboxHandler} id="InputsCheckbox" name="InputsCheckbox" />
              <label htmlFor="InputsCheckbox">
                Inputs open
              </label>
            </div>
          </div>
        </div>

        <div className="share__section share__section-embed">
          <h3>Embed</h3>
          <div>
            <div className="share__socials--text-input-container">
              <input type="text" readOnly value={this.constructEmbedURL(shaderUrlEmbedOption)} />
              <button
                className="share__socials-copy-button tooltipped-no-delay"
                data-clipboard-text={this.constructEmbedURL(shaderUrlEmbedOption)}
                ref={(element) => { this.embed = element; }}
                aria-label="Copied!"
                onMouseLeave={this.onMouseLeaveHandler}
                type="button"
              >
                <i className="fa fa-copy" />
              </button>
            </div>
            <div className="share__socials--segmented-control-container">
              <h4>Inputs Tab</h4>
              <input type="radio" onClick={this.onEmbedOptionRadioHandler} defaultChecked={this.state.shaderUrlEmbedOption === 'open'} name="onEmbedOptionRadio" id="EmbedOpenRadio" value="open" />
              <label htmlFor="EmbedOpenRadio">Open</label>
              <input type="radio" onClick={this.onEmbedOptionRadioHandler} defaultChecked={this.state.shaderUrlEmbedOption === 'closed'} name="onEmbedOptionRadio" id="EmbedClosedRadio" value="closed" />
              <label htmlFor="EmbedClosedRadio">Closed</label>
              <input type="radio" onClick={this.onEmbedOptionRadioHandler} defaultChecked={this.state.shaderUrlEmbedOption === 'hidden'} name="onEmbedOptionRadio" id="EmbedHiddenRadio" value="hidden" />
              <label htmlFor="EmbedHiddenRadio">Hidden</label>
            </div>
          </div>
        </div>

      </section>
    );
  }
}

Share.defaultProps = {
  retract: () => ({}),
};

Share.propTypes = {
  shader: CustomPropTypes.shader.isRequired,
  shareType: PropTypes.string.isRequired,
  retract: PropTypes.func.isRequired,
};

export default Share;
