import React, { Fragment, PureComponent } from 'react';
import { logoutUser } from '../../actions/user';
import { connect } from 'react-redux';
import CustomPropTypes from '../../CustomPropTypes';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class UserLinks extends PureComponent {
  static propTypes = {
    user: CustomPropTypes.user.isRequired,
    doLogout: PropTypes.func.isRequired,
  };

  render() {
    const { user, doLogout } = this.props;
    if (user.authenticated) {
      return (
        <Fragment>
          <li>
            <Link className="main-nav__settings" to={`/u/${user.username}`}>
              Account
            </Link>
          </li>
          <li>
            <Link
              className="main-nav__logout"
              to=""
              onClick={doLogout}
            >
              Log Out
            </Link>
          </li>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <li>
          <Link className="main-nav__login" to="/login">Login</Link>
        </li>
        <li>
          <Link className="main-nav__signup" to="/signup">Sign Up</Link>
        </li>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  doLogout: () => dispatch(logoutUser()),
});

export default (connect(mapStateToProps, mapDispatchToProps)(UserLinks));
