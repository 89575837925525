import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InlineSVG from 'react-inlinesvg';
import CustomPropTypes from '../../CustomPropTypes';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import thinX from '../../assets/thin-x.svg';
import {
  finishWalkthrough,
} from '../../actions/user';
import { isLastPage, currentPage } from '../../selectors/walkthrough';
/* eslint-disable */
import * as Walkthrough from '../../constants/walkthrough';
/* eslint-enable */
import {
  setWalkthroughMobile,
  setWalkthroughDesktop,
  enableWalkthrough,
  incrementPage,
  decrementPage,
} from '../../actions/walkthrough';

import walkthroughContent from './OverlayPages';

const POSITION_CENTER = 'POSITION_CENTER';
const POSITION_TOP = 'POSITION_TOP';
const POSITION_LEFT = 'POSITION_LEFT';
const POSITION_RIGHT = 'POSITION_RIGHT';
const POSITION_BOTTOM = 'POSITION_BOTTOM';

const walkthroughPositions = {
  [Walkthrough.DESKTOP_INTRO_PAGE]: POSITION_CENTER,
  [Walkthrough.DESKTOP_CODE_TAB_PAGE]: POSITION_RIGHT,
  [Walkthrough.DESKTOP_CONTROLS_PAGE]: POSITION_LEFT,
  [Walkthrough.DESKTOP_CONSOLE_PAGE]: POSITION_CENTER,
  [Walkthrough.MOBILE_INTRO_PAGE]: POSITION_CENTER,
  [Walkthrough.MOBILE_CODE_TAB_PAGE]: POSITION_TOP,
  [Walkthrough.MOBILE_CONTROLS_PAGE]: POSITION_TOP,
  [Walkthrough.MOBILE_CONSOLE_PAGE]: POSITION_TOP,
};

class WalkthroughOverlay extends React.Component {
  componentDidMount() {
    const {
      mobile,
      setWalkthroughMobile,
      setWalkthroughDesktop,
    } = this.props;
    if (mobile) {
      setWalkthroughMobile();
    } else {
      setWalkthroughDesktop();
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.expanded && !this.props.expanded) {
      this.props.enableWalkthrough();
    }
  }

  endWalkthrough = () => {
    this.props.setExpandedState(false);
    this.props.finishWalkthrough();
  }

  render() {
    const {
      walkthrough,
      mobile,
      currentPage,
      isLastPage,
      incrementPage,
      decrementPage,
    } = this.props;
    if (!walkthrough.enabled) {
      return null;
    }

    const position = walkthroughPositions[currentPage];

    const overlayContainerClass = classNames({
      walkthrough__container: true,
      'walkthrough__container--mobile': mobile,
      'walkthrough__container--desktop': !mobile,
      'walkthrough__container--left': position === POSITION_LEFT,
      'walkthrough__container--right': position === POSITION_RIGHT,
      'walkthrough__container--center': position === POSITION_CENTER || !position,
      'walkthrough__container--top': position === POSITION_TOP,
      'walkthrough__container--bottom': position === POSITION_BOTTOM,
    });

    return (
      <div className={overlayContainerClass}>
        <div className="walkthrough">
          {
            (walkthrough.pageIndex > 0 ? (
              <button
                className="walkthrough__decrement-button"
                onClick={decrementPage}
              >
                <i className="fa fa-arrow-left" />
              </button>) : <div />)
          }
          <div
            className="walkthrough__content"
          >
            {walkthroughContent[currentPage]}
          </div>
          <button
            className={isLastPage ? 'walkthrough__complete-button' : 'walkthrough__increment-button'}
            onClick={
              isLastPage
                ? this.endWalkthrough
                : incrementPage
            }
          >
            <i className={isLastPage ? 'fa fa-check' : 'fa fa-arrow-right'} />
          </button>
          <button
            className="walkthrough__exit-button"
            onClick={this.endWalkthrough}
          >

            <InlineSVG src={thinX} />
          </button>
        </div>
      </div>
    );
  }
}

WalkthroughOverlay.defaultProps = {
  mobile: false,
  setExpandedState: () => ({}),
};

WalkthroughOverlay.propTypes = {
  expanded: PropTypes.bool.isRequired,
  mobile: PropTypes.bool.isRequired,
  walkthrough: CustomPropTypes.walkthrough.isRequired,
  incrementPage: PropTypes.func.isRequired,
  decrementPage: PropTypes.func.isRequired,
  finishWalkthrough: PropTypes.func.isRequired,
  setExpandedState: PropTypes.func,
  enableWalkthrough: PropTypes.func.isRequired,
  isLastPage: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  walkthrough: state.walkthrough,
  currentPage: currentPage(state),
  isLastPage: isLastPage(state),
});

const mapDispatchToProps = (
  dispatch => bindActionCreators({
    incrementPage,
    decrementPage,
    finishWalkthrough,
    setWalkthroughMobile,
    setWalkthroughDesktop,
    enableWalkthrough,
  }, dispatch)
);


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WalkthroughOverlay);

