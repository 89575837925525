import axios from 'axios';
import { push } from 'connected-react-router';
import { SubmissionError, reset } from 'redux-form';
import {
  error as errorNotification,
  success as successNotification,
} from 'react-notification-system-redux';
import { getProfile } from './profile';
import { createShader, forkShader } from './shader';
import { disableWalkthrough } from './walkthrough';
import * as ActionTypes from '../constants/ActionTypes';
import { validateUserObject } from '../utils/form';
import { handler } from '../utils/axios';

export function authenticationError(error) {
  return {
    type: ActionTypes.AUTHENTICATION_ERROR,
    payload: error,
  };
}

export function authenticateUser(user) {
  return {
    type: ActionTypes.AUTHENTICATE_USER,
    payload: user,
  };
}

export function unauthenticateUser() {
  return {
    type: ActionTypes.UNAUTHENTICATE_USER,
  };
}

export function setUserAuthPending(isUserAuthPending) {
  return {
    type: ActionTypes.SET_USER_AUTH_PENDING,
    payload: isUserAuthPending,
  };
}

const handlePendingActions = (dispatch, getState) => {
  const { shader } = getState();
  const { pendingAction } = shader;
  if (pendingAction === ActionTypes.PENDING_CREATE) {
    const { rawFragmentSource, rawVertexSource, title } = shader;
    dispatch(createShader(rawFragmentSource, rawVertexSource, title));
  }
  if (pendingAction === ActionTypes.PENDING_FORK) {
    dispatch(forkShader());
  }
};

export function signUpUser(formValues) {
  return (dispatch, getState) => {
    axios.post(`${process.env.REACT_APP_API_URL}/signup`, formValues)
      .then((response) => {
        dispatch(authenticateUser(response.data));
        handlePendingActions(dispatch, getState);
        dispatch(successNotification({
          title: 'User account created!',
          timeout: 5,
        }));
        dispatch(push('/profile'));
      })
      .catch((response) => {
        dispatch(errorNotification({
          title: 'Failed to create user.',
          timeout: 5,
        }));
        dispatch(authenticationError(response.data && response.data.error));
      });
  };
}

export function loginUser(formValues) {
  return axios.post(`${process.env.REACT_APP_API_URL}/login`, formValues);
}

// don't think i need this? can just use authenticateUser
export function loginUserSuccess(user) {
  return {
    type: ActionTypes.AUTHENTICATE_USER,
    user,
  };
}

// also don't need this??? because we have authenticationError
export function loginUserFailure(error) {
  return {
    type: ActionTypes.AUTHENTICATION_ERROR,
    error,
  };
}

export const validateAndLoginUser = formProps => (
  (dispatch, getState) => (new Promise((resolve, reject) => loginUser(formProps)
    .then((response) => {
      dispatch(authenticateUser(response.data));
      handlePendingActions(dispatch, getState);
      dispatch(push('/profile'));
      resolve();
    })
    .catch((response) => {
      reject(new SubmissionError({ email: (response.data && response.data.message) || 'Email or Password incorrect', _error: 'Login failed!' }));
    })))
);

export function getUser() {
  return (dispatch) => {
    dispatch(setUserAuthPending(true));
    axios.get(`${process.env.REACT_APP_API_URL}/session`)
      .then((response) => {
        dispatch(authenticateUser(response.data));
      })
      .catch((error) => {
        dispatch(authenticationError(error.message));
      });
  };
}

export function validateSession() {
  return (dispatch, getState) => {
    axios.get(`${process.env.REACT_APP_API_URL}/session`)
      .then((response) => {
        const state = getState();
        if (state.user.username !== response.data.username) {
          // dispatch(showErrorModal('staleSession'));
          // do something to indicate user is stale
        }
      })
      .catch((response) => {
        if (response.status === 404) {
          // dispatch(showErrorModal('staleSession'));
          // do something to indicate user is stale
        }
      });
  };
}

export function logoutUser() {
  return (dispatch) => {
    axios.get(`${process.env.REACT_APP_API_URL}/logout`)
      .then(() => {
        dispatch(unauthenticateUser());
      })
      .catch(response => dispatch(authenticationError(response.data && response.data.error)));
  };
}

export function updateSettings(formValues) {
  return (dispatch, getState) => {
    const state = getState();
    const userId = state.user.id;
    let formData;
    if (formValues.image && typeof formValues.image !== 'string') {
      formData = new FormData();
      formData.append('image', formValues.image);
      const formValueKeys = Object.keys(formValues);
      formValueKeys.forEach((key) => {
        if (key !== 'image') {
          formData.append(key, formValues[key]);
        }
      });
      // iterate through the rest of the keys and append to the form data.
    } else {
      formData = formValues;
    }

    const errors = Object.entries(validateUserObject(formData, false));
    if (errors.length > 0) { return dispatch(errorNotification({ title: errors[0][1] })); }
    return axios.put(`${process.env.REACT_APP_API_URL}/users/${userId}`, formData)
      .then((response) => {
        if (!response.data || !response.data.user || !response.data.user.username) {
          return Promise.reject('Error in updating user settings.');
        }
        dispatch(getProfile(response.data.user.username));
        dispatch(authenticateUser(response.data.user));
        dispatch(successNotification({
          title: 'User settings updated.',
          timeout: 5,
        }));

        return Promise.resolve();
      }).catch(handler(dispatch));
  };
}

export function updatePassword(formValues) {
  return (dispatch, getState) => (new Promise((resolve, reject) => {
    const state = getState();
    const userId = state.user.id;
    axios.put(`${process.env.REACT_APP_API_URL}/users/${userId}/password`, formValues)
      .then(() => {
        dispatch(reset('change-password'));
        return dispatch(successNotification({
          title: 'Password updated.',
          timeout: 5,
        }));
      }).catch(({ response }) => reject(
        new SubmissionError({
          password: (response.data && response.data.message) || 'Invalid new password',
        }),
      ));
  }));
}

export function requestAccepted(responseData) {
  return {
    type: ActionTypes.RESET_PASSWORD_INITIATE,
    payload: responseData,
  };
}

const passwordResetError = response => (new SubmissionError({
  email: (response.data && response.data.error) ? response.data.error : 'Password reset request failed.',
  _error: 'Reset request failed',
}));

export function initiatePasswordReset(formValues, dispatch) {
  return (axios.put(`${process.env.REACT_APP_API_URL}/initiatePasswordReset`, formValues)
    .then((response) => {
      if (response.data.error) {
        return Promise.reject(passwordResetError(response));
      }
      return dispatch(requestAccepted(response.data));
    })
    .catch(({ response }) => Promise.reject(passwordResetError(response)))
  );
}


export function resetComplete(responseData) {
  return {
    type: ActionTypes.RESET_PASSWORD_COMPLETE,
    payload: responseData,
  };
}

export function completePasswordReset(formValues, dispatch) {
  return (axios.put(`${process.env.REACT_APP_API_URL}/completePasswordReset`, formValues)
    .then((response) => {
      dispatch(resetComplete(response.data));
    })
    .catch(response => Promise.reject(
      new SubmissionError({
        newPassword: (
          (response.data && response.data.error)
            || 'Password reset failed.'
        ),
        _error: 'Reset failed',
      }),
    ))
  );
}

export function deleteAccount() {
  return (dispatch, getState) => {
    const state = getState();
    const userId = state.user.id;
    axios.delete(`${process.env.REACT_APP_API_URL}/users/${userId}`)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: ActionTypes.DELETE_USER_SUCCESS,
          });
          dispatch(successNotification({
            title: 'Account successfully deleted.',
            timeout: 5,
          }));
          dispatch(push('/'));
        }
      });
  };
}

export function unlinkSocialAccount(type) {
  return (dispatch) => {
    axios.get(`${process.env.REACT_APP_API_URL}/unlink/${type}`)
      .then((response) => {
        dispatch(authenticateUser(response.data));
      });
  };
}

export const finishWalkthrough = () => (
  (dispatch, getState) => {
    const { user } = getState();
    if (!user.authenticated || !user.id) {
      dispatch(disableWalkthrough());
      return;
    }

    axios.post(`${process.env.REACT_APP_API_URL}/users/${user.id}/walkthrough`)
      .then((response) => {
        if (response.data && response.data.success) {
          dispatch(disableWalkthrough());
        }
      });
  }
);

export const setAdminMode = isEnabled => ({
  type: ActionTypes.SET_ADMIN_MODE,
  payload: !!isEnabled,
});
