import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

const ShadersFiltersSelect = ({ name, options, onChange, includeAllOption }) => (
  <div className="shaders-filters__item">
    <Field
      className="shaders-filters__item-select"
      component="select"
      name={name}
    >
      {includeAllOption && <option value="">All {name}</option>}
      {options.map(option => (
        <option key={option.name} value={option.name}>
          {option.name}
        </option>
      ))}
    </Field>
  </div>
);

ShadersFiltersSelect.propTypes = ({
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  includeAllOption: PropTypes.bool,
});

const WrappedShadersFiltersSelect = reduxForm({
  form: 'search',
  destroyOnUnmount: false,
})(ShadersFiltersSelect);

export default WrappedShadersFiltersSelect;
