import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { updateSettings } from '../../actions/user';
import EditImageUpload from './EditImageUpload';

class EditImageModal extends React.Component {
  componentWillMount() {
    this.initializeForm(this.props);
  }

  componentWillUpdate(nextProps) {
    if (!this.props.value && nextProps.value) {
      this.props.destroy();
      this.initializeForm(nextProps);
    }
    if (!this.props.submitSucceeded && nextProps.submitSucceeded) {
      this.props.setExpanded(false);
    }
  }

  initializeForm = (props) => {
    const { src } = props;
    this.props.initialize({ image: src });
  }

  handleDelete = (e) => {
    e.preventDefault();
    this.props.initialize({ image: '' });
    this.props.updateSettings({ image: '', imageCloudinaryId: '' });
    this.props.setExpanded(false);
  }

  handleCancel = (e) => {
    e.preventDefault();
    this.props.setExpanded(false);
  }

  render() {
    const { src, dirty, handleSubmit } = this.props;
    return (
      <form
        className="image-edit__tooltip-main"
        onSubmit={handleSubmit(this.props.updateSettings)}
      >
        <h2 className="image-edit__tooltip-header">
          Upload a profile photo
        </h2>
        <Field name="image" component={EditImageUpload} />
        <div className="image-edit__tooltip-buttons">
          { dirty
            && (
              <input
                className="image-edit__tooltip-button image-edit__tooltip-button--save"
                type="submit"
                value="Save"
              />
            )
          }
          { src
            && (
              <button
                className="image-edit__tooltip-button image-edit__tooltip-button--delete"
                onClick={this.handleDelete}
                type="button"
              >
                Delete
              </button>
            )
          }
          <button
            className="image-edit__tooltip-button image-edit__tooltip-button--cancel"
            onClick={this.handleCancel}
            type="button"
          >
            Cancel
          </button>
        </div>
      </form>);
  }
}

EditImageModal.propTypes = ({
  value: PropTypes.string,
  src: PropTypes.string,
  dirty: PropTypes.bool.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  destroy: PropTypes.func.isRequired,
  updateSettings: PropTypes.func.isRequired,
  setExpanded: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
});

EditImageModal.defaultProps = {
  value: '',
  src: '',
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => bindActionCreators({
  updateSettings,
}, dispatch);

const formComponent = reduxForm(
  { form: 'profile-image' },
)(EditImageModal);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(formComponent);
