import React from 'react';
import PropTypes from 'prop-types';

const LoginButton = ({
  value,
  submitting,
  pristine,
}) => (
  <div className="form-field form-field__buttom-container">
    <input
      className="form-field__button"
      type="submit"
      disabled={submitting || pristine}
      value={value}
    />
  </div>
);

LoginButton.propTypes = ({
  value: PropTypes.string.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
});

export default LoginButton;
