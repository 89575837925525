import * as ActionTypes from '../constants/ActionTypes';

const user = (state = { authenticated: false }, action) => {
  switch (action.type) {
    case ActionTypes.AUTHENTICATE_USER:
      return { ...action.payload, authenticated: true, isUserAuthPending: false };
    case ActionTypes.UNAUTHENTICATE_USER:
      return { authenticated: false };
    case ActionTypes.AUTHENTICATION_ERROR:
      return { authenticated: false, isUserAuthPending: false };
    case ActionTypes.STAR_SHADER: {
      const stars = [...state.stars];
      stars.push(action.payload.shaderId);
      return Object.assign({}, state, { stars });
    }
    case ActionTypes.UNSTAR_SHADER: {
      const stars = state.stars.filter(shaderId => shaderId !== action.payload.shaderId);
      return Object.assign({}, state, { stars });
    }
    case ActionTypes.DELETE_USER_SUCCESS: {
      return { authenticated: false };
    }
    case ActionTypes.DISABLE_WALKTHROUGH: {
      return { ...state, walkthroughFinished: true };
    }
    case ActionTypes.SET_ADMIN_MODE: {
      const { admin } = state;
      return { ...state, adminMode: !!action.payload && admin };
    }
    case ActionTypes.SET_USER_AUTH_PENDING: {
      return { ...state, isUserAuthPending: action.payload };
    }
    default:
      return state;
  }
};

export default user;
