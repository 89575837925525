// Sort options strings defined in CommonJS module format
// so they can be used easily in the backend
module.exports.RELEVANCE = 'Relevance';
module.exports.TITLE_ASC = 'Title ↑';
module.exports.TITLE_DESC = 'Title ↓';
module.exports.DATE_CREATED_ASC = 'Date Created ↑';
module.exports.DATE_CREATED_DESC = 'Date Created ↓';
module.exports.POPULARITY_ASC = 'Popularity ↑';
module.exports.POPULARITY_DESC = 'Popularity ↓';
module.exports.USERNAME_ASC = 'Username ↑';
module.exports.USERNAME_DESC = 'Username ↓';
