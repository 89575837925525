import React from 'react';
import PropTypes from 'prop-types';
import Resizable from 're-resizable';
import classNames from 'classnames';
import ShaderMessage from './ShaderMessage';
import withTab from '../ShaderEditorTab/ShaderEditorTab';

class ShaderMessageTab extends React.Component {
  componentWillReceiveProps(newProps) {
    // pop open when we get new content
    if (
      (!this.props.error && newProps.error) ||
      (!this.props.warning && newProps.warning) ||
      (!this.props.message && newProps.message)
    ) {
      this.props.setExpandedState(true);
    }
  }

  render() {
    const containerClass = classNames({
      'shader-message__container': true,
    });

    return ((
      <div className={containerClass}>
        <Resizable
          className="shader-message__resizable"
          defaultSize={{
            width: 500,
            height: '100%',
          }}
          minWidth={350}
          enable={{
            top: true,
            right: false,
            bottom: false,
            left: true,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false,
          }}
        >
          <div className="shader-message__header">
            <h2>Console</h2>
            {this.props.children}
          </div>
          <ShaderMessage
            error={this.props.error}
            warning={this.props.warning}
            message={this.props.message}
          />
        </Resizable>
      </div>
    ));
  }
}

ShaderMessageTab.defaultProps = ({
  error: {},
  warning: '',
  message: '',
});

ShaderMessageTab.propTypes = ({
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  setExpandedState: PropTypes.func.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
  warning: PropTypes.string,
  message: PropTypes.string,
});

export default withTab(ShaderMessageTab, 'message', 'fa fa-terminal', true, 'Debug console (^+i or ⌘+i)');
