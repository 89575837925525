import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import { addImage } from '../../actions/shader';
import { updateControl, updateControls } from '../../actions/inputs';
import Shader from '../Shader/Shader';
import ControlsTab from '../Controls/ControlsTab';
import CustomPropTypes from '../../CustomPropTypes';

const InlineShader = (props) => {
  const { shader } = props;
  return (
    <section className="inline-shader">
      <div className="inline-shader__shader">
        <Shader shader={shader} />
        { shader.inputs && shader.inputs.length > 0
            && (
              <div className="controls">
                <ControlsTab
                  initExpanded
                />
              </div>
            )
        }
      </div>
      <div className="inline-shader__info">
        <h2>
          <Link to={`/shaders/${shader.id}`}>
            {shader.title}
          </Link>
        </h2>
        <p className="inline-shader__owner">
          by&nbsp;
          <Link to={`/u/${shader.username}`}>
            {shader.username}
          </Link>
        </p>
      </div>
    </section>
  );
};

const mapStateToProps = ({ shader, inputs }) => ({
  shader,
  inputs,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  addImage,
  updateControl,
  updateControls,
}, dispatch);

InlineShader.defaultProps = ({
});

InlineShader.propTypes = ({
  shader: CustomPropTypes.shader.isRequired,
  inputs: CustomPropTypes.inputs.isRequired,
  updateControl: PropTypes.func.isRequired,
  updateControls: PropTypes.func.isRequired,
  addImage: PropTypes.func.isRequired,
});

export default connect(mapStateToProps, mapDispatchToProps)(InlineShader);
