import axios from 'axios';
import JSZip from 'jszip';
import { handler } from '../utils/axios';
import {
  success as successNotification,
  error as errorNotification,
} from 'react-notification-system-redux';
import saveAs from 'file-saver';
import * as ActionTypes from '../constants/ActionTypes';
import { parseShader } from '../utils/isf.js';
import { zipShader } from '../utils/zipShader.js';

export function setProfile(profile) {
  return {
    type: ActionTypes.SET_PROFILE,
    payload: profile,
  };
}

export function setProfileLoading(isLoading) {
  return {
    type: ActionTypes.SET_PROFILE_LOADING,
    payload: isLoading,
  };
}

export function setProfileLoadingFailed(usernameThatFailedToLoad) {
  return {
    type: ActionTypes.SET_PROFILE_LOADING_FAILED,
    payload: usernameThatFailedToLoad,
  };
}

export function getProfile(username) {
  return (dispatch, getState) => {
    const { profile: { isProfileLoading } } = getState();
    if (isProfileLoading) return;
    dispatch(setProfileLoading(true));
    axios.get(`${process.env.REACT_APP_API_URL}/${username}/profile`)
      .then((response) => {
        dispatch(setProfile(response.data));
      })
      .catch((error) => {
        const { response: { status } } = error;
        dispatch(setProfileLoading(false));
        dispatch(setProfileLoadingFailed(username));
        if (status && status !== 404) handler(dispatch)(error);
      });
  };
}

export function downloadAllShaders() {
  return async (dispatch, getState) => {
    const { profile: { shaders = [], username } } = getState();
    const zip = new JSZip();
    const lotsOfShaders = shaders.length > 5;
    if (lotsOfShaders) {
      dispatch(successNotification({
        title: `Archiving ${shaders.length} shaders.`,
      }));
    }

    for (const shader of shaders) {
      shader.initialized = true; // shader will not parse otherwise
      await zipShader(parseShader(shader), dispatch, zip);
      if (!lotsOfShaders) {
        dispatch(successNotification({
          title: `'${shader.title}' archived.`,
        }));
      }
    }

    try {
      const blob = await zip.generateAsync({ type: 'blob' });
      saveAs(blob, `${username}-shaders.zip`);
      dispatch(successNotification({
        title: 'User shader archive downloaded.',
      }));
    } catch (err) {
      dispatch(errorNotification({
        title: 'Error downloading shaders.',
      }));
    }
  };
}
