import * as ActionTypes from '../constants/ActionTypes';
import * as DefaultShaders from '../utils/defaultShaders';
import { upgradeShader, parseShader } from '../utils/isf';

const initialState = {
  initialized: false,
  rawFragmentSource: '',
  rawVertexSource: '',
};

const initializeShader = (type, user) => {
  const vertexSource = DefaultShaders.defaultVS;
  let fragmentSource;
  switch (type) {
    case 'filter':
      fragmentSource = DefaultShaders.filterFS;
      break;
    case 'generator':
      fragmentSource = DefaultShaders.generatorFS;
      break;
    case 'transition':
      fragmentSource = DefaultShaders.transitionFS;
      break;
    default:
      fragmentSource = DefaultShaders.defaultFS;
      break;
  }
  if (user.authenticated) {
    fragmentSource = fragmentSource.replace('You', user.username);
  }
  return {
    valid: true,
    rawVertexSource: vertexSource,
    rawFragmentSource: fragmentSource,
    title: 'Untitled Shader',
    description: '',
    initialized: true,
    images: [],
    user: user.id,
    username: user.username,
    private: false,
    parsed: false,
    scale: 1,
  };
};

// export const normalizeShader = (shaderJSON) => {
//   const normalizedShader = Object.assign({}, shaderJSON);
//   if (normalizedShader.user) {
//     normalizedShader.user = normalizedShader.user.username;
//   }
//   return normalizedShader;
// }

const shader = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.TRIGGER_SNAPSHOT:
      return { ...state, snapshotTriggerActive: true };
    case ActionTypes.FINISH_SNAPSHOT:
      return { ...state, snapshotTriggerActive: false };
    case ActionTypes.SET_SHADER:
      return parseShader({ parsed: false, ...(action.payload), initialized: true });
    case ActionTypes.SHADER_ERROR:
      return { ...state, error: action.payload };
    case ActionTypes.SET_FETCH_SHADER_FAILED:
      return { ...state, initialized: false, fetchShaderFailed: action.payload };
    case ActionTypes.SET_FRAGMENT_SOURCE:
      return parseShader({
        ...state,
        dirty: true,
        fragmentEdited: false,
        parsed: false,
        rawFragmentSource: action.payload,
      });
    case ActionTypes.SET_VERTEX_SOURCE:
      return parseShader({
        ...state,
        dirty: true,
        vertexEdited: false,
        parsed: false,
        rawVertexSource: action.payload,
      });
    case ActionTypes.SET_VERTEX_EDITED:
      return { ...state, vertexEdited: true };
    case ActionTypes.SET_FRAGMENT_EDITED:
      return { ...state, fragmentEdited: true };
    case ActionTypes.SET_OWNER:
      // this has to be bad... @TODO
      return {
        ...state,
        user: action.payload.id,
        username: action.payload.username,
        rawFragmentSource: state.rawFragmentSource.replace(
          'You',
          action.payload.username,
        ),
      };
    case ActionTypes.STAR_SHADER: {
      const stars = (state.stars || [])
        .filter(userId => userId !== action.payload.userId);
      stars.push(action.payload.userId);
      return { ...state, stars };
    }
    case ActionTypes.UNSTAR_SHADER: {
      const stars = (state.stars || [])
        .filter(userId => userId !== action.payload.userId);
      return { ...state, stars };
    }
    case ActionTypes.NEW_SHADER:
      return (parseShader(initializeShader(action.payload.type, action.payload.user)));
    case ActionTypes.SET_SHADER_SOURCE:
      return parseShader({
        ...state,
        vertexEdited: false,
        fragmentEdited: false,
        dirty: true,
        parsed: false,
        ...(action.payload),
      });
    case ActionTypes.SET_THUMBNAIL:
      return { ...state, thumbnailCloudinaryId: action.payload };
    case ActionTypes.ADD_IMAGE: {
      const images = state.images
        .filter(image => image.name !== action.payload.name);
      images.push(action.payload);
      return { ...state, images };
    }
    case ActionTypes.SET_SHADER_TITLE:
      return { ...state, dirty: true, title: action.payload };
    case ActionTypes.SET_FEATURED:
      return { ...state, featured: action.payload };
    case ActionTypes.SET_SCALE:
      return { ...state, dirty: true, scale: action.payload };
    case ActionTypes.SET_FRAME_RATE:
      return { ...state, frameRate: action.payload };
    case ActionTypes.SET_SHADER_FORKING:
      return { ...state, forking: action.payload };
    case ActionTypes.SET_PENDING_FORK:
      return { ...state, pendingAction: ActionTypes.PENDING_FORK };
    case ActionTypes.SET_PENDING_CREATE:
      return { ...state, pendingAction: ActionTypes.PENDING_CREATE };
    case ActionTypes.RESET_IMAGE:
      if (!state.images) { return state; }
      const resetIndex = state.images.findIndex(image => image.name === action.payload);
      if (resetIndex < 0) { return state; }
      const images = [
        ...state.images.slice(0, resetIndex),
        ...state.images.slice(resetIndex + 1),
      ];
      return { ...state, images };
    case ActionTypes.UPGRADE_SHADER:
      return parseShader(upgradeShader(state));
    default:
      return state;
  }
};

export default shader;
