import React from 'react';
import PropTypes from 'prop-types';

const deleteShaderCopy = `
Are you sure you want to delete this shader?
This will permanently delete this shader and it cannot be reversed.
`;

class DeleteShaderButton extends React.Component {
  confirmDelete = () => {
    // @TODO replace with modal component
    if (window.confirm(deleteShaderCopy)) {
      if (window.confirm('Are you really sure you want to delete this shader?')) {
        this.props.deleteShader(this.props.shaderId);
        this.props.onDelete();
      }
    }
  }

  render() {
    return (
      <button
        className="shader__delete-button"
        type="button"
        onClick={this.confirmDelete}
      >
        <i className="fa fa-trash" />
      </button>
    );
  }
}

DeleteShaderButton.propTypes = ({
  deleteShader: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  shaderId: PropTypes.string.isRequired,
});

export default DeleteShaderButton;
