import React from 'react';
import PropTypes from 'prop-types';
import ChangePasswordForm from './ChangePasswordForm';

const ChangePassword = ({ updatePassword }) => (
  <section className="settings__change-password">
    <ChangePasswordForm onSubmit={updatePassword} />
  </section>
);

ChangePassword.propTypes = {
  updatePassword: PropTypes.func.isRequired,
};

export default ChangePassword;
