import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import qs from 'qs';

import * as Constants from '../../constants/ActionTypes';
import { signUpUser } from '../../actions/user';
import SignUpForm from '../../components/LoginForms/SignUpForm';
import SocialLoginButtons from '../../components/LoginForms/SocialLoginButtons';
import TopMenu from '../../components/TopMenu/TopMenu';

class SignUp extends React.Component {
  render() {
    const { pendingAction } = qs.parse(this.props.location.search.substring(1));
    let title = '';
    switch (pendingAction) {
      case Constants.PENDING_CREATE:
        title = 'Sign up to save shader';
        break;
      case Constants.PENDING_FORK:
        title = 'Sign up to fork shader';
        break;
      default:
        title = 'Sign up';
    }
    return (
      <section className="login">
        <TopMenu
          menuType="default"
        />
        <div className="login__main">
          <h1 className="login__header">
            { title }
          </h1>
          <Link
            className="login__alternate-link"
            to="/login"
          >
            Or log in to your account
          </Link>
          <SignUpForm
            onSubmit={this.props.signUpUser}
          />
          <div className="login__social-pre">
            Or sign in with
          </div>
          <SocialLoginButtons />
        </div>
      </section>
    );
  }
}

function mapStateToProps() {
  return {
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ signUpUser }, dispatch);
}

SignUp.propTypes = ({
  signUpUser: PropTypes.func.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
