import * as ActionTypes from '../constants/ActionTypes';

const inputs = (
  state = { controlsInitialized: false, values: {} },
  action,
) => {
  switch (action.type) {
    case ActionTypes.NEW_SHADER:
    case ActionTypes.SET_SHADER_SOURCE:
    case ActionTypes.SET_VERTEX_SOURCE:
    case ActionTypes.SET_FRAGMENT_SOURCE:
    case ActionTypes.SET_SHADER:
      return {
        ...state,
        controlsInitialized: false,
      };
    case ActionTypes.UPDATE_CONTROLS:
      return {
        controlsInitialized: true,
        values: { ...state.values, ...action.payload },
      };

    default:
      return state;
  }
};

export default inputs;
