import defaultProfileImage from '../assets/profile.png';
import defaultShaderImage from '../assets/isf-hero.png';

export const fixProfileImage = (e) => {
  e.target.src = defaultProfileImage;
};

export const fixShaderImage = (e) => {
  e.target.src = defaultShaderImage;
};
