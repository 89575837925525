import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getShader } from '../../actions/shader';
import CustomPropTypes from '../../CustomPropTypes';
import Shader from '../../components/Shader/Shader';
import ControlsTab from '../../components/Controls/ControlsTab';

export const EMBED_CONTROLS_ATTRIBUTE = 'embed_controls_state';
export const EMBED_CONTROLS_HIDDEN = '1';

class EmbedShaderView extends React.Component {
  constructor(props) {
    super(props);
    if (props.match.params.shaderId && props.shader.id !== props.match.params.shaderId) {
      this.props.getShader(props.match.params.shaderId);
    }
  }

  componentDidMount() {
    if (!this.props.shader._id && this.props.match.params.shaderId) {
      this.props.getShader(this.props.match.params.shaderId);
    }
  }

  render() {
    const { shader, inputs } = this.props;
    const embedUrl = new URL(window.location.href);
    const params = new URLSearchParams(embedUrl.search);
    const hideControls = params.get(EMBED_CONTROLS_ATTRIBUTE) === EMBED_CONTROLS_HIDDEN;
    return (
      <div className="embed">
        <Shader shader={shader} />
        <div style={{ visibility: hideControls ? 'hidden' : 'visible' }}>
          <ControlsTab shader={shader} inputs={inputs} embed />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ shader, inputs }) => ({
  shader,
  inputs,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getShader }, dispatch);
}

EmbedShaderView.propTypes = ({
  shader: CustomPropTypes.shader.isRequired,
  inputs: CustomPropTypes.inputs.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  getShader: PropTypes.func.isRequired,
});


export default connect(mapStateToProps, mapDispatchToProps)(EmbedShaderView);
