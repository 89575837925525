import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import CustomPropTypes from '../../CustomPropTypes';
import TopMenu from '../../components/TopMenu/TopMenu';
import { push } from 'connected-react-router';
import { getCategories, setCategoryPrivacy } from '../../actions/categories';
import CategoryList from '../../components/CategoryList/CategoryList';

class Categories extends React.Component {
  componentDidUpdate() {
    this.loadCategories();
  }

  componentDidMount() {
    this.loadCategories(true);
  }

  loadCategories = (forceReload = false) => {
    if ((forceReload || !this.categories) && this.props.admin) {
      this.props.getCategories(true);
    }
  }

  render() {
    const { admin, categories = [] } = this.props;
    const publicCategories = categories.filter(c => c.public);
    const privateCategories = categories.filter(c => !c.public);
    return (
      <section className="categories">
        <TopMenu
          menuType="default"
        />
        <div className="categories__container">
          <h1 className="categories__header">Edit Categories</h1>
          <div className="categories">
            { admin ? (
              <Fragment>
                <CategoryList
                  title="Private categories"
                  categories={privateCategories}
                  setCategoryPrivacy={this.props.setCategoryPrivacy}
                />
                <CategoryList
                  title="Public categories"
                  categories={publicCategories}
                  setCategoryPrivacy={this.props.setCategoryPrivacy}
                  isPublic
                />
              </Fragment>
            ) : (
              <span>
                Error: please <Link to="/login">login</Link> if
                you are an administrator or return <Link to="/">home.</Link>
              </span>
            )
            }
          </div>
        </div>
      </section>);
  }
}

function mapStateToProps(state) {
  return {
    admin: state.user.authenticated && state.user.admin,
    categories: state.categories,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getCategories,
    push,
    setCategoryPrivacy,
  }, dispatch);
}

Categories.defaultProps = {
  admin: false,
};

Categories.propTypes = ({
  setCategoryPrivacy: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  admin: PropTypes.bool,
  categories: PropTypes.arrayOf(CustomPropTypes.category).isRequired,
});

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
