import React from 'react';

const SocialLoginButtons = () => (
  <div className="login__social-container">
    <a className="login__social" href="/auth/facebook">
      <img alt="Facebook logo" src="facebook.svg" height="24" />
      <span>
        Login with Facebook
      </span>
    </a>
    <a className="login__social" href="/auth/twitter">
      <img alt="Twitter logo" src="twitter.svg" height="24" />
      <span>
        Login with Twitter
      </span>
    </a>
  </div>
);

export default SocialLoginButtons;
