import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

const ShadersFiltersToggle = ({ name, onChange }) => (
  <div className="shaders-filters__item">
    <Field
      className="shaders-filters__item-toggle"
      component="input"
      type="checkbox"
      name="showPrivate"
    />
    <label htmlFor="isPrivate">Show Private shaders</label>
  </div>
);

ShadersFiltersToggle.propTypes = ({
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
});

const WrappedShadersFiltersToggle = reduxForm({
  form: 'search',
  destroyOnUnmount: false,
})(ShadersFiltersToggle);

export default WrappedShadersFiltersToggle;
