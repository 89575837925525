import React from 'react';
import PropTypes from 'prop-types';

class EditImageUpload extends React.Component {
  onChange = (e) => {
    const { input: { onChange } } = this.props;
    onChange(e.target.files[0]);
  }


  render() {
    const { input: { value } } = this.props;
    let src;
    if (typeof value !== 'string') {
      src = URL.createObjectURL(value);
    } else {
      src = value;
    }

    const inner = (src
      ? (
        <img
          className="image-edit__tooltip-image"
          src={src}
          alt="Uploaded File"
        />
      )
      : (
        <div className="image-edit__tooltip-placeholder">
          <i className="fa fa-camera" />
        </div>
      ));
    return (
      <div className="image-edit__tooltip-filearea">
        <input
          className="image-edit__tooltip-file-input"
          type="file"
          name="image-edit__file"
          id="image-edit__file"
          value=""
          onChange={this.onChange}
        />
        <label htmlFor="image-edit__file">
          {inner}
        </label>
      </div>);
  }
}

EditImageUpload.propTypes = ({
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
});

export default EditImageUpload;
