import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import cloudinary from '../../utils/cloudinaryConfig';
import CustomPropTypes from '../../CustomPropTypes';
import { fixShaderImage } from '../../utils/images';
import {
  starShader,
  unstarShader,
} from '../../actions/shader';
import format from 'date-fns/format';
import defaultShaderImage from '../../assets/isf-hero.png';

const itemDimensions = { width: 360, height: 250 };
const ADDED_TEXT = 'Added to Favorites!';

class ShaderListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      favoriteText: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.shaderStarred !== prevProps.shaderStarred) {
      if (this.props.shaderStarred) {
        this.setState({ favoriteText: ADDED_TEXT });
        setTimeout(() => this.setState({ favoriteText: null }), 3000);
      } else {
        this.setState({ favoriteText: null });
      }
    }
  }

  handleFavoriteClick = (e) => {
    e.preventDefault();
    const {
      shader, shaderStarred,
    } = this.props;
    if (shaderStarred) {
      this.props.unstarShader(shader.id);
    } else {
      this.props.starShader(shader.id);
    }
  }

  render() {
    const { shader, shaderStarred, handleOpenShader } = this.props;
    const { favoriteText } = this.state;
    const iconClass = classNames({
      fa: true,
      'fa-heart': shaderStarred,
      'fa-heart-o': !shaderStarred,
    });

    return (
      <li className="shader-list__item" key={shader._id}>
        <div
          className="shader-list__thumbnail-link"
        >

          <Link
            to={`/shaders/${shader._id}`}
            onClick={handleOpenShader}
          >
            {
              favoriteText &&
                <div
                  className="shader-list__favorite-overlay"
                >
                  <div
                    className="shader-list__favorite-container"
                  >
                    <span>{favoriteText}</span>
                  </div>
                </div>
            }
            {
              shader.thumbnailCloudinaryId ? (
                <img
                  src={cloudinary.url(
                    shader.thumbnailCloudinaryId,
                    { ...itemDimensions, crop: 'fill' },
                  )}
                  alt={shader._id}
                  onError={fixShaderImage}
                />
              ) : (<img src={defaultShaderImage} alt={shader._id} />)
            }
          </Link>
        </div>
        <div className="shader-list__item-left">
          <h2 className="shader-list__item-title">
            <Link
              to={`/shaders/${shader._id}`}
              onClick={handleOpenShader}
            >
              {shader.title || 'Untitled'}
            </Link>
          </h2>
          <p className="shader-list__item-owner">
            by&nbsp;
            <Link to={`/u/${shader.username}`}>
              {shader.username}
            </Link>
          </p>
        </div>
        <div className="shader-list__item-right">
          <button className="shader-list__item-favs" onClick={this.handleFavoriteClick}>
            {shader.stars ? shader.stars.length : 0} <i className={iconClass} />
          </button>
          <p className="shader-list__item-date">
            <span className="shader-list__item-date-info">Created </span>{format(shader.createdAt, 'M/D/YY')}
          </p>
        </div>
      </li>
    );
  }
}

ShaderListItem.propTypes = {
  shader: CustomPropTypes.shader.isRequired,
  handleOpenShader: PropTypes.func,
  starShader: PropTypes.func.isRequired,
  unstarShader: PropTypes.func.isRequired,
  shaderStarred: PropTypes.bool.isRequired,
};

ShaderListItem.defaultProps = {
  handleOpenShader: () => {},
};

const mapStateToProps = (state, ownProps) => ({
  shaderStarred: ((state.user || {}).stars || []).includes(ownProps.shader._id),
});

const mapDispatchToProps = dispatch => ({
  ...(bindActionCreators({
    starShader,
    unstarShader,
  }, dispatch)),
});


export default connect(mapStateToProps, mapDispatchToProps)(ShaderListItem);
