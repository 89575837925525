import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import SignUpPage from '../pages/login/SignUp';
import LoginPage from '../pages/login/Login';
import ResetPasswordRequestPage from '../pages/login/ResetPasswordRequest';
import ResetPasswordPage from '../pages/login/ResetPassword';
import ShaderPage from '../pages/shader/ShaderView';
import CategoriesPage from '../pages/categories/Categories';
import EmbedShaderView from '../pages/shader/EmbedShaderView';
import ShadersPage from '../pages/shaders/Shaders';
import ProfilePage from '../pages/profile/ProfileView';
import TestHomePage from '../pages/home/TestHomeView';
import SettingsPage from '../pages/settings/SettingsView';
import NotFoundPage from '../pages/notfound/NotFound';
import Home from '../pages/home/Home';
import About from '../pages/about/About';
import LoginRequiredRoute from '../components/LoginRequiredRoute/LoginRequiredRoute';
import { getUser } from '../actions/user';

class Routes extends Component {
  componentDidMount() {
    this.props.getUser();
  }

  render() {
    return (
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={About} />
        <Route exact path="/home" component={TestHomePage} />
        <Route exact path="/shaders" component={ShadersPage} />
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/signup" component={SignUpPage} />
        <Route exact path="/resetPasswordRequest" component={ResetPasswordRequestPage} />
        <Route exact path="/categories" component={CategoriesPage} />
        <Route path="/search/:q" component={ShadersPage} />
        <Route path="/resetPassword/:token" component={ResetPasswordPage} />
        <Route path="/shaders/new" component={ShaderPage} />
        <Route path="/shaders/:shaderId/embed" component={EmbedShaderView} />
        <Route path="/shaders/:shaderId" component={ShaderPage} />
        <LoginRequiredRoute path="/settings" component={SettingsPage} />
        <LoginRequiredRoute exact path="/profile" component={ProfilePage} />
        <Route path="/u/:username" component={ProfilePage} />
        <Redirect from="/popular" to="/shaders?&sort=Popularity+%E2%86%93" />
        <Redirect from="/categories" to="/shaders" />
        <Redirect from="/sketches/:shaderId/embed" to="/shaders/:shaderId/embed" />
        <Redirect from="/sketches/:shaderId" to="/shaders/:shaderId" />
        <Redirect from="/search" to="/shaders" />
        <Route path="/:badurl" component={NotFoundPage} />
      </Switch>
    );
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getUser }, dispatch);
}

Routes.propTypes = ({
  getUser: PropTypes.func.isRequired,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes));
