import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import { getProfile } from '../../actions/profile';
import cloudinary from '../../utils/cloudinaryConfig';
import ShadersTabs from '../../components/ShadersTabs/ShadersTabs';
import ShadersTab from '../../components/ShadersTabs/ShadersTab';
import NotFound from '../notfound/NotFound';
import TopMenu from '../../components/TopMenu/TopMenu';
import ProfileEditLine from '../../components/ProfileEditLine/ProfileEditLine';
import ProfileEditImage from '../../components/ProfileEditImage/ProfileEditImage';
import CustomPropTypes from '../../CustomPropTypes';

class ProfileView extends React.Component {
  componentWillMount() {
    this.syncProfile(this.props);
  }

  syncProfile = (props) => {
    const { profile, match: { params }, user } = props;
    if (!params.username && user.username !== profile.username) {
      // when route is just /profile
      return this.props.getProfile(user.username);
    } else if (params.username && profile.username !== params.username) {
      // when slug changes
      return this.props.getProfile(params.username);
    }
    // otherwise, don't fetch
  }

  componentWillReceiveProps(nextProps) {
    const {
      profile: { usernameThatFailedToLoad },
      match: { params: { username },
      } } = nextProps;
    if (usernameThatFailedToLoad === username) return;
    this.syncProfile(nextProps);
  }

  getImageUrl = () => {
    const { profile } = this.props;
    if (profile.image) return profile.image.replace('http:', 'https:');

    if (profile.imageCloudinaryId) {
      return cloudinary.url(profile.imageCloudinaryId, { width: 360, height: 360, crop: 'fill', secure: true }).replace('http:', 'https:');
    }

    return null;
  }

  render() {
    const { profile, user, match } = this.props;
    if (!profile.isProfileLoading && !profile._id) return <NotFound match={match} />;
    const isUserOwner = (profile._id === user._id);
    const ownedShaders = profile.shaders.filter(shader => shader.username === profile.username);
    const favoriteShaders = profile.shaders.filter(shader => profile.stars.includes(shader._id));

    return (
      <section className="profile">
        <TopMenu
          menuType="default"
        />
        <div className="profile-header-wrap">
          <div className="profile-header__spacer" />
          <div className="profile-header">
            <ProfileEditImage
              edit={isUserOwner}
              src={this.getImageUrl()}
            />
            <div className="profile-header__text">
              <h1 className="profile-header__name">
                <ProfileEditLine
                  user={profile.username}
                  value={profile.name}
                  field="name"
                  edit={isUserOwner}
                  growing
                />
              </h1>
              <div className="profile-header__details">
                <ProfileEditLine
                  user={profile.username}
                  value={profile.username}
                  field="username"
                  edit={isUserOwner}
                  format={value => `@${value}`}
                />
                <ProfileEditLine
                  user={profile.username}
                  value={profile.website}
                  edit={isUserOwner}
                  field="website"
                />
                <ProfileEditLine
                  value={profile.bio}
                  user={profile.username}
                  edit={isUserOwner}
                  field="bio"
                />
              </div>
            </div>
          </div>
          { isUserOwner ? (
            <Link className="profile__settings" to="/settings">
              <i className="fa fa-pencil" />
              <span className="profile__settings-text">Settings</span>
            </Link>
          ) : <div className="profile-header__spacer" /> }
        </div>
        <div className="profile-related-shaders">
          <ShadersTabs>
            <ShadersTab
              title="All Shaders"
              tabKey="all"
              shaders={ownedShaders}
              loading={profile.isProfileLoading}
            />
            <ShadersTab
              title="Liked Shaders"
              tabKey="liked"
              shaders={favoriteShaders}
              noneMessage="No user favorites"
              loading={profile.isProfileLoading}
            />
          </ShadersTabs>
        </div>
      </section>
    );
  }
}


ProfileView.defaultProps = ({
});

ProfileView.propTypes = ({
  match: ReactRouterPropTypes.match.isRequired,
  user: CustomPropTypes.user.isRequired,
  profile: CustomPropTypes.profile.isRequired,
  getProfile: PropTypes.func.isRequired,
  isCurrentUser: PropTypes.bool.isRequired,
});

const mapStateToProps = state => ({
  user: state.user,
  profile: state.profile,
  isCurrentUser: state.user._id === state.profile._id,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getProfile }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileView);
