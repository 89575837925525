import * as ActionTypes from '../constants/ActionTypes';

const initialState = () => [];

const featuredShaders = (state, action) => {
  if (state === undefined) {
    state = initialState();
  }

  switch (action.type) {
    case ActionTypes.SET_FEATURED_SHADERS:
      return action.payload;
    default:
      return state;
  }
};

export default featuredShaders;
