import * as ActionTypes from '../constants/ActionTypes';
import { addStarsToShaders, removeStarsFromShaders } from './shaders';

const initialState = {
  numberOfResults: 0,
  shaders: [],
  page: 0,
};

const search = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_SEARCH_RESULTS:
      return { ...state, ...action.payload };
    case ActionTypes.INCREMENT_SEARCH_PAGE:
      return { ...state, page: state.page + 1 };
    case ActionTypes.DECREMENT_SEARCH_PAGE:
      return { ...state, page: state.page - 1 };
    case ActionTypes.RESET_SEARCH_PAGE:
      return { ...state, page: action.payload };
    case ActionTypes.STAR_SHADER: {
      const { payload: { userId, shaderId } } = action;
      return {
        ...state,
        shaders: addStarsToShaders(state.shaders, shaderId, userId),
      };
    }
    case ActionTypes.UNSTAR_SHADER: {
      const { payload: { userId, shaderId } } = action;
      return {
        ...state,
        shaders: removeStarsFromShaders(state.shaders, shaderId, userId),
      };
    }
    default:
      return state;
  }
};

export default search;
