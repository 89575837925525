import React, { Fragment } from 'react';

import Share from './Share';
import withDropdownTooltip from '../DropdownTooltip/DropdownTooltip';

const ShareNavButton = () => (
  <Fragment>
    <i className="fa fa-share" /> Share
  </Fragment>
);

export default withDropdownTooltip(ShareNavButton, Share);
