import React from 'react';
import PropTypes from 'prop-types';

const FrameRate = ({ frameRate }) => (
  <div className="frame-rate">
    <span className="frame-rate__label">
      FPS
    </span>
    <span className="frame-rate__value">
      {frameRate.toFixed(2)}
    </span>
  </div>
);

FrameRate.propTypes = {
  frameRate: PropTypes.number.isRequired,
};

export default FrameRate;
