import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CustomPropTypes from '../../CustomPropTypes';
import TopMenu from '../../components/TopMenu/TopMenu';
import Account from '../../components/Settings/Account';
import ExternalAccounts from '../../components/Settings/ExternalAccounts';
import ChangePassword from '../../components/Settings/ChangePassword';
import {
  updateSettings,
  updatePassword,
  deleteAccount,
  unlinkSocialAccount,
} from '../../actions/user';

const SettingsView = props => (
  <Fragment>
    <section className="settings">
      <TopMenu
        menuType="default"
      />
      <Account
        updateSettings={props.updateSettings}
        deleteAccount={props.deleteAccount}
        user={props.user}
      />
      <ExternalAccounts
        user={props.user}
        unlinkSocialAccount={props.unlinkSocialAccount}
      />
      <ChangePassword
        updatePassword={props.updatePassword}
      />
    </section>
  </Fragment>
);


SettingsView.propTypes = ({
  user: CustomPropTypes.user.isRequired,
  updateSettings: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  updatePassword: PropTypes.func.isRequired,
  unlinkSocialAccount: PropTypes.func.isRequired,
});

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateSettings,
    deleteAccount,
    updatePassword,
    unlinkSocialAccount,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsView);
