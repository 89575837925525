import axios from 'axios';
import { push } from 'connected-react-router';
import pick from 'lodash.pick';
import qs from 'qs';
import {
  success as successNotification,
  error as errorNotification,
} from 'react-notification-system-redux';
import cloudinary from '../utils/cloudinaryConfig';
import * as ActionTypes from '../constants/ActionTypes';
import { parseShader } from '../utils/isf';
import { handler } from '../utils/axios';
import { zipShader } from '../utils/zipShader';
import saveAs from 'file-saver';

export const reportShaderError = error => ({
  type: ActionTypes.SHADER_ERROR,
  payload: error,
});

export const triggerSnapshot = () => ({
  type: ActionTypes.TRIGGER_SNAPSHOT,
});

export const finishSnapshot = () => ({
  type: ActionTypes.FINISH_SNAPSHOT,
});

export const resetImage = name => ({
  type: ActionTypes.RESET_IMAGE,
  payload: name,
});

// pull headers for shader image and asynchronously remove images that 404
const testAndResetImage = (image, dispatch) => {
  const url = image.cloudinaryId && cloudinary.url(image.cloudinaryId);
  if (!url) { return; }
  axios.head(url).catch((err) => {
    console.error(err); // eslint-disable-line
    dispatch(resetImage(image.name));
    dispatch(errorNotification({
      title: `Error loading ${image.name}`,
      message: 'Please reupload the image and save.',
      autoDismiss: 5,
    }));
  });
};

export const setShader = shader => (dispatch) => {
  // set the shader right away
  dispatch({
    type: ActionTypes.SET_SHADER,
    payload: shader,
  });

  if (shader.images && shader.images.length) {
    shader.images.forEach(image => testAndResetImage(image, dispatch));
  }
};

export const setShaderTitle = title => ({
  type: ActionTypes.SET_SHADER_TITLE,
  payload: title,
});

export const parseStoredShader = () => (dispatch, getState) => {
  const { shader } = getState();
  if (shader.parsed) return;
  const parsedShader = parseShader(shader);
  dispatch(setShader(parsedShader));
};

export function setFragmentSource(rawFragmentSource) {
  return {
    type: ActionTypes.SET_FRAGMENT_SOURCE,
    payload: rawFragmentSource,
  };
}

export function setVertexSource(rawVertexSource) {
  return {
    type: ActionTypes.SET_VERTEX_SOURCE,
    payload: rawVertexSource,
  };
}

export function setShaderSource(rawVertexSource, rawFragmentSource, title) {
  return {
    type: ActionTypes.SET_SHADER_SOURCE,
    payload: { rawVertexSource, rawFragmentSource, title },
  };
}

export function setFetchShaderFailed(fetchShaderFailed) {
  return {
    type: ActionTypes.SET_FETCH_SHADER_FAILED,
    payload: fetchShaderFailed,
  };
}

export function getShader(shaderId) {
  return (dispatch) => {
    axios.get(`${process.env.REACT_APP_API_URL}/shaders/${shaderId}`)
      .then(response => dispatch(setShader(response.data)))
      .catch((error) => {
        const { response: { status } } = error;
        dispatch(setFetchShaderFailed(true));
        if (status && status !== 404) handler(dispatch)(error);
      });
  };
}

export function getFeaturedShader(enforceFeatured = true) {
  const enforceParam = enforceFeatured ? { featured: true } : {};
  return (dispatch) => {
    axios.get(
      `${process.env.REACT_APP_API_URL}/shaders`,
      { params: { ...enforceParam, limit: 1 } },
    )
      .then(response => (response.data
          && response.data.length > 0
        && dispatch(setShader(response.data[0]))
      ))
      .catch(handler(dispatch));
  };
}

export function fetchMediaBlobs(images = []) {
  return new Promise((resolve) => {
    const promises = [];
    const fetchedImages = [...images];
    fetchedImages.forEach((image) => {
      if (image.blobUrl && !image.cloudinaryId) {
        const promise = axios.get(image.blobUrl, { responseType: 'blob' }).then((response) => {
          image.blob = response.data;
        });
        promises.push(promise);
      }
    });
    Promise.all(promises).then(() => {
      resolve(fetchedImages);
    });
  });
}

// actually just upload the images without cloudinary ids
export function saveImages(shader, inputs) {
  // actually iterate through all of the inputs ?
  return fetchMediaBlobs(shader.images)
    .then((resolvedImages) => {
      const data = new FormData();
      resolvedImages.forEach((image) => {
        if (!image.cloudinaryId && image.blobUrl) {
          data.append(`images[${image.name}]`, image.blob, image.name);
          data.append(`animated_files[${image.name}]`, image.animated);
        }
        if (image.swatchUrl) {
          data.append(`swatches[${image.name}]`, image.swatchUrl);
          data.append(`animated_files[${image.name}]`, true);
        }
      });
      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      return axios
        .post(`${process.env.REACT_APP_API_URL}/shaders/${shader.id}/images`, data, config)
        .then(res => res.data);
    });
}

export function newShader(type, user) {
  return {
    type: ActionTypes.NEW_SHADER,
    payload: { type, user },
  };
}

export function setOwner(user) {
  return {
    type: ActionTypes.SET_OWNER,
    payload: user,
  };
}

const shaderSubmissionFields = [
  'oldId',
  'title',
  'user',
  'username',
  'working',
  'featured',
  'thumbnailCloudinaryId',
  'rawFragmentSource',
  'rawVertexSource',
  'private',
  'description',
  'shaderType',
  'stars',
  'scale',
  'imports',
  'forkedFrom',
  'categories',
  'publicCategories',
  'privateCategories',
  '_id',
  'id',
];

const setPendingCreate = () => ({
  type: ActionTypes.SET_PENDING_CREATE,
});

export function createShader(rawFragmentSource, rawVertexSource, title) {
  return (dispatch, getState) => {
    const state = getState();
    if (!state.user.authenticated) {
      dispatch(setPendingCreate());
      dispatch(push(
        `/login?${qs.stringify({ pendingAction: ActionTypes.PENDING_CREATE })}`,
      ));
      return;
    }

    const newShaderAttributes = { rawFragmentSource, rawVertexSource, title };
    const shader = { ...state.shader, ...newShaderAttributes };
    const submitShader = pick(shader, shaderSubmissionFields);

    axios.post(`${process.env.REACT_APP_API_URL}/shaders`, submitShader)
      .then(response => new Promise((resolve, reject) => {
        if (response.data && response.data._id && !response.data.error) {
          dispatch(successNotification({
            title: 'Shader created!',
            autoDismiss: 3,
          }));
          resolve(response.data);
        } else {
          dispatch(errorNotification({
            title: 'Error in shader save',
            message: response.data.error.toString(),
            autoDismiss: 0,
          }));
          reject({ response });
        }
      }))
      .then(newShader => saveImages({ ...newShader, ...shader }))
      .then((newShader) => {
        dispatch(setShader(newShader));
        dispatch(push(`/shaders/${newShader._id}`));
        dispatch(setShader({ ...newShader, ...shader }));
        dispatch(saveShader({ ...newShader, ...shader }));
        setTimeout(() => dispatch(triggerSnapshot()), 1000); // give time for shader to render
      })
      .catch(handler(dispatch));
  };
}

export function saveShader(shader) {
  return (dispatch, getState) => {
    const state = getState();
    const updatedShader = { ...state.shader, ...shader };
    const submitShader = pick(updatedShader, shaderSubmissionFields);
    saveImages(updatedShader).then(() => axios.post(
      `${process.env.REACT_APP_API_URL}/shaders/${submitShader._id}`,
      submitShader,
    ))
      .then((response) => {
        if (response.data && response.data._id && !response.data.error) {
          dispatch(setShader(response.data));
          dispatch(successNotification({
            title: 'Shader saved.',
            autoDismiss: 3,
          }));
        } else {
          dispatch(errorNotification({
            title: 'Error saving shader',
            message: response.data.error || '',
            autoDismiss: 0,
          }));
        }
      })
      .catch(handler(dispatch));
  };
}

export const setPrivacy = (isPrivate => saveShader({ private: !!isPrivate }));

export const setFeatured = (isFeatured => (dispatch, getState) => {
  const { shader: { id } } = getState();
  axios.post(`${process.env.REACT_APP_API_URL}/shaders/${id}/feature`, { featured: isFeatured })
    .then(response => dispatch({
      type: ActionTypes.SET_FEATURED,
      payload: response.data.featured,
    }));
}
);

const setPendingFork = () => ({
  type: ActionTypes.SET_PENDING_FORK,
});

export function forkShader() {
  return (dispatch, getState) => {
    const state = getState();

    if (!state.user.authenticated) {
      dispatch(setPendingFork());
      dispatch(push(
        `/login?${qs.stringify({ pendingAction: ActionTypes.PENDING_FORK })}`,
      ));
      return;
    }

    // keep out the root-unique fields
    // and images, which need to be saved
    // after we get a forked shader id
    const {
      _id,
      id,
      thumbnailCloudinaryID,
      images,
      stars,
      featured,
      working,
      privateCategories,
      ...shader
    } = state.shader;
    delete shader.private;
    const { user } = state;
    const { username } = user;
    const submitShader = pick({
      ...shader,
      username,
      user,
      forkedFrom: _id,
    }, shaderSubmissionFields);
    axios.post(`${process.env.REACT_APP_API_URL}/shaders`, submitShader)
      .then(response => new Promise(
        (resolve, reject) => {
          if (response.data && response.data._id) {
            // save the local images
            // now that we have a forked shader id
            resolve({ ...response.data, images });
          } else {
            reject(
              (response.data && response.data.error)
              || 'Error in shader forking',
            );
          }
        },
      ))
      .then(responseShader => saveImages(responseShader)
        .then(() => responseShader))
      .then((responseShader) => {
        dispatch(setShader({ ...responseShader, forking: true }));
        return responseShader._id;
      })
      .then((responseShaderId) => {
        dispatch(push(`/shaders/${responseShaderId}`));
        dispatch({ type: ActionTypes.SET_SHADER_FORKING, payload: false });
        dispatch(successNotification({
          title: 'Shader forked!',
        }));
      })
      .catch(error => dispatch(errorNotification({
        title: 'Error in shader forking',
        message: error.toString(),
        autoDismiss: 0,
      })));
  };
}

export function starShaderSuccess(shaderId, userId) {
  return {
    type: ActionTypes.STAR_SHADER,
    payload: { shaderId, userId },
  };
}

export function unstarShaderSuccess(shaderId, userId) {
  return {
    type: ActionTypes.UNSTAR_SHADER,
    payload: { shaderId, userId },
  };
}

export function starShader(shaderId) {
  return (dispatch, getState) => {
    const state = getState();
    if (!state.user.authenticated) {
      dispatch(push(
        `/login?${qs.stringify({ pendingAction: ActionTypes.STAR_SHADER })}`,
      ));
      return;
    }
    const id = shaderId || state.shader.id;

    axios.post(`${process.env.REACT_APP_API_URL}/shaders/${id}/star`)
      .then(() => {
        dispatch(starShaderSuccess(id, state.user.id));
      })
      .catch(handler(dispatch));
  };
}

export function unstarShader(shaderId) {
  return (dispatch, getState) => {
    const state = getState();
    if (!state.user.authenticated) {
      dispatch(push(
        `/login?${qs.stringify({ pendingAction: ActionTypes.PENDING_FORK })}`,
      ));
      return;
    }
    const id = shaderId || state.shader.id;
    axios.post(`${process.env.REACT_APP_API_URL}/shaders/${id}/unstar`)
      .then(() => {
        dispatch(unstarShaderSuccess(id, state.user.id));
      })
      .catch(handler(dispatch));
  };
}

export function setThumbnailSuccess(thumbnailCloudinaryId) {
  return {
    type: ActionTypes.SET_THUMBNAIL,
    payload: thumbnailCloudinaryId,
  };
}

export function setThumbnail(thumbnail) {
  return (dispatch, getState) => {
    const state = getState();
    const { user, shader } = state;
    if (user.admin || user.username === shader.username) {
      axios.post(`${process.env.REACT_APP_API_URL}/shaders/${state.shader.id}/setThumbnail`, { thumbnail })
        .then(response => dispatch(setThumbnailSuccess(response.data.thumbnailCloudinaryId)))
        .catch(handler(dispatch));
    }
  };
}

export const addImage = (image) => {
  testAndResetImage(image);
  return {
    type: ActionTypes.ADD_IMAGE,
    payload: image,
  };
};

export function deleteShader(shaderId) {
  return (dispatch) => {
    axios.delete(`${process.env.REACT_APP_API_URL}/shaders/${shaderId}`)
      .then(() => {
        dispatch(push('/'));
        dispatch(successNotification({
          title: 'Shader Deleted',
        }));
      })
      .catch(handler(dispatch));
  };
}

export const setScale = (scale = 1) => ({
  type: ActionTypes.SET_SCALE,
  payload: scale,
});

export const setFrameRate = (rate = -1) => ({
  type: ActionTypes.SET_FRAME_RATE,
  payload: rate,
});

export const setFragmentEdited = () => ({
  type: ActionTypes.SET_FRAGMENT_EDITED,
});

export const setVertexEdited = () => ({
  type: ActionTypes.SET_VERTEX_EDITED,
});

export const upgradeShader = () => ({
  type: ActionTypes.UPGRADE_SHADER,
});

export const downloadShader = () => (dispatch, getState) => {
  const { shader, shader: { title } } = getState();
  zipShader(shader, dispatch)
    .then(zip => zip.generateAsync({ type: 'blob' }))
    .then((blob) => {
      saveAs(blob, `${title}.zip`);
      dispatch(successNotification({
        title: 'Shader downloaded.',
      }));
    })
    .catch((err) => {
      dispatch(errorNotification({
        title: 'Error in shader download.',
      }));
    });
};
