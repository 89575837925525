import * as ActionTypes from '../constants/ActionTypes';

const initialState = {
  shaders: [],
  stars: [],
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_PROFILE:
      // const profile = Object.assign({}, action.payload);
      // profile.shaders = profile.shaders.map((shader) => normalizeShader(shader));
      return action.payload;
    case ActionTypes.SET_PROFILE_LOADING:
      return { ...state, isProfileLoading: action.payload };
    case ActionTypes.SET_PROFILE_LOADING_FAILED:
      return { ...state, usernameThatFailedToLoad: action.payload };
    case ActionTypes.STAR_SHADER: {
      if (action.payload.userId === state._id) {
        return {
          ...state,
          stars: [
            ...state.stars.filter(shaderId => shaderId !== action.payload.shaderId),
            action.payload.shaderId,
          ],
        };
      }

      return state;
    }
    case ActionTypes.UNSTAR_SHADER: {
      return {
        ...state,
        stars: state.stars.filter(shaderId => shaderId !== action.payload.shaderId),
      };
    }
    default:
      return state;
  }
};

export default profile;
