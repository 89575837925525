import * as ActionTypes from '../constants/ActionTypes';
import { SHADERS_KEYS } from '../actions/shaders';

export const addStarsToShaders = (shaders = [], shaderId, userId) => (
  shaders.map(s =>
    (s.id === shaderId
      ? { ...s, stars: s.stars.concat(userId) }
      : s),
  )
);

export const removeStarsFromShaders = (shaders = [], shaderId, userId) => (
  shaders.map(s =>
    (s.id === shaderId
      ? { ...s, stars: s.stars.filter(uId => uId !== userId) }
      : s),
  )
);

const shaders = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.SET_SHADERS:
      const { key, shaders } = action.payload;
      return { ...state, [key]: shaders };
    case ActionTypes.STAR_SHADER: {
      const { payload: { userId, shaderId } } = action;
      const shaders = {};
      for (const key of SHADERS_KEYS) {
        shaders[key] = addStarsToShaders(state[key], shaderId, userId);
      }
      return { ...state, ...shaders };
    }
    case ActionTypes.UNSTAR_SHADER: {
      const { payload: { userId, shaderId } } = action;
      const shaders = {};
      for (const key of SHADERS_KEYS) {
        shaders[key] = removeStarsFromShaders(state[key], shaderId, userId);
      }
      return { ...state, ...shaders };
    }
    default:
      return state;
  }
};

export default shaders;
