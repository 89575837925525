import React from 'react';
import PropTypes from 'prop-types';

function Paginator({
  handlePrevPage,
  handleNextPage,
  currentPage,
  numPages,
}) {
  return (
    <div className="paginator">
      <button
        disabled={currentPage === 0}
        onClick={handlePrevPage}
      >
        Prev
      </button>
      <p>{currentPage + 1}</p>
      <button
        disabled={currentPage + 1 === numPages}
        onClick={handleNextPage}
      >
        Next
      </button>
    </div>
  );
}

Paginator.defaultProps = {};

Paginator.propTypes = {
  numPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  handlePrevPage: PropTypes.func.isRequired,
  handleNextPage: PropTypes.func.isRequired,
};

export default Paginator;
