import OverlayTab from './WalkthroughOverlay';
import withTab from '../ShaderEditorTab/ShaderEditorTab';

export default withTab(
  OverlayTab,
  'walkthrough-tab',
  'fa fa-question-circle',
  false,
  'Help',
);
