/* copied from https://github.com/msfeldstein/interactive-shader-format-js/blob/master/src/ISFLineMapper.js */

function getMainLine(src) {
  const lines = src.split('\n');
  for (let i = 0; i < lines.length; i += 1) {
    if (lines[i].indexOf('main()') !== -1) return i;
  }
  return -1;
}

export function mapGLErrorToISFLine(error, glsl, isf) {
  const glslMainLine = getMainLine(glsl);
  const isfMainLine = getMainLine(isf);
  const regex = /ERROR: (\d+):(\d+): (.*)/g;
  const matches = regex.exec(error.message);
  if (!matches || matches.length < 2) { return -1; }
  const glslErrorLine = matches[2];
  const isfErrorLine = parseInt(glslErrorLine, 10) + isfMainLine - glslMainLine;
  return isfErrorLine;
}

function getISFStart(src) {
  const lines = src.split('\n');
  for (let i = 0; i < lines.length; i += 1) {
    if (lines[i].indexOf('/*') !== -1) return i;
  }
  return -1;
}

export function mapISFErrorToISFLine(error, isf) {
  const isfStart = getISFStart(isf);
  if (isfStart < 0) { return -1; }
  return error.lineNumber + isfStart;
}
