import axios from 'axios';
import {
  usernameMaxLength,
  usernameLengthRegex,
  usernameContentRegex,
  emailRegex,
} from '../constants/regexes';

export const asyncValidate = (formProps, dispatch, props, fieldName) => {
  if (fieldName) {
    const queryParams = {};
    queryParams[fieldName] = formProps[fieldName];
    queryParams.check_type = fieldName;
    return axios.get(`${process.env.REACT_APP_API_URL}/signup/duplicate_check`, { params: queryParams })
      .then((response) => {
        if (response.data.exists) {
          const error = {};
          error[fieldName] = response.data.message;
          throw error;
        }
      });
  }
  return Promise.resolve(true).then(() => {});
};

export const validateUserObject = (values, requireAll = true) => {
  const errors = {};
  if (requireAll && !values.username) {
    errors.username = 'Please enter a username';
  } else if (values.username && !values.username.match(usernameLengthRegex)) {
    errors.username = `Username must be fewer than ${usernameMaxLength} characters`;
  } else if (values.username && !values.username.match(usernameContentRegex)) {
    errors.username = 'Username must only consist of numbers, letters, periods, dashes, and underscores';
  }

  if (values.email && !values.email.match(emailRegex)) {
    errors.email = 'Please enter a valid email address';
  }

  return errors;
};
