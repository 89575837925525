import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  downloadAllShaders,
} from '../../actions/profile';

const makeClass = (base, suffix) => classNames({
  [`shaders-tabs${suffix}`]: true,
  [`${base}${suffix}`]: base,
});

class ShadersTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
    };
  }

  setActiveIndex = (activeIndex) => {
    this.setState({ activeIndex });
  }

  render() {
    const { children, baseCssClass, downloadAllShaders } = this.props;
    const { activeIndex } = this.state;
    const className = suffix => makeClass(baseCssClass, suffix);

    return (
      <div className={className('__container')}>
        <div className={className('__sorting')}>
          {
            (React.Children.toArray(children)
              .filter(child => (child.props && child.props.tabKey && child.props.title))
              .map(({ props: { tabKey, title } }, index) => (
                <Fragment key={tabKey}>
                  <input
                    type="radio"
                    id={`shaders-tabs__${tabKey}`}
                    className={className('__sorting-param-input')}
                    name="filter"
                    checked={activeIndex === index}
                    onChange={() => this.setActiveIndex(index)}
                  />
                  <label
                    htmlFor={`shaders-tabs__${tabKey}`}
                    className={className('__sorting-param')}
                  >
                    {title}
                  </label>
                  {
                    tabKey === 'all'
                      ? (
                        <button className="download-all-shaders-link" onClick={downloadAllShaders}>
                          <img alt="download all shaders" src="/arrow-alt-to-bottom-solid.svg" />
                        </button>
                      )
                      : ''
                  }
                </Fragment>
              )) || '')
          }
        </div>
        {
          React.Children.count(children) > 0
            ? React.Children.toArray(children).filter(x => x)[activeIndex] : ''
        }
      </div>);
  }
}

const mapStateToProps = state => ({
  shader: state.shader,
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  ...(bindActionCreators({
    downloadAllShaders,
  }, dispatch)),
});

ShadersTabs.defaultProps = ({
  baseCssClass: '',
});

ShadersTabs.propTypes = ({
  downloadAllShaders: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  baseCssClass: PropTypes.string,
});


export default connect(mapStateToProps, mapDispatchToProps)(ShadersTabs);
