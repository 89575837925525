import React from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import classNames from 'classnames';
import { compose } from 'redux';

function withDropdownTooltip(ButtonComponent, TooltipComponent) {
  class DropdownClass extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        expanded: false,
      };
    }

    toggle = () => {
      this.setState(({ expanded }) => ({ expanded: !expanded }));
    }

    handleClickOutside = () => {
      this.setState({ expanded: false });
    }

    setExpanded = (expand) => {
      this.setState({ expanded: expand });
    }

    render() {
      const dropdownClass = classNames({
        'dropdown-tooltip': true,
        'dropdown-tooltip--expanded': this.state.expanded,
      });
      const buttonClass = classNames({
        'dropdown-tooltip__button': true,
        [this.props.buttonClass]: !!this.props.buttonClass,
      });
      const tooltipClass = classNames({
        'dropdown-tooltip__tooltip': true,
        [this.props.tooltipClass]: !!this.props.tooltipClass,
      });
      return (
        <div className={dropdownClass}>
          <button
            className={buttonClass}
            onClick={this.toggle}
            type="button"
            title="Share this shader"
          >
            <ButtonComponent {...this.props} />
          </button>
          <div className={tooltipClass} style={this.props.tooltipStyle}>
            <TooltipComponent
              {...this.props}
              setExpanded={this.setExpanded}
            />
          </div>
        </div>
      );
    }
  }

  DropdownClass.propTypes = ({
    buttonClass: PropTypes.string,
    tooltipClass: PropTypes.string,
    dropdownClass: PropTypes.string,
    tooltipStyle: PropTypes.object,
  });

  DropdownClass.defaultProps = ({
    buttonClass: '',
    tooltipClass: '',
    dropdownClass: '',
    tooltipStyle: {},
  });

  return DropdownClass;
}

export default compose(onClickOutside, withDropdownTooltip);
