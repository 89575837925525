import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import renderField from '../FormFields/InputField';
import SubmitButton from '../FormFields/SubmitButton';

const validate = (values) => {
  const errors = {};
  if (!values.currentPassword) {
    errors.currentPassword = 'Please enter your current password to change it.';
  } else if (!values.password) {
    errors.password = 'Please enter a new password.';
  } else if (!values.confirmPassword) {
    errors.confirmPassword = 'Please enter your new password again.';
  } else if (values.password !== values.confirmPassword) {
    errors.confirmPassword = 'Passwords do not match.';
  }
  return errors;
};


const ChangePasswordForm = ({
  handleSubmit, onSubmit, submitting, pristine, hasPassword,
}) => (
  <form className="change-password-form" onSubmit={handleSubmit(onSubmit)}>
    {
      hasPassword && (
        <Field
          name="currentPassword"
          component={renderField}
          type="password"
          label="Current
          Password"
        />
      )
    }
    <Field name="password" component={renderField} type="password" label="New Password" />
    <Field name="confirmPassword" component={renderField} type="password" label="Confirm New Password" />
    <SubmitButton
      submitting={submitting}
      pristine={pristine}
      value="Save Changes"
    />
  </form>
);

ChangePasswordForm.propTypes = ({
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  hasPassword: PropTypes.bool.isRequired,
});


// async validate could check if current password matches?
const WrappedChangePasswordForm = reduxForm({
  form: 'change-password',
  validate,
})(ChangePasswordForm);


const mapStateToProps = ({ user: { hasPassword } }) => ({ hasPassword });

export default connect(mapStateToProps)(WrappedChangePasswordForm);
