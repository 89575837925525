import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ShaderTitleInput from '../ShaderTitleInput/ShaderTitleInput';
import ControlsButton from '../Controls/ControlsButton';
import CodePaneButton from '../CodePane/CodePaneButton';
import ShareButton from '../Share/ShareButton';
import { starShader, unstarShader, addImage } from '../../actions/shader';
import { updateControl, updateControls } from '../../actions/inputs';
import ShaderMessageButton from '../ShaderMessage/ShaderMessageButton';
import { userStarredCurrent, isUserOwner, isNew } from '../../selectors/shader';
import { setWalkthroughMobile, enableWalkthrough } from '../../actions/walkthrough';
import { currentPage } from '../../selectors/walkthrough';
import CustomPropTypes from '../../CustomPropTypes';
import WalkthroughOverlay from '../WalkthroughOverlay/WalkthroughOverlay';
import * as Walkthrough from '../../constants/walkthrough';

const tools = {
  CONTROLS: 'CONTROLS',
  CODE: 'CODE',
  SHARE: 'SHARE',
  MESSAGE: 'MESSAGE',
};

class MobileShaderToolbar extends React.Component {
  constructor(props) {
    super(props);
    props.setWalkthroughMobile();
    this.state = {
      toolVisible: undefined,
    };
  }

  expandTool(tool) {
    this.setState({
      toolVisible: tool,
    });
  }

  retractAll = () => {
    this.setState({
      toolVisible: undefined,
    });
  }

  render() {
    const {
      walkthrough,
      currentPage,
      shader,
      isNew,
      isUserOwner,
      shaderStarred,
      inputs,
      user,
    } = this.props;
    const walkthroughEnabled = walkthrough.enabled;
    let toolVisible = this.state.toolVisible;
    if (walkthroughEnabled) {
      switch (currentPage) {
        case Walkthrough.MOBILE_CONSOLE_PAGE:
          toolVisible = tools.MESSAGE;
          break;
        case Walkthrough.MOBILE_CODE_TAB_PAGE:
          toolVisible = tools.CODE;
          break;
        case Walkthrough.MOBILE_CONTROLS_PAGE:
          toolVisible = tools.CONTROLS;
          break;
        default:
          toolVisible = '';
      }
    }

    const codeVisible = toolVisible === tools.CODE;
    const shareVisible = toolVisible === tools.SHARE;
    const messageVisible = toolVisible === tools.MESSAGE;
    const controlsVisible = toolVisible === tools.CONTROLS;

    const showControls = (
      (shader && shader.inputs)
        ? shader.inputs.length !== 0
        : false);

    return (
      <Fragment>
        <div className="mobile-tools__container">
          <WalkthroughOverlay mobile />
          <div className="mobile-tools">
            <CodePaneButton
              expanded={codeVisible}
              expand={() => this.expandTool(tools.CODE)}
              retract={this.retractAll}
              shader={shader}
              isNew={isNew}
              isUserOwner={isUserOwner}
              user={user}
            />
            <ShaderMessageButton
              expanded={messageVisible}
              expand={() => this.expandTool(tools.MESSAGE)}
              retract={this.retractAll}
              error={shader.error}
              warning={shader.warning}
              message={shader.error && shader.error.message}
            />
            {showControls
              && (
                <ControlsButton
                  expanded={controlsVisible}
                  expand={() => this.expandTool(tools.CONTROLS)}
                  retract={this.retractAll}
                  shader={shader}
                  inputs={inputs}
                  updateControl={this.props.updateControl}
                  updateControls={this.props.updateControls}
                  addImage={this.props.addImage}
                />
              )
            }
            <ShaderTitleInput
              inputClass="mobile-tools__title"
              formClass="mobile-tools__title-form"
            />
            <button
              className="mobile-tools__button"
              onClick={this.props.enableWalkthrough}
              type="button"
            >
              <i className="fa fa-question-circle" />
            </button>
            {shaderStarred
              ? !isNew
                && (
                  <button
                    className="mobile-tools__button"
                    onClick={() => this.props.unstarShader()}
                    type="button"
                  >
                    <i className="fa fa-heart" />
                  </button>
                )
              : !isNew
                && (
                  <button
                    className="mobile-tools__button"
                    onClick={() => this.props.starShader()}
                    type="button"
                  >
                    <i className="fa fa-heart-o" />
                  </button>
                )
            }
            {!isNew
              && (
                <ShareButton
                  expanded={shareVisible}
                  expand={() => this.expandTool(tools.SHARE)}
                  retract={this.retractAll}
                  shader={shader}
                />
              )
            }
          </div>
        </div>
      </Fragment>
    );
  }
}

MobileShaderToolbar.propTypes = ({
  walkthrough: CustomPropTypes.walkthrough.isRequired,
  shader: CustomPropTypes.shader.isRequired,
  inputs: CustomPropTypes.inputs.isRequired,
  user: CustomPropTypes.user.isRequired,
  shaderStarred: PropTypes.bool.isRequired,
  unstarShader: PropTypes.func.isRequired,
  starShader: PropTypes.func.isRequired,
  addImage: PropTypes.func.isRequired,
  isUserOwner: PropTypes.bool.isRequired,
  isNew: PropTypes.bool.isRequired,
  updateControl: PropTypes.func.isRequired,
  updateControls: PropTypes.func.isRequired,
  setWalkthroughMobile: PropTypes.func.isRequired,
  enableWalkthrough: PropTypes.func.isRequired,
  currentPage: PropTypes.string.isRequired,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updateControls,
  updateControl,
  unstarShader,
  starShader,
  addImage,
  setWalkthroughMobile,
  enableWalkthrough,
}, dispatch);

const mapStateToProps = state => ({
  walkthrough: state.walkthrough,
  shader: state.shader,
  inputs: state.inputs,
  user: state.user,
  shaderStarred: userStarredCurrent(state),
  isUserOwner: isUserOwner(state),
  isNew: isNew(state),
  currentPage: currentPage(state),
});


export default connect(mapStateToProps, mapDispatchToProps)(MobileShaderToolbar);
