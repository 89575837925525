import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Prompt } from 'react-router';
import qs from 'qs';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MediaQuery from 'react-responsive';
import CustomPropTypes from '../../CustomPropTypes';
import NotFound from '../notfound/NotFound';
import {
  getShader,
  setOwner,
  newShader,
} from '../../actions/shader';
import { getRelatedShaders } from '../../actions/relatedShaders';
import { enableWalkthrough } from '../../actions/walkthrough';
import { isNew } from '../../selectors/shader';
import { currentPage } from '../../selectors/walkthrough';
import DesktopShaderView from './DesktopShaderView';
import MobileShaderView from './MobileShaderView';

class ShaderView extends Component {
  constructor(props) {
    super(props);

    if (props.match.url === '/shaders/new') {
      const searchParams = new URLSearchParams(props.location.search);
      const shaderType = searchParams.get('type');
      props.newShader(shaderType, props.user);
      if (props.user.authenticated) {
        props.setOwner(props.user);
      }
    }
  }

  componentDidMount() {
    const { shader, shaders, match: { params }, getRelatedShaders, getShader } = this.props;
    if (!shaders || shaders.length < 1) {
      getRelatedShaders(shader._id);
    }
    if (params.shaderId) {
      getShader(params.shaderId);
    }
  }

  componentWillUpdate(newProps) {
    // don't want to reparse shader if there are errors
    if (newProps.shader && newProps.shader.error) {
      return;
    }

    const { match: { params: { shaderId } } } = this.props;
    const { match: { params: { shaderId: newShaderId } } } = newProps;

    // if shaderId param in url changes
    if (shaderId) {
      // don't refetch if new shader (and thus there's no shaderId param)
      if (shaderId !== newShaderId || this.props.shader.id !== newProps.shader.id) {
        this.props.getShader(newProps.match.params.shaderId);
      }
    }

    // if authenticate callback completes and user is on new shader page
    if (
      this.props.isNew
      && !this.props.user.authenticated
      && newProps.user.authenticated
    ) {
      this.props.setOwner(newProps.user);
    }

    if (this.props.shader._id !== newProps.shader._id) {
      this.props.getRelatedShaders(newProps.shader._id);
    }
  }

  render() {
    const {
      isNew,
      shader,
      shader: { fetchShaderFailed },
      shaders,
      walkthrough,
      match,
    } = this.props;
    const shaderViewProps = {
      isNew,
      shader,
      shaders,
      walkthroughEnabled: walkthrough.enabled,
    };
    if (fetchShaderFailed) return <NotFound match={match} />;

    return (
      <div className="shader-view">
        <Prompt
          when={
            (!!shader.dirty || !!shader.fragmentEdited || !!shader.vertexEdited)
            && !(shader.forking || shader.pendingAction)
          }
          message={(location) => {
            const { pendingAction } = qs.parse(location.search.substring(1));
            return (
              !!(pendingAction)
              || 'Unsaved changes to shader. Are you sure you want to leave?'
            );
          }}
        />
        <div className="shader-background">
          <MediaQuery query="(min-width: 680px) and (min-height: 576px)">
            <DesktopShaderView
              {...shaderViewProps}
              walkthroughPage={this.props.currentPage}
            />
          </MediaQuery>
          <MediaQuery query="(max-width: 680px), (max-height: 576px)">
            <MobileShaderView
              {...shaderViewProps}
              walkthroughPage={this.props.currentPage}
            />
          </MediaQuery>
        </div>
      </div>
    );
  }
}

ShaderView.defaultProps = ({
});

ShaderView.propTypes = ({
  shader: CustomPropTypes.shader.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  user: CustomPropTypes.user.isRequired,
  setOwner: PropTypes.func.isRequired,
  getShader: PropTypes.func.isRequired,
  getRelatedShaders: PropTypes.func.isRequired,
  currentPage: PropTypes.string.isRequired,
  walkthrough: CustomPropTypes.walkthrough.isRequired,
  enableWalkthrough: PropTypes.func.isRequired,
  newShader: PropTypes.func.isRequired,
  shaders: PropTypes.arrayOf(CustomPropTypes.shader).isRequired,
  isNew: PropTypes.bool.isRequired,
});

const mapStateToProps = state => ({
  shader: state.shader,
  shaders: state.relatedShaders,
  user: state.user,
  walkthrough: state.walkthrough,
  isNew: isNew(state),
  currentPage: currentPage(state),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getShader,
    setOwner,
    newShader,
    enableWalkthrough,
    getRelatedShaders,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ShaderView);
