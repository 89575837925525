import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { initiatePasswordReset } from '../../actions/user';
import ResetPasswordRequestForm from '../../components/LoginForms/ResetPasswordRequestForm';
import TopMenu from '../../components/TopMenu/TopMenu';

class ResetPasswordRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = { requestSuccess: false };
  }

  handleSuccess = () => {
    this.setState({ requestSuccess: true });
  }

  render() {
    return (
      <section className="reset-password-request">
        <TopMenu
          menuType="default"
        />
        <div className="login__main">
          <h1 className="login__header">
            Request Password Reset
          </h1>
          <Link className="login__alternate-link" to="/login">
            Return to Login
          </Link>
          { (this.state.requestSuccess) ? (
            <div className="login__dialog">
              Reset request received.
              If your email is registered and confirmed,
              you will receive a message containing a password reset link.
              Be sure to check spam folders and filters if you do not see the email.
            </div>
          ) : (
            <ResetPasswordRequestForm
              onSubmit={this.props.initiatePasswordReset}
              onSubmitSuccess={this.handleSuccess}
            />
          )
          }
        </div>
      </section>
    );
  }
}

function mapStateToProps() {
  return {
  };
}

function mapDispatchToProps() {
  return {
    initiatePasswordReset,
  };
}

ResetPasswordRequest.propTypes = ({
  initiatePasswordReset: PropTypes.func.isRequired,
});


export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordRequest);
