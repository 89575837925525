import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { updateSettings } from '../../actions/user';

const fieldComponent = ({ input, type = '', label = '' }) => (
  <input
    {...input}
    type={type}
    className="edit-line__content"
    placeholder={label}
  />);

fieldComponent.propTypes = {
  input: PropTypes.object.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
};

fieldComponent.defaultProps = {
  type: '',
  label: '',
};

class ProfileEditLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
    };
  }

  handleEdit = () => {
    this.setState({ isEditing: true });
  }

  handleCancel = () => {
    this.setState({ isEditing: false });
  }

  componentWillMount() {
    this.initializeForm(this.props);
  }

  componentWillUpdate(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.props.destroy();
      this.initializeForm(nextProps);
    }
    if (!this.props.submitSucceeded && nextProps.submitSucceeded) {
      this.handleCancel();
      this.props.destroy();
      this.initializeForm(nextProps);
    }
  }

  initializeForm = (props) => {
    const { field, value } = props;
    this.props.initialize({ [field]: value });
  }

  render() {
    const {
      format, field, value, edit, handleSubmit, growing, user,
    } = this.props;
    const { isEditing } = this.state;
    const inner = (edit
      ? (
        <button
          className="edit-line__button edit-line__edit"
          onClick={this.handleEdit}
          type="button"
        >
          Edit
        </button>
      )
      : <div />
    );
    return (
      (
        <form
          className={`edit-line__form ${growing ? '' : 'edit-line__form-restricted'}`}
          onSubmit={handleSubmit(this.props.updateSettings)}
        >
          <div
            className={`edit-line__main ${isEditing ? 'edit-line__main-editing' : ''}`}
            style={isEditing ? {} : { display: 'none' }}
          >
            <Field
              component={fieldComponent}
              name={field}
            />
            <input
              className="edit-line__button edit-line__save-changes"
              type="submit"
              value="Save Changes"
            />
            <input
              className="edit-line__button edit-line__cancel"
              onClick={this.handleCancel}
              value="Cancel"
              type="button"
            />
          </div>
          {!isEditing
          && (
            <div className="edit-line__main">
              <div className="edit-line__content">
                {(user ? format(value) || `No ${field}` : 'Loading...')}
              </div>
            </div>
          )
          }
          {!isEditing && inner}
        </form>
      )
    );
  }
}

ProfileEditLine.propTypes = ({
  destroy: PropTypes.func.isRequired,
  growing: PropTypes.bool,
  updateSettings: PropTypes.func.isRequired,
  format: PropTypes.func,
  field: PropTypes.string.isRequired,
  value: PropTypes.string,
  edit: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
  user: PropTypes.string,
});

ProfileEditLine.defaultProps = {
  format: x => x,
  growing: false,
  value: '',
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => bindActionCreators({
  updateSettings,
}, dispatch);

const BaseComponent = reduxForm()(
  connect(mapStateToProps, mapDispatchToProps)(ProfileEditLine),
);

export default props => <BaseComponent {...props} form={`profile-edit-${props.field}`} />;
