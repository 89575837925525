import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Controls from './Controls';
import CustomPropTypes from '../../CustomPropTypes';

const ControlsButton = ({
  shader,
  inputs,
  updateControls,
  updateControl,
  addImage,
  retract,
  expand,
  expanded,
}) => {
  const controlsClass = classNames({
    'controls-button': true,
    'controls-button--expanded': expanded,
  });
  return (
    <div className={controlsClass}>
      <div className="controls-button__retracted">
        <button
          className="controls-button__icon"
          onClick={expand}
          type="button"
        >
          <i className="fa fa-sliders" />
        </button>
      </div>
      <div className="controls-button__expanded">
        <div className="controls-button__mobile-container">
          <Controls
            shader={shader}
            inputs={inputs}
            updateControl={updateControl}
            updateControls={updateControls}
            addImage={addImage}
          />
        </div>
        <button
          className="controls-button__icon"
          onClick={retract}
          type="button"
        >
          <i className="fa fa-sliders" />
        </button>
      </div>
    </div>
  );
};

ControlsButton.propTypes = {
  shader: CustomPropTypes.shader.isRequired,
  inputs: CustomPropTypes.inputs.isRequired,
  updateControls: PropTypes.func.isRequired,
  updateControl: PropTypes.func.isRequired,
  addImage: PropTypes.func.isRequired,
  retract: PropTypes.func.isRequired,
  expand: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
};

export default ControlsButton;
