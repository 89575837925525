import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import ReactGA from 'react-ga';

import NotificationDisplay from './components/NotificationDisplay/NotificationDisplay';
import configureStore from './store/configureStore';
import createHistory from './routing/createHistory';
import './styles/main.css';
import Routes from './routing/Routes';
import { unregister } from './registerServiceWorker';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Footer from './components/Footer/Footer';

if (process.env.REACT_APP_GA_CODE) {
  ReactGA.initialize(process.env.REACT_APP_GA_CODE);
}

const history = createHistory();

history.listen((location) => {
  if (process.env.REACT_APP_GA_CODE) {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }
});

const { store, persistor } = configureStore(history);
class Root extends React.Component {
  componentDidCatch(error, info) {
    ReactGA.exception({
      description: `${error}: ${info.componentStack}`,
      fatal: true,
    });
  }

  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ConnectedRouter history={history}>
            <ScrollToTop>
              <Fragment>
                <main>
                  <Routes />
                </main>
                <Footer />
                <NotificationDisplay />
              </Fragment>
            </ScrollToTop>
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById('root'));
unregister();
