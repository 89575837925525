import * as ActionTypes from '../constants/ActionTypes';

const initialState = {
  isFullscreen: false,
  opacity: 1.0,
};

const codeTab = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.TOGGLE_IS_FULLSCREEN:
      return {
        ...state,
        isFullscreen: !state.isFullscreen,
      };
    case ActionTypes.SET_OPACITY:
      return {
        ...state,
        opacity: action.payload,
      };
    default:
      return state;
  }
};

export default codeTab;
