import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CustomPropTypes from '../../CustomPropTypes';
import cloudinary from '../../utils/cloudinaryConfig';
import { fixShaderImage } from '../../utils/images';
import { getRelatedShaders } from '../../actions/relatedShaders';
import { getPopularShaders } from '../../actions/popularShaders';
import { getFeaturedShaders } from '../../actions/featuredShaders';
import ShadersTabs from '../ShadersTabs/ShadersTabs';
import ShadersTab from '../ShadersTabs/ShadersTab';
import defaultShaderImage from '../../assets/isf-hero.png';

class RelatedShaders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view: 'default',
    };
  }

  componentDidMount() {
    if (!this.props.relatedShaders || this.props.relatedShaders.length < 1) {
      this.props.getRelatedShaders(this.props.shader._id);
    }
    if (!this.props.popularShaders || this.props.popularShaders.length < 1) {
      this.props.getPopularShaders();
    }
    if (!this.props.featuredShaders || this.props.featuredShaders.length < 1) {
      this.props.getFeaturedShaders();
    }
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.shader._id !== this.props.shader._id) && this.state.view === 'expanded') {
      // if hidden by parent component, hide
      this.setView('default');
    }
    if (!this.props.shader._id && prevProps.shader._id) {
      this.props.getRelatedShaders(prevProps.shader._id);
    }
  }

  setView = (view) => {
    this.setState({ view });
  }

  render() {
    const { view } = this.state;
    const { shader: { thumbnailCloudinaryId } } = this.props;
    const relatedShadersClass = classNames({
      'related-shaders': true,
      'related-shaders--expanded': view === 'expanded',
    });
    return (
      <Fragment>
        <div className="shader-info">
          <img
            alt={this.props.shader.title}
            className="shader-info-img"
            src={
              thumbnailCloudinaryId
                ? (cloudinary.url(thumbnailCloudinaryId, { width: 360, height: 250, crop: 'fill' }))
                : defaultShaderImage
            }
            onError={fixShaderImage}
          />
          <div className="shader-info-text">
            <h1 className="shader-info-title">
              {this.props.shader.title}
            </h1>
            <h2 className="shader-info-owner">
              by
              {' '}
              <Link to={`/u/${this.props.shader.username}`}>
                {this.props.shader.username}
              </Link>
            </h2>
          </div>
        </div>
        <section className={relatedShadersClass}>
          <div className="related-shaders__retracted">
            <button
              className="related-shaders__button"
              onClick={() => this.setView('default')}
              type="button"
              title="Show related shaders"
            >
              <i className="fa fa-angle-down" />
            </button>
          </div>
          <button
            className="related-shaders__default-browse-button"
            onClick={() => this.setView('expanded')}
            type="button"
          >
            <i className="fa fa-arrow-down fa-fw" /> Related Shaders
          </button>
          <div className="related-shaders__expanded">
            <div className="related-shaders__retract-button-container">
              <button
                className="related-shaders__retract-button"
                onClick={() => this.setView('default')}
                type="button"
              >
                Back To Shader
              </button>
            </div>
            <div className="related-shaders__current-shader">
              <img
                src={cloudinary.url(this.props.shader.thumbnailCloudinaryId, { width: 360, height: 250, crop: 'fill' })}
                alt={this.props.shader.title}
                onError={fixShaderImage}
              />
              <div className="related-shaders__current-shader-details">
                <h1 className="related-shaders__current-shader-title">
                  {this.props.shader.title}
                </h1>
                <h2 className="related-shaders__current-shader-owner">
                  by
                  {' '}
                  <Link to={`/u/${this.props.shader.username}`}>
                    {this.props.shader.username}
                  </Link>
                </h2>
                <p>
                  {this.props.shader.description}
                </p>
              </div>
            </div>
            <ShadersTabs>
              <ShadersTab
                title="Related"
                tabKey="related"
                shaders={this.props.relatedShaders}
                handleOpenShader={this.props.resetView}
              />
              <ShadersTab
                title="Popular"
                tabKey="popular"
                shaders={this.props.popularShaders}
                handleOpenShader={this.props.resetView}
              />
              <ShadersTab
                title="Featured"
                tabKey="featured"
                shaders={this.props.featuredShaders}
                handleOpenShader={this.props.resetView}
              />
            </ShadersTabs>
          </div>
        </section>
      </Fragment>
    );
  }
}

RelatedShaders.defaultProps = ({
  shaders: [],
  relatedShaders: [],
  popularShaders: [],
  featuredShaders: [],
});

RelatedShaders.propTypes = ({
  shader: CustomPropTypes.shader.isRequired,
  shaders: PropTypes.arrayOf(CustomPropTypes.shader),
  relatedShaders: PropTypes.arrayOf(CustomPropTypes.shader),
  popularShaders: PropTypes.arrayOf(CustomPropTypes.shader),
  featuredShaders: PropTypes.arrayOf(CustomPropTypes.shader),
  resetView: PropTypes.func.isRequired,
  getRelatedShaders: PropTypes.func.isRequired,
});

const mapStateToProps = state => ({
  shader: state.shader,
  relatedShaders: state.relatedShaders,
  popularShaders: state.popularShaders,
  featuredShaders: state.featuredShaders,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getRelatedShaders,
    getPopularShaders,
    getFeaturedShaders,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(RelatedShaders);
