import React from 'react';
import PropTypes from 'prop-types';
import SearchContainer from '../TopMenu/SearchContainer';
import ShadersFiltersSelect from './ShadersFiltersSelect';
import ShadersFiltersToggle from './ShadersFiltersToggle';
import CustomPropTypes from '../../CustomPropTypes';
import {
  RELEVANCE,
  TITLE_ASC,
  TITLE_DESC,
  DATE_CREATED_ASC,
  DATE_CREATED_DESC,
  POPULARITY_ASC,
  POPULARITY_DESC,
  USERNAME_ASC,
  USERNAME_DESC,
} from '../../constants/sortOptions';

class ShadersFilters extends React.Component {
  componentDidMount = () => {
    this.props.getCategories();
  }

  onCategoryChange = () => {
    this.props.searchShaders();
  }

  onSortChange = () => {
    this.props.searchShaders();
  }

  onSubmitSearch = () => {
    this.props.searchShaders();
  }

  render() {
    const { categories } = this.props;
    const sortOptions = [
      RELEVANCE,
      TITLE_ASC,
      TITLE_DESC,
      DATE_CREATED_ASC,
      DATE_CREATED_DESC,
      POPULARITY_ASC,
      POPULARITY_DESC,
      USERNAME_ASC,
      USERNAME_DESC,
    ];

    const publicCategories = categories
      .filter(o => o.public)
      .sort((o1, o2) => o1.name.localeCompare(o2.name));

    const privateCategories = categories
      .filter(o => !o.public)
      .sort((o1, o2) => o1.name.localeCompare(o2.name));

    const sortedCategories = [...publicCategories, ...privateCategories];

    return (
      <ul className="shaders-filters">
        <li>
          <ShadersFiltersSelect
            name="sort"
            form="sort"
            options={sortOptions.map(o => ({ name: o }))}
            onChange={this.onSortChange}
          />
        </li>
        <li>
          <ShadersFiltersSelect
            includeAllOption
            name="Categories"
            form="category"
            options={sortedCategories || []}
            onChange={this.onCategoryChange}
          />
        </li>
        <li>
          <div className="shaders-filters__search">
            <SearchContainer
              onSubmit={this.onSubmitSearch}
            />
          </div>
        </li>
        { this.props.admin &&
        <li>
          <ShadersFiltersToggle
            name="showPrivate"
            title="Show Private Shaders"
            onChange={this.onSubmitSearch}
          />
        </li>
        }
      </ul>
    );
  }
}

ShadersFilters.defaultProps = {
  categories: [],
  createdDates: [],
  admin: false,
  showPrivate: false,
};

ShadersFilters.propTypes = {
  showPrivate: PropTypes.bool,
  admin: PropTypes.bool,
  categories: CustomPropTypes.categories,
  searchShaders: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  createdDates: PropTypes.arrayOf(PropTypes.object.isRequired),
};

export default ShadersFilters;
