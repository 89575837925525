import * as Permissions from '../constants/permissions';

export const isUserOwner = (({ user, shader }) => (
  (!shader.username) || shader.username === user.username
));

export const userPermissions = (({ user, shader }) => {
  if (!shader.username) {
    return Permissions.PERMISSION_NEW_SHADER;
  } if (user.admin && shader.username === user.username) {
    return Permissions.PERMISSION_ADMIN_OWNER;
  } if (shader.username === user.username) {
    return Permissions.PERMISSION_OWNER;
  } if (user.admin) {
    return (
      user.adminMode
        ? Permissions.PERMISSION_ADMIN
        : false
    );
  }
  return false;
});

export const isNew = ({ shader }) => (!shader.id);

const shaderStarred = (id, stars) => {
  if ((stars || []).includes(id)) {
    return true;
  }
  return false;
};

export const userStarredShader = ({ user }, stars) => {
  if (!user.authenticated) return false;
  return shaderStarred(user.id, stars);
};

export const userStarredCurrent = state => userStarredShader(state, state.shader.stars);

export const profileStarredCurrent = (
  ({ shader, profile }) => shaderStarred(shader.id, profile.stars)
);

export const frameRate = state => (
  state.frameRate || -1
);
