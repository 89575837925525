import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';

const Footer = ({ location }) => {
  if (location && location.pathname && location.pathname.match(/shaders\/.+/)) return null;
  return (
    <div className="footer">
      <div className="footer__text">
        <strong>BETA</strong> Please email <a href="mailto:isf@vidvox.net">isf@vidvox.net</a> w/ issues + ideas!
      </div>
      <div className="footer__icons">
        <a href="mailto:isf@vidvox.net" title="Send us an email">
          <span className="fa-stack">
            <i className="fa fa-square fa-stack-2x" />
            <i className="fa fa-envelope-o fa-stack-1x" />
          </span>
        </a>
        <a href="https://github.com/vidvox/isf" title="ISF GitHub account">
          <span className="fa-stack">
            <i className="fa fa-square fa-stack-2x" />
            <i className="fa fa-github-alt fa-stack-1x" />
          </span>
        </a>
        <a href="https://twitter.com/isfvideo" title="Follow us on Twitter">
          <span className="fa-stack">
            <i className="fa fa-square fa-stack-2x" />
            <i className="fa fa-twitter fa-stack-1x" />
          </span>
        </a>
        <a href="https://www.facebook.com/ISF-Video-2027304414178146" title="Follow us on Facebook">
          <span className="fa-stack">
            <i className="fa fa-square fa-stack-2x" />
            <i className="fa fa-facebook fa-stack-1x" />
          </span>
        </a>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  location: state.router.location,
});

Footer.propTypes = ({
  location: ReactRouterPropTypes.location.isRequired,
});

export default connect(mapStateToProps)(Footer);
