import React from 'react';
import fps from 'fps';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { error as errorNotification } from 'react-notification-system-redux';
import ReactGA from 'react-ga';
import {
  setFrameRate,
  setThumbnail,
  reportShaderError,
  triggerSnapshot,
  finishSnapshot,
} from '../../actions/shader';
import CustomPropTypes from '../../CustomPropTypes';
import { isNew } from '../../selectors/shader';
import { getOptimizedScale } from '../../selectors';

const ISF = require('interactive-shader-format');
const ISFV1 = require('interactive-shader-format-v1');

class Shader extends React.Component {
  constructor(props) {
    super(props);
    this.ticker = fps({
      every: 30,
    });

    this.renderer = undefined;
  }

  componentDidMount() {
    this.gl = this.canvas.getContext('webgl', { premultipliedAlpha: false });
    this.createRenderer(this.props);
    this._resize();
    this._requestAnimationFrame();
    this.renderShader();
    window.addEventListener('resize', this._resize);

    this.ticker.on('data', this.props.setFrameRate);
  }

  shouldComponentUpdate(newProps, newState) {
    return !!(
      (this.props.optimizedScale !== newProps.optimizedScale)
      || (this.props.shader._id !== newProps.shader._id && !newProps.isNew)
      || (newProps.shader.inputs !== this.props.shader.inputs)
      || (newProps.inputs.values !== this.props.inputs.values)
      || (this.props.shader.parsed !== newProps.shader.parsed)
      || (this.props.shader.fragmentShader !== newProps.shader.fragmentShader)
      || (this.props.shader.vertexShader !== newProps.shader.vertexShader)
    );
  }

  componentDidUpdate(oldProps) {
    const { shader, isNew, inputs } = this.props;
    let dirty = false;

    // if we just got a new shaderid and we aren't on a new shader page,
    // make a new renderer, trigger a render, and trigger a snapshot if appropriate
    if (
      this.props.shader._id
      && oldProps.shader._id !== shader._id
      && !isNew
    ) {
      this.createRenderer(this.props);

      dirty = true;

      // if we have a new id and it doesn't have a thumbnail, take a snapshot and save it to the backend.
      // should we limit this to when the current user is the owner / in admin mode?
      if (oldProps.shader._id
        && oldProps.shader._id !== shader._id
        && !shader.thumbnailCloudinaryId) {
        this.props.triggerSnapshot();
      }
    }

    // if we just got our shader parsed or have a shader update,
    // or if errors were cleared out since the last run,
    // trigger a render
    dirty = dirty || ((oldProps.shader.parsed !== shader.parsed)
      || (oldProps.shader.fragmentShader !== shader.fragmentShader)
      || (oldProps.shader.vertexShader !== shader.vertexShader)
      || (oldProps.shader.error && !shader.error));

    // render if it's been triggered
    if (dirty) {
      this.renderShader();
    }

    // resize the rendering resolution if necessary
    if (this.props.optimizedScale !== oldProps.optimizedScale) {
      this._resize();
    }

    // update shader inputs if necessary
    if (oldProps.shader.inputs !== shader.inputs
      || oldProps.inputs.values !== inputs.values
    ) {
      shader.inputs.forEach((input) => {
        if (this.renderer) this.renderer.setValue(input.NAME, inputs.values[input.NAME] || '');
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._resize);
    this.ticker.removeAllListeners('data');
    // window.cancelAnimationFrame(this._requestID);
  }

  createRenderer = (props) => {
    if (!this.gl) {
      this.renderer = null;
      props.reportShaderError({ message: 'Failed to get WebGL context' });
      return;
    }

    if (props.shader.isfVersion === 2) {
      this.renderer = new ISF.Renderer(this.gl);
    } else {
      this.renderer = new ISFV1.ISFRenderer(this.gl);
    }
  }

  renderError = (err) => {
    this.props.errorNotification({
      title: 'Error in shader compilation.',
      message: 'Check the error trace below.',
      timeout: 0,
    });
    this.props.reportShaderError(err);
  }

  renderShader() {
    const { shader } = this.props;
    const { renderer, renderError } = this;
    if (!shader.parsed) {
      return;
    }
    const { vertexShader, fragmentShader } = shader;
    try {
      if (renderer) {
        // TODO: stop this hacky conversion between shader and isf model
        // if only shader store structure wasn't deeply bound into every component...
        const isfModel = {
          ...shader,
          rawFragmentShader: shader.rawFragmentSource,
          rawVertexShader: shader.rawVertexSource,
        };
        renderer.sourceChanged(fragmentShader, vertexShader, isfModel);
        if (renderer.error) { renderError({ message: renderer.error.message, line: renderer.errorLine }); }
        renderer.setValue('TIME', 0.0);
      }
    } catch (err) {
      renderError(err);
    }
  }

  takeSnapshot() {
    if (this.props.shader.id) {
      const thumbnail = this.canvas.toDataURL('image/jpeg');
      this.props.setThumbnail(thumbnail);
      this.props.finishSnapshot();
    }
  }

  _requestAnimationFrame() {
    this.ticker.tick();
    this._requestID = window.requestAnimationFrame(this._refresh.bind(this));
  }

  _refresh() {
    if (this.renderer && this.renderer.program && this.canvas && !this.props.shader.error) {
      this.renderer.draw(this.canvas);
      if (this.props.shader.snapshotTriggerActive) {
        this.takeSnapshot();
      }

      if (this.props.shader.inputs) {
        this.props.shader.inputs.forEach((input) => {
          if (input.TYPE === 'image' && this.renderer) {
            this.renderer.setValue(input.NAME, this.props.inputs.values[input.NAME]);
          }
        });
      }
    }
    this._requestAnimationFrame();
  }

  _resize = () => {
    this.canvas.width = document.body.offsetWidth * this.props.optimizedScale;
    this.canvas.height = document.body.offsetHeight * this.props.optimizedScale;
    this.canvas.style.width = document.body.offsetWidth;
    this.canvas.style.height = document.body.offsetHeight;
    this.canvas.style.transformOrigin = '0 0';
  }

  render() {
    const { shader } = this.props;
    return (
      <canvas
        ref={(element) => { this.canvas = element; }}
        className={`shader-canvas ${(!shader || !shader.initialized) && 'shader-loading'}`}

      />
    );
  }
}

Shader.defaultProps = ({
  finishSnapshot: () => {},
  optimizedScale: 1,
});

Shader.propTypes = ({
  shader: CustomPropTypes.shader.isRequired,
  setFrameRate: PropTypes.func.isRequired,
  reportShaderError: PropTypes.func.isRequired,
  triggerSnapshot: PropTypes.func.isRequired,
  setThumbnail: PropTypes.func.isRequired,
  finishSnapshot: PropTypes.func,
  optimizedScale: PropTypes.number,
  inputs: CustomPropTypes.inputs.isRequired,
});

const mapStateToProps = state => ({
  shader: state.shader,
  isNew: isNew(state),
  inputs: state.inputs,
  optimizedScale: getOptimizedScale(state),
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    reportShaderError,
    setFrameRate,
    triggerSnapshot,
    setThumbnail,
    finishSnapshot,
    errorNotification,
  }, dispatch)
);

const ConnectedShader = connect(mapStateToProps, mapDispatchToProps)(Shader);

class ShaderWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, info) {
    this.setState({ error: true });
    ReactGA.exception({
      description: `Error rendering shader: ${error} ${info.componentStack}`,
      fatal: false,
    });
  }

  render() {
    if (this.state.error) {
      return <div className="shader-error">Error in shader rendering. Please try reloading the page.</div>;
    }

    return <ConnectedShader {...this.props} />;
  }
}

export default ShaderWrapper;
