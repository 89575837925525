import React from 'react';
import PropTypes from 'prop-types';
import CustomPropTypes from '../../CustomPropTypes';
import ShaderListItem from './ShaderListItem';

const ShaderList = ({
  shaders, handleOpenShader,
}) => (
  <section className="shader-list">
    <ul className="shader-list__items">
      {
        shaders && shaders.length
          ? shaders.map(shader => (
            <ShaderListItem
              key={shader.id}
              shader={shader}
              handleOpenShader={handleOpenShader}
            />
          ))
          : <p>No shaders found.</p>
      }
    </ul>
  </section>
);

ShaderList.propTypes = ({
  shaders: PropTypes.arrayOf(CustomPropTypes.shader),
  handleOpenShader: PropTypes.func,
});

ShaderList.defaultProps = ({
  shaders: [],
  handleOpenShader: () => {},
});


export default ShaderList;
