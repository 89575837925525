import * as ActionTypes from '../constants/ActionTypes';

export const enableWalkthrough = () => ({
  type: ActionTypes.ENABLE_WALKTHROUGH,
});

export const disableWalkthrough = () => ({
  type: ActionTypes.DISABLE_WALKTHROUGH,
});

export const incrementPage = () => ({
  type: ActionTypes.INCREMENT_PAGE,
});

export const decrementPage = () => ({
  type: ActionTypes.DECREMENT_PAGE,
});

export const setWalkthroughMobile = () => ({
  type: ActionTypes.SET_WALKTHROUGH_MOBILE,
});

export const setWalkthroughDesktop = () => ({
  type: ActionTypes.SET_WALKTHROUGH_DESKTOP,
});
