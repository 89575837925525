export const defaultFS = `
/*{
	"DESCRIPTION": "",
	"CREDIT": "",
	"ISFVSN": "2",
	"CATEGORIES": [
		"XXX"
	],
	"INPUTS": [
		{
			"NAME": "inputImage",
			"TYPE": "image"
		},
		{
			"NAME": "boolInput",
			"TYPE": "bool",
			"DEFAULT": 1.0
		},
		{
			"NAME": "colorInput",
			"TYPE": "color",
			"DEFAULT": [
				0.0,
				0.0,
				1.0,
				1.0
			]
		},
		{
			"NAME": "flashInput",
			"TYPE": "event"
		},
		{
			"NAME": "floatInput",
			"TYPE": "float",
			"DEFAULT": 0.5,
			"MIN": 0.0,
			"MAX": 1.0
		},
		{
			"NAME": "longInputIsAPopUpButton",
			"TYPE": "long",
			"VALUES": [
				0,
				1,
				2
			],
			"LABELS": [
				"red",
				"green",
				"blue"
			],
			"DEFAULT": 1
		},
		{
			"NAME": "pointInput",
			"TYPE": "point2D",
			"DEFAULT": [
				0,
				0
			]
		}
	],
	"PASSES": [
		{
			"TARGET":"bufferVariableNameA",
			"WIDTH": "$WIDTH/16.0",
			"HEIGHT": "$HEIGHT/16.0"
		},
		{
			"DESCRIPTION": "this empty pass is rendered at the same rez as whatever you are running the ISF filter at- the previous step rendered an image at one-sixteenth the res, so this step ensures that the output is full-size"
		}
	]
	
}*/

void main()	{
	vec4		inputPixelColor;
	//	both of these are the same
	inputPixelColor = IMG_THIS_PIXEL(inputImage);
	inputPixelColor = IMG_PIXEL(inputImage, gl_FragCoord.xy);
	
	//	both of these are also the same
	inputPixelColor = IMG_NORM_PIXEL(inputImage, isf_FragNormCoord.xy);
	inputPixelColor = IMG_THIS_NORM_PIXEL(inputImage);
	
	gl_FragColor = inputPixelColor;
}
`;

export const defaultVS = `
void main() {
  isf_vertShaderInit();
}
`;

export const filterFS = `
/*{
  "CREDIT": "by You",
  "DESCRIPTION": "",
  "CATEGORIES": [
    "filter"
  ],
  "INPUTS": [
    {
      "NAME": "inputImage",
      "TYPE": "image"
    }
  ]
}*/

void main() {
  gl_FragColor = IMG_NORM_PIXEL(inputImage, isf_FragNormCoord.xy);
}
`;

export const transitionFS = `
/*{
  "CREDIT": "by You",
  "DESCRIPTION": "",
  "CATEGORIES": [
    "transition"
  ],
  "INPUTS": [
    {
      "NAME": "startImage",
      "TYPE": "image"
    },
    {
      "NAME": "endImage",
      "TYPE": "image"
    }
  ]
}*/

void main() {
  gl_FragColor = IMG_NORM_PIXEL(startImage, isf_FragNormCoord.xy);
}
`;

export const generatorFS = `
/*{
  "CREDIT": "by You",
  "DESCRIPTION": "",
  "CATEGORIES": [
    "generator"
  ],
  "INPUTS": [
    {
      "NAME": "colorInput",
      "TYPE": "color",
      "DEFAULT": [
        0.0,
        0.0,
        1.0,
        1.0
      ]
    }
  ]
}*/

void main() {
  gl_FragColor = colorInput;
}
`;
