import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import CustomPropTypes from '../../CustomPropTypes';
import { getFeaturedShader } from '../../actions/shader';
import {
  getFeaturedShaders,
  getRecentShaders,
  getPopularShaders,
} from '../../actions/shaders';
import { featuredShaders, recentShaders, popularShaders } from '../../selectors/shaders';
import TopMenu from '../../components/TopMenu/TopMenu';
import InlineShader from '../../components/InlineShader/InlineShader';
import ShaderList from '../../components/ShaderList/ShaderList';

class Home extends React.Component {
  componentDidMount() {
    const {
      getFeaturedShader,
      getFeaturedShaders,
      getRecentShaders,
      getPopularShaders,
      featuredShaders,
      recentShaders,
      popularShaders,
    } = this.props;
    getFeaturedShader(true);
    if (featuredShaders.length === 0) {
      getFeaturedShaders();
    }
    if (recentShaders.length === 0) {
      getRecentShaders();
    }
    if (popularShaders.length === 0) {
      getPopularShaders();
    }
  }

  render() {
    const {
      featuredShaders,
      recentShaders,
      popularShaders,
      shader: headerShader,
    } = this.props;
    const otherFeaturedShaders = featuredShaders
      .filter(s => s.rawFragmentSource)
      .slice(0, 6);

    const goodRecentShaders = recentShaders
      .filter(s => s.rawFragmentSource)
      .slice(0, 6);

    const goodPopularShaders = popularShaders
      .filter(s => s.rawFragmentSource)
      .slice(0, 6);

    return (
      <section className="home">
        <TopMenu
          menuType="default"
        />
        <div className="home__tagline">
          <h1>
            Create interactive shaders to use
            <br />
            {' '}
            on desktop, mobile and in the browser.
          </h1>
        </div>
        <MediaQuery minWidth={576}>
          {
            headerShader && (
              <div className="home__header-shader">
                <InlineShader />
              </div>
            )
          }
        </MediaQuery>
        <div className="home__description">
          <h2 className="home__description-text">
            Interactive Shader Format (ISF) is a file format used to describe
            GLSL shaders for use in interactive applications. It can be used to
            create image filters or generative video sources.
          </h2>
          <div className="home__row">
            <Link className="home__description-about" to="/about">
              How it Works
            </Link>
            <a className="home__description-quickstart" href="http://docs.isf.video/quickstart">
              Quickstart
            </a>
          </div>
        </div>
        <div className="home__other-shaders">
          <ShaderList shaders={otherFeaturedShaders} />
        </div>
        <div className="home__row">
          <div className="home__browse-container">
            <Link className="home__browse-button" to="/shaders">
              Browse More Shaders
            </Link>
          </div>
        </div>
        <div className="home__other-shaders">
          <ShaderList shaders={goodRecentShaders} />
        </div>
        <div className="home__row">
          <div className="home__browse-container">
            <Link className="home__browse-button" to="/shaders?q=&category=&sort=Date+Created+↓&from=0">
              Browse Recent Shaders
            </Link>
          </div>
        </div>
        <div className="home__other-shaders">
          <ShaderList shaders={goodPopularShaders} />
        </div>
        <div className="home__row">
          <div className="home__browse-container">
            <Link className="home__browse-button" to="/shaders?q=&category=&sort=Popularity+↓&from=0">
              Browse Popular Shaders
            </Link>
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    shader: state.shader,
    featuredShaders: featuredShaders(state),
    recentShaders: recentShaders(state),
    popularShaders: popularShaders(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getFeaturedShader,
    getFeaturedShaders,
    getRecentShaders,
    getPopularShaders,
  }, dispatch);
}

Home.propTypes = ({
  featuredShaders: PropTypes.arrayOf(CustomPropTypes.shader).isRequired,
  shader: CustomPropTypes.shader.isRequired,
  getFeaturedShader: PropTypes.func.isRequired,
  getFeaturedShaders: PropTypes.func.isRequired,
  getRecentShaders: PropTypes.func.isRequired,
  getPopularShaders: PropTypes.func.isRequired,
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
