import React, { Fragment } from 'react';
import UserLinks from './UserLinks';

const NavLinks = props => (
  <Fragment>
    <ul className="secondary">
      <li><a href="https://isf.video/" target="_blank" rel="noopener noreferrer" title="">About ISF</a></li>
      <li className="hide-on-mobile"><a href="https://docs.isf.video/quickstart.html" target="_blank" rel="noopener noreferrer" title="">Quickstart</a></li>
      <li><a href="https://docs.isf.video/" target="_blank" rel="noopener noreferrer" title="">Docs</a></li>
    </ul>

    <ul>
      <li><a href="/shaders" title=""><i className="fa fa-search" /> <span className="hide-on-mobile">Find</span></a></li>
      <li><a href="/shaders?q=&category=&sort=Date+Created+%E2%86%93&page=0" title=""><i className="fa fa-history" /> <span className="hide-on-mobile">Recent</span></a></li>
      <li><a href="/shaders/new" title=""><i className="fa fa-file-plus" /> <span className="hide-on-mobile">New</span></a></li>
      <UserLinks />
    </ul>
  </Fragment>
);

export default NavLinks;

