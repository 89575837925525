import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import qs from 'qs';

import * as Constants from '../../constants/ActionTypes';
import { validateAndLoginUser } from '../../actions/user';
import LoginForm from '../../components/LoginForms/LoginForm';
import SocialLoginButtons from '../../components/LoginForms/SocialLoginButtons';
import TopMenu from '../../components/TopMenu/TopMenu';

class Login extends React.Component {
  render() {
    const { pendingAction, error } = qs.parse(this.props.location.search.substring(1));
    let title = '';
    switch (pendingAction) {
      case Constants.PENDING_CREATE:
        title = 'Log in to save shader';
        break;
      case Constants.PENDING_FORK:
        title = 'Log in to fork shader';
        break;
      case Constants.STAR_SHADER:
        title = 'Log in to star shaders';
        break;
      default:
        title = 'Log in';
    }

    let errorMessage;
    if (error === 'facebook_auth') {
      errorMessage = `
  There was an error when signing in with Facebook.
  There may already be an account using that Facebook account's email address.
        `;
    }
    if (error === 'twitter_auth') {
      errorMessage = `
  There was an error when signing in with Twitter.
  There may already be an account using that Twitter account's email address.
        `;
    }

    return (
      <section className="login">
        <TopMenu
          menuType="default"
        />
        <div className="login__main">
          <h1 className="login__header">
            { title }
          </h1>
          <Link className="login__alternate-link" to="/signup">
            Or sign up for a new account
          </Link>
          {
            errorMessage &&
              <h2 className="login__error">
                {errorMessage}
              </h2>
          }
          <LoginForm
            onSubmit={this.props.validateAndLoginUser}
          />
          <div className="login__social-pre">
            Or sign up with
          </div>
          <SocialLoginButtons />
          <Link className="login__reset-link" to="/resetPasswordRequest">
            Reset Password
          </Link>
        </div>
      </section>
    );
  }
}

function mapStateToProps() {
  return {
  };
}

const mapDispatchToProps = (
  dispatch => bindActionCreators(
    { validateAndLoginUser }, dispatch,
  )
);

Login.propTypes = ({
  validateAndLoginUser: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
