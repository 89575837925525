import React from 'react';
import PropTypes from 'prop-types';
import CustomPropTypes from '../../CustomPropTypes';

const ExternalAccounts = ({
  user: { facebook, twitter },
  unlinkSocialAccount,
}) => (
  <section className="settings__external-accounts">
    { !twitter
      && (
        <a className="settings__link-button" href="/auth/twitter">
          <img alt="Twitter logo" src="twitter.svg" height="24" />
          <span>
            Link Twitter
          </span>
        </a>
      )
    }
    { twitter
      && (
        <button
          className="settings__link-button"
          onClick={() => unlinkSocialAccount(this, 'twitter')}
          type="button"
        >
          <img alt="Twitter logo" src="twitter.svg" height="24" />
          <span>
            Unlink Twitter
          </span>
        </button>
      )
    }
    { !facebook
      && (
        <a className="settings__link-button" href="/auth/facebook">
          <img alt="Facebook logo" src="facebook.svg" height="24" />
          <span>
            Link Facebook
          </span>
        </a>
      )
    }
    { facebook
      && (
        <button
          className="settings__link-button"
          onClick={() => unlinkSocialAccount(this, 'facebook')}
          type="button"
        >
          <img alt="Facebook logo" src="facebook.svg" height="24" />
          <span>
            Unlink Facebook
          </span>
        </button>
      )
    }
  </section>
);

ExternalAccounts.propTypes = ({
  user: CustomPropTypes.user.isRequired,
  unlinkSocialAccount: PropTypes.func.isRequired,
});

export default ExternalAccounts;
