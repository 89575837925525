import PropTypes from 'prop-types';

const shader = PropTypes.shape({
  _id: PropTypes.string,
  thumbnailCloudinaryId: PropTypes.string,
  title: PropTypes.string,
  username: PropTypes.string,
  initialized: PropTypes.bool,
  rawFragmentSource: PropTypes.string,
  rawVertexSource: PropTypes.string,
  images: PropTypes.array,
});

const inputs = PropTypes.shape({
  controlsInitialized: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
});

const user = PropTypes.shape({
  _id: PropTypes.string,
  id: PropTypes.string,
  username: PropTypes.string,
  email: PropTypes.string,
  name: PropTypes.string,
  imageCloudinaryId: PropTypes.string,
  bio: PropTypes.string,
  website: PropTypes.string,
  image: PropTypes.string,
  stars: PropTypes.array,
  authenticated: PropTypes.bool,
});

const profile = PropTypes.shape({
  shaders: PropTypes.arrayOf(shader).isRequired,
  _id: PropTypes.string,
  username: PropTypes.string,
  email: PropTypes.string,
  name: PropTypes.string,
  imageCloudinaryId: PropTypes.string,
  bio: PropTypes.string,
  website: PropTypes.string,
  image: PropTypes.string,
});

const search = PropTypes.shape({
  shaders: PropTypes.arrayOf(shader).isRequired,
  numberOfResults: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
});


const categories = PropTypes.arrayOf(PropTypes.object.isRequired);

const walkthrough = PropTypes.shape({
  enabled: PropTypes.bool.isRequired,
  pageIndex: PropTypes.number.isRequired,
  mobile: PropTypes.bool.isRequired,
});

const CustomPropTypes = ({
  shader,
  inputs,
  user,
  profile,
  search,
  categories,
  walkthrough,
});

export default CustomPropTypes;
