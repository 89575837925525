import * as Walkthrough from '../constants/walkthrough';

const currentPageList = (mobile => (mobile ? Walkthrough.MOBILE_PAGES : Walkthrough.DESKTOP_PAGES)
);

export const currentPage = ({ walkthrough: { mobile, pageIndex } }) => (
  currentPageList(mobile)[pageIndex]
);

export const isLastPage = ({ walkthrough: { mobile, pageIndex } }) => (
  currentPageList(mobile).length === pageIndex + 1
);
