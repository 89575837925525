import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import CustomPropTypes from '../../CustomPropTypes';
import ControlsTab from '../../components/Controls/ControlsTab';
import Shader from '../../components/Shader/Shader';
import CodeTab from '../../components/CodePane/CodeTab';
import ShaderMessageTab from '../../components/ShaderMessage/ShaderMessageTab';
import RelatedShaders from '../../components/RelatedShaders/RelatedShaders';
import WalkthroughTab from '../../components/WalkthroughOverlay/WalkthroughOverlayTab';
import TopMenu from '../../components/TopMenu/TopMenu';
import * as Walkthrough from '../../constants/walkthrough';

class DesktopShaderView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      relatedShaders: false,
    };
  }

  hideAll = () => {
    this.setState({
      relatedShaders: false,
    });
  }


  render() {
    const walkthroughPage = (
      this.props.walkthroughEnabled ? this.props.walkthroughPage : -1
    );
    return (
      <Fragment>
        <TopMenu
          menuType={this.state.relatedShaders === 'expanded' ? 'relatedShaders' : 'shader'}
        />
        <Shader />
        <ControlsTab
          hidden={this.state.relatedShaders === 'expanded'}
          highlighted={
            walkthroughPage === Walkthrough.DESKTOP_CONTROLS_PAGE
          }
          expandKey={'k'}
        />
        <CodeTab
          hidden={this.state.relatedShaders === 'expanded'}
          shader={this.props.shader}
          highlighted={
            walkthroughPage === Walkthrough.DESKTOP_CODE_TAB_PAGE
          }
          expandKey={'b'}
        />
        <ShaderMessageTab
          error={
            this.props.shader.error
              || (
                (walkthroughPage === Walkthrough.DESKTOP_CONSOLE_PAGE)
                  ? { message: 'Here\'s where you\'d see shader debug information.' }
                  : {}
              )
          }
          warning={this.props.shader.warning}
          message={this.props.shader.error && this.props.shader.error.message}
          expandKey={'i'}
          highlighted={
            walkthroughPage === Walkthrough.DESKTOP_CONSOLE_PAGE
          }
        />
        { (
          !this.props.isNew
        ) && (
          <RelatedShaders
            resetView={this.hideAll}
          />
        )
        }
        <WalkthroughTab highlighted={this.props.walkthroughEnabled} />
      </Fragment>);
  }
}

DesktopShaderView.propTypes = ({
  shader: CustomPropTypes.shader.isRequired,
  isNew: PropTypes.bool.isRequired,
  walkthroughEnabled: PropTypes.bool.isRequired,
});

export default DesktopShaderView;
