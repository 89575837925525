const ISF = require('interactive-shader-format');
const ISFV1 = require('interactive-shader-format-v1');

const inputToDefault = (input) => {
  switch (input.TYPE) {
    case 'float':
      return input.DEFAULT || 0;
    case 'image':
      return {};
    case 'event':
      return false;
    case 'bool':
      return !!input.DEFAULT || false;
    case 'long':
      return input.DEFAULT || 0;
    case 'color':
      return input.DEFAULT || [1.0, 1.0, 1.0, 1.0];
    case 'point2D':
      return input.DEFAULT || [0.0, 0.0];
    default:
      console.log(`Unknown input type for ISFModel.inputToDefault ${input.TYPE}`); // eslint-disable-line
      return 0;
  }
};

const upgrader = ISF.Upgrader;

export const upgradeShader = (shaderJSON) => {
  const rawFragmentSource = upgrader.convertFragment(shaderJSON.rawFragmentSource);
  const rawVertexSource = upgrader.convertVertex(shaderJSON.rawVertexSource);
  return { ...shaderJSON, rawFragmentSource, rawVertexSource, dirty: true, fragmentEdited: true, vertexEdited: true };
};

export const parseShader = (shaderJSON) => {
  if (!shaderJSON.initialized) return shaderJSON;
  const shader = Object.assign({}, shaderJSON);
  let parser = new ISF.Parser();

  parser.parse(shader.rawFragmentSource, shader.rawVertexSource);
  if (!parser.error && parser.isfVersion !== 2) {
    parser = new ISFV1.ISFParser();
    parser.parse(shader.rawFragmentSource, shader.rawVertexSource);
  }
  if (parser.error) {
    parser.valid = false;
    shader.valid = false;
    shader.error = parser.error;
    return shader;
  }

  shader.warning = undefined;
  if (shader.rawFragmentSource.indexOf('texture2D(') !== -1) {
    shader.warning = 'Please use IMG_PIXEL or IMG_NORM_PIXEL instead of texture2D or texture2DRect';
  }

  for (const key in parser.imports) {
    if (parser.imports.hasOwnProperty(key)) {
      parser.inputs.push({ NAME: key, TYPE: 'image' });
    }
  }
  shader.error = null;
  shader.valid = parser.valid;
  shader.vertexShader = parser.vertexShader;
  shader.fragmentShader = parser.fragmentShader;
  shader.passes = parser.passes;
  shader.defaultInputs = {};
  parser.inputs.forEach((input) => {
    input.DEFAULT = inputToDefault(input);
    shader.defaultInputs[input.NAME] = inputToDefault(input);
  });
  shader.inputs = parser.inputs;
  shader.imports = parser.imports;
  // shader.images = [...shader.images, ...parser.imports];
  shader.description = parser.description;
  shader.isfVersion = parser.isfVersion;
  shader.error = undefined;
  shader.parsed = true;
  return shader;
};
