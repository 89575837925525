import * as ActionTypes from '../constants/ActionTypes';

const getUpdate = (type, value) => {
  // handle image, need to create <img> or <video> and
  // set value accordingly
  let update;
  if (type === 'color') {
    const newColor = [
      value[0] / 255,
      value[1] / 255,
      value[2] / 255,
      value[3],
    ];
    update = newColor;
  } else if (type === 'image' || type === 'video') {
    update = value.domElement;
  } else {
    update = value;
  }
  return update;
};

export const updateControl = (name, type, value) => (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_CONTROLS,
    payload: { [name]: getUpdate(type, value) },
  });
};

export const updateControls = updates => (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_CONTROLS,
    payload: updates,
  });
};
