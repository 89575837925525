import React from 'react';
import PropTypes from 'prop-types';
import CustomPropTypes from '../../CustomPropTypes';
import AccountForm from './AccountForm';

const deleteCopy = `Are you sure you want to delete your account? 
This will permanently delete your account and all of your shaders.
It cannot be reversed!`;

class Account extends React.Component {
  // @TODO replace with modal components
  confirmDelete = () => {
    if (window.confirm(deleteCopy)) {
      if (window.confirm('Are you really sure you want to delete your account?')) {
        this.props.deleteAccount();
      }
    }
  }

  render() {
    return (
      <section className="settings__account">
        <AccountForm
          onSubmit={this.props.updateSettings}
          user={this.props.user}
        />
        <div className="settings__account-delete">
          <button
            className="settings__account-delete-button"
            onClick={this.confirmDelete}
            type="button"
          >
            Delete Account
          </button>
        </div>
      </section>
    );
  }
}

Account.propTypes = ({
  user: CustomPropTypes.user.isRequired,
  updateSettings: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
});

export default Account;
