import React from 'react';
import InlineSVG from 'react-inlinesvg';
import thinX from '../../assets/thin-x.svg';

/* eslint-disable */
import * as Walkthrough from '../../constants/walkthrough';
/* eslint-enable */

const introPage = (
  <div>
    <p>Welcome to the ISF shader editor.</p>
    <p>Let's take a look at the editor's features.</p>
    <p>Click <i className="fa fa-arrow-right" /> to continue.</p>
    <p>You can click <InlineSVG src={thinX} /> at any time to exit the tutorial.</p>
  </div>);

const consolePage = (
  <div>
    <p>The console <i className="fa fa-terminal" /> will
      display any errors in compiling or running your shader.</p>
    <p>
      It will also show any errors in parsing your ISF metadata to create shader controls.
    </p>
  </div>);

const outroPage = (
  <div>
    <p>
      That's it!
    </p>
    <p>
      Now you can get started creating and sharing ISF-enabled shaders.
    </p>
    <p>
      Click <i className="fa fa-check" /> to exit the tutorial.
    </p>
  </div>);

const walkthroughContent = {
  [Walkthrough.DESKTOP_INTRO_PAGE]: introPage,
  [Walkthrough.DESKTOP_CODE_TAB_PAGE]: (
    <div>
      <p>The code tab <i className="fa fa-code" /> may be used to edit the fragment and vertex sources for the shader.</p>
      <p>Click <i className="fa fa-refresh" /> to compile and run a modified shader.</p>
      <p>Click <i className="fa fa-expand" /> to toggle the code editor's fullscreen mode.</p>
      <p>The <i className="fa fa-eye-slash" /> slider will change the code editor's transparency.</p>
    </div>),
  [Walkthrough.DESKTOP_CONTROLS_PAGE]: (
    <div>
      <p> The input tab <i className="fa fa-sliders" /> may be used to manipulate the uniforms your shaders define.</p>
      <p>Different uniform types, such as image buffers, floats or 2D vectors, will create different controls.</p>
      <p>You can manipulate these controls to experiment with your shader's parameters or create a live performance.</p>
      <p>Click <i className="fa fa-cog" /> to change the resolution of your shader. A lower resolution can render at a higher framerate, while a resolution higher than your screen can create a supersampling effect.</p>
      <p>Once you've saved your shader, click <i className="fa fa-link" /> to share your creation!</p>
      <p />
    </div>
  ),
  [Walkthrough.DESKTOP_CONSOLE_PAGE]: consolePage,
  [Walkthrough.DESKTOP_OUTRO_PAGE]: outroPage,
  [Walkthrough.MOBILE_INTRO_PAGE]: introPage,
  [Walkthrough.MOBILE_CODE_TAB_PAGE]: (
    <div>
      <p>The code tab <i className="fa fa-code" /> may be used to edit the fragment and vertex sources for the shader.</p>
      <p>Click <i className="fa fa-refresh" /> to compile and run a modified shader.</p>
    </div>),
  [Walkthrough.MOBILE_CONTROLS_PAGE]: (
    <div>
      <p> The input tab <i className="fa fa-sliders" /> may be used to manipulate the uniforms your shaders define.</p>
      <p>Different uniform types, such as image buffers, floats or 2D vectors, will create different controls.</p>
      <p>You can manipulate these controls to experiment with your shader's parameters or create a live performance.</p>
    </div>
  ),
  [Walkthrough.MOBILE_CONSOLE_PAGE]: consolePage,
  [Walkthrough.MOBILE_RELATED_SHADERS_PAGE]: (
    <div>
      <p>
        If you're viewing a saved shader,
        you can swipe to the left to view similar, related shaders.
      </p>
      <p>
        Try it now!
      </p>
    </div>
  ),
  [Walkthrough.MOBILE_OUTRO_PAGE]: outroPage,
};

export default walkthroughContent;
