import React from 'react';
import PropTypes from 'prop-types';
import InlineSVG from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MediaQuery from 'react-responsive';

import { searchShaders } from '../../actions/search';
import logo from '../../assets/isf-logo.svg';
import textLogo from '../../assets/ISF.svg';
import ShaderButtons from './ShaderButtons';
import NavLinks from './NavLinks';
import { logoutUser } from '../../actions/user';
import { isNew } from '../../selectors/shader';
import CustomPropTypes from '../../CustomPropTypes';

class TopMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  expandMenu = () => {
    this.setState({ expanded: true });
  }

  contractMenu = () => {
    this.setState({ expanded: false });
  }

  onSubmitSearchSuccess = (result, dispatch, props) => {
    this.props.push({
      pathname: '/shaders',
    });
  }

  onSubmitSearch = () => {
    this.contractMenu();
  }

  render() {
    const { menuType, isNew } = this.props;
    const shaderMenu = menuType === 'shader';
    const relatedShadersMenu = menuType === 'relatedShaders';
    const topNavClass = classNames({
      'main-nav': true,
      'main-nav--expanded': this.state.expanded,
      'main-nav--unexpanded': !this.state.expanded,
      'main-nav--related-shaders': relatedShadersMenu,
    });

    return (
      <nav className={topNavClass}>
        <div className="isf-logo">
          <Link to="/" title="ISF Homepage">
            <InlineSVG className="main-nav__logo" src={logo} />
            <InlineSVG className="main-nav__logo" src={textLogo} />
          </Link>
        </div>
        <div className="nav-links">
          <NavLinks />
        </div>
        {
          (shaderMenu && !isNew) && (
            <div className="shader-buttons">
              <MediaQuery minWidth={576}>
                <ShaderButtons />
              </MediaQuery>
            </div>
          )
        }
      </nav>
    );
  }
}

const mapStateToProps = state => ({
  isNew: isNew(state),
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  doLogout: () => dispatch(logoutUser()),
  ...(bindActionCreators({
    searchShaders,
    push,
  }, dispatch)),
});

TopMenu.defaultProps = ({
  menuType: 'default',
});

TopMenu.propTypes = ({
  user: CustomPropTypes.user.isRequired,
  push: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  doLogout: PropTypes.func.isRequired,
  menuType: PropTypes.string,
});

export default (connect(mapStateToProps, mapDispatchToProps)(TopMenu));
