import * as ActionTypes from '../constants/ActionTypes';

const initialState = () => [];

const popularShaders = (state, action) => {
  if (state === undefined) {
    state = initialState();
  }

  switch (action.type) {
    case ActionTypes.SET_POPULAR_SHADERS:
      return action.payload;
    default:
      return state;
  }
};

export default popularShaders;
