import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import dat from 'dat.gui/build/dat.gui';
import { error as errorNotification } from 'react-notification-system-redux';
import { addImage } from '../../actions/shader';
import { updateControls, updateControl } from '../../actions/inputs';
import CustomPropTypes from '../../CustomPropTypes';
import cloudinary from '../../utils/cloudinaryConfig';

// Note that in the user-facing UI, controls are referred to as 'inputs'

class Controls extends React.Component {
  constructor(props) {
    super(props);
    this.gui = new dat.GUI({ autoPlace: false });
    this.state = { hasError: false };
  }

  componentDidMount() {
    this.guiContainer.appendChild(this.gui.domElement);
    this.createControls();
  }

  componentDidUpdate(oldProps) {
    if (
      (oldProps.inputs.controlsInitialized !== this.props.inputs.controlsInitialized)
      || (!oldProps.shader.parsed && this.props.shader.parsed)
      || (this.props.shader.inputs && !oldProps.shader.inputs)
      || (this.props.shader._id !== oldProps.shader._id)
      || (oldProps.shader.rawFragmentSource !== this.props.shader.rawFragmentSource)
      || (oldProps.shader.rawVertexSource !== this.props.shader.rawVertexSource)
      || (oldProps.shader.rawVertexSource !== this.props.shader.rawVertexSource)
    ) {
      this.createControls();
    }
  }

  // should this be added as a method to dat.gui instead of here?
  // like why does dat.gui not have a remove all controls method
  // i feel like it is very obvious that it is wanted
  destroyControls = () => {
    const controllers = [...this.gui.__controllers];
    controllers.forEach((controller) => {
      this.gui.remove(controller);
    });
    Object.keys(this.gui.__folders).forEach((folderName) => {
      const folder = this.gui.__folders[folderName];
      const folderControllers = [...folder.__controllers];
      folderControllers.forEach((controller) => {
        folder.remove(controller);
      });
      folder.close();
      this.gui.__ul.removeChild(folder.domElement.parentNode);
      delete this.gui.__folders[folderName];
      this.gui.onResize();
    });
  }

  createControls = () => {
    try {
      this.destroyControls();
      if (this.props.shader.defaultInputs && !this.props.inputs.controlsInitialized) {
        // initialize controls with default values and return
        return this.props.updateControls(this.props.shader.defaultInputs);
      }
      const controls = this.props.shader.inputs;
      if (!controls || !this.props.inputs.controlsInitialized) return;

      const { images, imports } = this.props.shader;
      controls.forEach((control) => {
        const { TYPE = '', NAME = '', LABEL = '', LABELS = [], VALUES = [], DEFAULT = 0 } = control;
        if (!NAME) {
          console.error(`No name for control ${control}`); // eslint-disable-line no-console
          return;
        }
        switch (TYPE) {
          case 'color': {
            const color = this.props.inputs.values[NAME];
            color[0] *= 255;
            color[1] *= 255;
            color[2] *= 255;
            const controller = this.gui.addColor({
              [NAME]: color,
            }, NAME);
            // updateColor normalizes color value back to 0-1 range
            this.props.updateControl(NAME, TYPE, color);
            if (LABEL) {
              controller.name(LABEL);
            }
            controller.onChange((value) => {
              this.props.updateControl(NAME, TYPE, value);
            });
          } break;
          case 'long': {
            const values = {};
            LABELS.forEach((label, index) => {
              values[label] = VALUES[index];
            });
            const controller = this.gui.add({
              [NAME]: this.props.inputs.values[NAME],
            }, NAME, values);
            if (LABEL) {
              controller.name(LABEL);
            }
            controller.onChange((value) => {
              this.props.updateControl(NAME, TYPE, value);
            });
          } break;
          case 'float': {
            const { MIN = 0, MAX = 1 } = control;
            const controller = this.gui.add({
              [NAME]: this.props.inputs.values[NAME],
            }, NAME, MIN, MAX);
            controller.onChange((value) => {
              this.props.updateControl(NAME, TYPE, value);
            });
          } break;
          case 'point2D': {
            const folder = this.gui.addFolder(NAME);
            folder.open();
            const { MIN = [0, 0], MAX = [1, 1] } = control;

            const controller = folder.add({
              [NAME]: this.props.inputs.values[NAME],
            }, NAME, MIN, MAX);
            const sliderFolder = folder.addFolder('Sliders');
            const controllerX = sliderFolder.add({
              x: this.props.inputs.values[NAME][0],
            }, 'x', MIN[0], MAX[0]);
            const controllerY = sliderFolder.add({
              y: this.props.inputs.values[NAME][1],
            }, 'y', MIN[1], MAX[1]);

            // @TODO: is a dumb lock the best thing here?
            let lock = false;
            controller.onChange((value) => {
              if (!lock) {
                lock = true;
                this.props.updateControl(NAME, TYPE, value);
                controllerX.setValue(value[0]);
                controllerY.setValue(value[1]);
                lock = false;
              }
            });
            controllerX.onChange((value) => {
              if (!lock) {
                lock = true;
                const newState = [value, controllerY.getValue()];
                this.props.updateControl(NAME, TYPE, newState);
                controller.setValue(newState);
                controllerY.setValue(newState[1]);
                lock = false;
              }
            });
            controllerY.onChange((value) => {
              if (!lock) {
                lock = true;
                const newState = [controllerX.getValue(), value];
                this.props.updateControl(NAME, TYPE, newState);
                controller.setValue(newState);
                controllerX.setValue(newState[0]);
                lock = false;
              }
            });
          } break;
          case 'bool': {
            const controller = this.gui.add({
              [NAME]: this.props.inputs.values[NAME],
            }, NAME, DEFAULT);
            controller.onChange((value) => {
              this.props.updateControl(NAME, TYPE, value);
            });
          } break;
          case 'event':
            this.gui.add({
              [NAME]: () => {
                this.props.updateControl(NAME, TYPE, true);
                setTimeout(() => {
                  this.props.updateControl(NAME, TYPE, false);
                }, 10);
              },
            }, NAME);
            break;
          case 'video':
          case 'image': {
            const selectedImage = images.find(image => image.name === NAME) || {};
            const imageUrl = selectedImage.blobUrl || cloudinary.url(selectedImage.cloudinaryId) || '/bee.jpg';
            const videoSrc = selectedImage.swatchUrl;
            const isImport = !!imports[NAME];
            let controlType;
            if (selectedImage.animated) {
              if (selectedImage.swatchUrl) {
                controlType = 'video';
              } else if (!selectedImage.blobUrl && !selectedImage.cloudinaryId && !videoSrc) {
                // not sure if this case can happen, as it's currently impossible
                // to save a shader with controls set to webcam
                controlType = 'video-stream';
              } else {
                controlType = 'gif';
              }
            } else {
              controlType = 'image';
            }
            const controlValue = {
              url: controlType === 'image' || controlType === 'gif' ? imageUrl : videoSrc,
              type: controlType,
            };
            const controller = this.gui.addImage({
              [NAME]: controlValue,
            }, NAME, {
              defaults: isImport ? [] : [
                {
                  src: '/gif1.gif',
                  videoSrc: '/gif1_td81a7.mov',
                },
                {
                  src: '/gif2.gif',
                  videoSrc: '/gif2_twfrnl.mov',
                },
                {
                  src: '/gif3.gif',
                  videoSrc: '/gif3_mtmo4k.mov',
                },
              ],
              disableVideo: isImport,
            });
            const value = controller.getValue();
            this.props.updateControl(NAME, TYPE, value);
            // need to do this to set the DOM element
            controller.onChange((changedValue) => {
              // check if src is a blob url
              // if it is then add a blobUrl parameter
              // else add the cloudinaryId
              // and swatcUrl
              // and animated
              // and shaderId
              const REGEXP_BLOB_URL = /^blob:.+\/[\w-]{36,}(#.+)?$/;
              const isBlobUrl = REGEXP_BLOB_URL.test(changedValue.url);

              // if it's a webcam stream, simply update the control
              // otherwise, add a new image to reference
              if (
                changedValue.type === 'image' ||
                changedValue.type === 'gif' ||
                changedValue.type === 'video'
              ) {
                this.props.addImage({
                  name: NAME,
                  blobUrl: isBlobUrl ? changedValue.url : undefined,
                  swatchUrl: changedValue.type === 'video' ? changedValue.url : undefined,
                  animated: changedValue.type !== 'image',
                  shader: this.props.shader.id,
                });
              }
              this.props.updateControl(NAME, TYPE, changedValue);
            });
          } break;
          default: {
            const controller = this.gui.add({
              [NAME]: this.props.inputs.values[NAME],
            }, NAME, DEFAULT);
            if (LABEL) {
              controller.name(LABEL);
            }
            controller.onChange((value) => {
              this.props.updateControl(NAME, TYPE, value);
            });
          } break;
        }
      });
    } catch (error) {
      this.props.errorNotification({
        title: 'Error in rendering shader controls.',
        message: error.message,
        timeout: 0,
      });
      this.setState({
        hasError: true,
      });
    }
  }

  componentWillUnmount() {
    this.destroyControls();
  }

  render() {
    /*
     * const controlsClass = classNames({
      controls: true,
      'controls--expanded': this.props.expanded,
      'controls--hidden': this.props.hidden,
    });
    */
    return (
      <div className="dat-gui-wrapper">
        <h2 className="dat-gui-header">
          Input
        </h2>
        <div
          className="dat-gui-container"
          ref={(element) => { this.guiContainer = element; }}
        />
      </div>
    );
  }
}

Controls.propTypes = ({
  addImage: PropTypes.func.isRequired,
  shader: CustomPropTypes.shader.isRequired,
  inputs: CustomPropTypes.inputs.isRequired,
  updateControls: PropTypes.func.isRequired,
  updateControl: PropTypes.func.isRequired,
  errorNotification: PropTypes.func.isRequired,
});

const mapStateToProps = state => ({
  shader: state.shader,
  inputs: state.inputs,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updateControls,
  updateControl,
  addImage,
  errorNotification,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Controls);
