import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import CustomPropTypes from '../../CustomPropTypes';

const TestHomeView = ({ user }) => (
  <section className="home">
    <div>
      <Link to="/shaders">
        View Shaders
      </Link>
    </div>
    <div>
      <Link to="/shaders/new?type=filter">
        Create a new filter shader
      </Link>
      <Link to="/shaders/new?type=generator">
        Create a new generator shader
      </Link>
      <Link to="/shaders/new?type=transition">
        Create a new transition shader
      </Link>
    </div>
    { user.authenticated
      ? (
        <div>
          <Link to="/login">
            Login
          </Link>
        &nbsp;or&nbsp;
          <Link to="/signup">
            Signup
          </Link>
        </div>
      )
      : (
        <span>
          {`Hello, ${user.username}!`}
        </span>
      )
    }

  </section>
);

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

TestHomeView.propTypes = {
  user: CustomPropTypes.user.isRequired,
};

export default connect(mapStateToProps)(TestHomeView);
