import {
  error as errorNotification,
} from 'react-notification-system-redux';

export const handler = dispatch => (error) => {
  let title = 'Error';
  let message = 'Please try this action again';
  const { response, response: { status, data } } = error;
  if (response) {
    if (status === 404) {
      title = 'Content not found';
      message = 'Check that the URL you entered is correct';
    }
    if (status === 422) {
      title = 'Submission error';
      message = data.message;
    } else if (status >= 500) {
      title = 'ISF server error';
    } else if (status >= 400) {
      title = 'Submission error';
    }
    message = data && typeof data.error === 'string'
      ? data.error
      : message;
  } else if (error.request) {
    title = 'Error contacting ISF server';
  }

  dispatch(errorNotification({
    title,
    message: message.toString(),
    timeout: 10,
  }));
  console.error('Error:', title, message); // eslint-disable-line
};
