import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Notifications from 'react-notification-system-redux';

// @TODO can we hook this directly into scss vars
// or replace react-notification-system's inline styles fully
const style = {
  NotificationItem: {
    DefaultStyle: {
      border: '1px solid #212121',
      boxShadow: 'none',
      WebkitBoxShadow: 'none',
      MozBoxShadow: 'none',
      color: '#b9b9b9',
      backgroundColor: '#363636',
      fontWeight: 'normal',
    },
    error: {
      color: '#DC1756',
    },
  },
  Title: {
    DefaultStyle: {
      fontWeight: 'normal',
      color: '#fff',
    },
    success: {
      color: '#02ff3f',
    },
    error: {
      color: '#DC1756',
    },
  },
  Dismiss: {
    DefaultStyle: {
      backgroundColor: 'rgba(0,0,0,0)',
      borderRadius: 0,
      fontWeight: 'normal',
    },
  },
};

const NotificationDisplay = ({ notifications }) => (
  <Notifications
    notifications={notifications}
    style={style}
    className="notifications"
  />
);

NotificationDisplay.propTypes = {
  notifications: PropTypes.array,
};

export default connect(
  ({ notifications }) => ({ notifications }),
)(NotificationDisplay);
