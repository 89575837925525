import React from 'react';
import PropTypes from 'prop-types';

class Toggle extends React.Component {
  handleToggle = e => this.props.setValue(!!e.target.checked)

  render() {
    const { value, title, name } = this.props;
    return (
      <div className="toggle">
        <label htmlFor={name} className="toggle__label">
          {title}
        </label>
        <input
          type="checkbox"
          id={name}
          onChange={this.handleToggle}
          checked={value}
        />
      </div>
    );
  }
}

Toggle.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default Toggle;
