import React from 'react';
import PropTypes from 'prop-types';

const ShadersHeader = ({ resultsLength, query }) => {
  let header = 'Browse shaders';
  let description = resultsLength && resultsLength > 0
    ? `${resultsLength} shaders indexed`
    : 'Loading...';
  if (query) {
    header = `Search results for "${query}"`;
    description = `${resultsLength} results found`;
  }

  return (
    <header className="shaders-header">
      <h1>{header}</h1>
      <p>{description}</p>
    </header>
  );
};

ShadersHeader.propTypes = ({
  query: PropTypes.string.isRequired,
  resultsLength: PropTypes.number.isRequired,
});

export default ShadersHeader;
