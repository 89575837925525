import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { change } from 'redux-form';
import {
  searchShaders,
  incrementSearchPage,
  decrementSearchPage,
  resetSearchPage,
} from '../../actions/search';
import { getCategories } from '../../actions/categories';
import CustomPropTypes from '../../CustomPropTypes';
import TopMenu from '../../components/TopMenu/TopMenu';
import ShaderList from '../../components/ShaderList/ShaderList';
import ShadersFilters from '../../components/ShadersFilters/ShadersFilters';
import ShadersHeader from '../../components/ShadersHeader/ShadersHeader';
import Paginator from '../../components/Paginator/Paginator';
import { PAGE_SIZE } from '../../constants/pagination';


class Shaders extends React.Component {
  componentDidMount() {
    const {
      location,
      changeQueryValue,
      changeSortValue,
      changeCategoryValue,
      resetSearchPage,
    } = this.props;
    const sp = new URLSearchParams(location.search);
    if (sp.get('q')) changeQueryValue(sp.get('q'));
    if (sp.get('sort')) changeSortValue(sp.get('sort'));
    if (sp.get('category')) changeCategoryValue(sp.get('category'));
    if (sp.get('page')) resetSearchPage(Number(sp.get('page')));
    this.props.searchShaders();
  }

  componentWillReceiveProps(newProps) {
    const { search: { params: oldParams = {} } } = this.props;
    const {
      search: { params: newParams = {} },
      resetSearchPage,
      searchShaders,
    } = newProps;
    if (newParams.q !== oldParams.q) {
      resetSearchPage();
      searchShaders();
    }
  }

  handleNextPage = () => {
    const { incrementSearchPage, searchShaders } = this.props;
    incrementSearchPage();
    searchShaders();
  }

  handlePrevPage = () => {
    const { decrementSearchPage, searchShaders } = this.props;
    decrementSearchPage();
    searchShaders();
  }

  render() {
    const {
      search,
      categories,
      searchShaders,
      getCategories,
    } = this.props;
    const { shaders, params = {}, numberOfResults, page } = search;
    const { showPrivate, q = '' } = params;
    return (
      <section className="browse-shaders">
        <TopMenu
          menuType="default"
          getShaders={searchShaders}
        />
        <section className="browse-shaders__body">
          <ShadersHeader
            query={q}
            resultsLength={numberOfResults}
          />
          <ShadersFilters
            showPrivate={showPrivate}
            admin={this.props.admin}
            categories={categories}
            getCategories={getCategories}
            searchShaders={searchShaders}
          />
          <ShaderList shaders={shaders} />
          <div className="browse-shaders__paginator-container">
            <Paginator
              handleNextPage={this.handleNextPage}
              handlePrevPage={this.handlePrevPage}
              currentPage={page}
              numPages={Math.ceil(numberOfResults / PAGE_SIZE)}
            />
          </div>
        </section>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    admin: state.user.admin,
    categories: state.categories,
    search: state.search,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    searchShaders,
    getCategories,
    incrementSearchPage,
    decrementSearchPage,
    resetSearchPage,
    changeSortValue: value => dispatch(change('sort', 'sort', value)),
    changeCategoryValue: value => dispatch(change('category', 'Categories', value)),
    changeQueryValue: value => dispatch(change('search', 'q', value)),
  }, dispatch);
}

Shaders.defaultProps = { admin: false };

Shaders.propTypes = ({
  admin: PropTypes.bool,
  location: ReactRouterPropTypes.location.isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  search: CustomPropTypes.search.isRequired,
  searchShaders: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
});

export default connect(mapStateToProps, mapDispatchToProps)(Shaders);
