import * as ActionTypes from '../constants/ActionTypes';

const relatedShaders = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.SET_RELATED_SHADERS:
      return action.payload.sort(
        (() => Math.random() - 0.5),
      );
    default:
      return state;
  }
};

export default relatedShaders;
