// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const LoginRequiredRoute = ({ component: Component, user, ...rest }) => {
  const { isUserAuthPending, _id } = user;
  const isLoggedIn = !!_id;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isUserAuthPending) return null;
        if (isLoggedIn) return <Component {...props} />;
        return (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        );
      }}
    />
  );
};

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(LoginRequiredRoute);
