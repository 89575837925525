import React, { Fragment } from 'react';
import TopMenu from '../../components/TopMenu/TopMenu';

const QUESTIONS = [
  {
    question: 'What is ISF?',
    answer: (
      <Fragment>
        <p>
          ISF stands for &quot;Interactive Shader Format&quot;, and is a file format
          that describes a GLSL fragment shader, as well as how to execute
          and interact with it.
        </p>
        <p>
          The goal of this file format is to provide a simple and minimal
          interface for image filters and generative video sources that
          allows them to be interacted with and reused in a generic and
          modular fashion. ISF is nothing more than a [slightly modified]
          GLSL fragment shader with a JSON blob at the beginning that
          describes how to interact with the shader (how many inputs/uniform
          variables it has, what their names are, what kind of inputs/variables
          they are, that sort of thing). ISF isn&apos;t some crazy new groundbreaking
          technology- it&apos;s just a simple and useful combination of two things
          that have been around for a while to make a minimal—but highly effective—
          filter format.
        </p>
      </Fragment>),
  },
  {
    question: 'What is the difference between GLSL and ISF?',
    answer: (
      <Fragment>
        <p>
          GLSL is a general programming language for working with the GPU in a
          computer. It is very powerful and can be used for a very wide range
          of things. On its own it is very limiting and often it is used as
          part of a larger host application that often combines multiple
          pieces of GLSL code (often called shaders) into a final output.
        </p>
        <p>
          ISF is to designed a standard for working with GLSL in such a way
          that advanced 2D video generators, filters and mixers can be
          interoperable between applications and platforms.
        </p>
      </Fragment>),
  },
  {
    question: 'How to use ISF?',
    answer: (
      <Fragment>
        <p>
          Artists who are using GLSL to create generators and FX to create
          compositions can use any of the shaders that are found on this
          website by downloading and loading them in host software that
          supports ISF.
        </p>
        <p>
          A free ISF Editor for Mac and Windows is available here:{' '}
          <a
            href="https://isf.vidvox.net/desktop-editor/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://isf.vidvox.net/desktop-editor/
          </a>
          .
          These are some of its basic features:
        </p>
        <ul>
          <li>
            Browses, renders and displays ISF files. Has a built-in video
            source, and can also use any QC comps, movie files, image
            files, Syphon video servers, or AVCapture-compatible video
            inputs as a video source for testing ISF-based image filters.
          </li>
          <li>
            Automatically publishes the rendered output as a Syphon source
          </li>
          <li>
            UI items are automatically created for inputs,
            allowing you to interact with your ISF file
          </li>
          <li>
            Built-in shader editor with syntax coloring and integrated error
            display along with plenty of logging to facilitate creating and debugging shaders.
          </li>
          <li>
            Output window can be paused, and can also be used to view the output
            of the individual render passes in your ISF file, which facilitates
            debugging by providing shader devs with a quick and easy way to
            visualize values being used in their shaders
          </li>
          <li>
            &quot;Import from Shadertoy/GLSL Sandbox&quot; feature can be used to automatically
            convert the vast majority of shaders found on Shadertoy and GLSL Sandbox
            to ISF sources and filters. Some shaders may need further modification,
            but it&apos;s shocking how many will &quot;just work&quot;.
          </li>
        </ul>
        <p>
          Developers who wish to learn more about supporting ISF in their own
          applications can visit the
          {' '}
          <a href="/spec">
            ISF Specification
          </a>
          {' '}
          page on this site.
        </p>
      </Fragment>),

  },
  {
    question: 'Who created ISF?',
    answer: (
      <Fragment>
        <p>
          The ISF Specification was created and is maintained by{' '}
          <a href="vidvox.net" target="_blank">
            VIDVOX
          </a>
          {' '}
          as part of our
          codebase for our VJ software VDMX.
        </p>
      </Fragment>),
  },
  {
    question: 'Are there any good resources for learning GLSL as a language?',
    answer: (
      <Fragment>
        <p>
          Typically the best place to get started with GLSL is by looking
          at some of the great shaders on this website and trying to modify
          them to see what happens.
        </p>
        <p>
          For example, you can make a simple Solid Color generator or a
          more complex Conways Game of Life in ISF.
        </p>
        <p>
          Beyond that there are lots of websites and books dedicated to
          learning GLSL. Two places that are recommended to begin are{' '}
          <a
            href="https://thebookofshaders.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            The Book of Shaders
          </a>
          {' '}
          and
          {' '}
          <a
            href="https://developer.nvidia.com/gpugems/GPUGems/gpugems_pref01.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            GPU Gems
          </a>
          .
        </p>
      </Fragment>),
  },
];

const About = () => (
  <section className="about">
    <TopMenu
      menuType="default"
    />
    {QUESTIONS.map(item => (
      <div className="about__pane" key={item.question}>
        <h2 className="about__question">
          {item.question}
        </h2>
        <div
          className="about__answer"
        >
          {item.answer}
        </div>
      </div>
    ))}
  </section>
);
export default About;
